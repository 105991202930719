import { Checkbox, Modal } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  DatePickerSection,
  ModalContainer,
} from "../../EventCalender/components/style";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "450px",
    width: "550px",
  };
}

const StyledModal = styled(Modal)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    outline: 0,
    width: 500,
    height: 250,
    backgroundColor: "#fff",
    boxShadow: 5,
    padding: 16,
  },
}));

const DemandsLevels = ["CityDemandLevel", "CityEvent"];

export function NewCalendarCopyModal({
  setCopyFromHotelId,
  isOpen,
  setOpen,
  hotellist,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setCheckbox,
  onClose,
  selectDL,
  handleDLChange,
  dLCheckbox,
  dateRangeState,
  setDateRangeState,
  setIsChangeDl,
  currentHotelId,
}) {
  const [modalStyle] = React.useState(getModalStyle);

  const [isHotelSelect, setIsHotelSelect] = useState(false);
  const [hotelName, setHotelName] = useState("");
  const [hotelId, setHotelId] = useState(null);

  useEffect(() => {
    if (hotelName !== "") {
      setIsHotelSelect(true);
    } else {
      setIsHotelSelect(false);
    }
  }, [hotelName]);

  const defaultHotelProps = {
    options: [
      ...hotellist?.map((hotel) => {
        return hotel.name + " (" + hotel.organizationName + ")";
      }),
    ],
    getOptionLabel: (option) => option,
  };

  const handleHotelChange = (value) => {
    if (value) {
      const hotelName = value.split("(")[0].trim();
      const selectedHotel = hotellist.find((hotel) => hotel.name === hotelName);

      if (selectedHotel) {
        setHotelId(selectedHotel.id);
        setHotelName(selectedHotel.name);
        setIsHotelSelect(true);
      } else {
        setHotelId(null);
        setIsHotelSelect(false);
      }
    } else {
      setHotelId(null);
    }
  };

  return (
    <StyledModal open={isOpen} onClose={onClose}>
      <ModalContainer style={modalStyle} className="paper">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          style={{
            width: "100%",
          }}
        >
          <Typography variant="subtitle1"></Typography>
          <IconButton
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon
              style={{
                fontSize: "18px",
              }}
            />
          </IconButton>
        </Stack>
        <Stack direction={"column"}>
          <Box style={{ padding: "10px 0" }}>
            <Stack direction={"column"}>
              <Autocomplete
                {...defaultHotelProps}
                value={hotelName}
                sx={{ width: 380 }}
                onChange={(event, newValue) => {
                  handleHotelChange(newValue);
                }}
                clearIcon={hotelName ? undefined : null}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    placeholder="Select Hotel"
                  />
                )}
              />

              {isHotelSelect && (
                <>
                  <FormControl
                    variant="outlined"
                    style={{ marginTop: "30px", marginBottom: "20px" }}
                  >
                    <InputLabel>Demand Level Factors</InputLabel>

                    <Select
                      multiple
                      value={selectDL}
                      label="Demand Level Factors"
                      onChange={(e) => handleDLChange(e.target.value)}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em>Placeholder</em>;
                        }

                        return <em>{selected.join(", ")}</em>;
                      }}
                      MenuProps={{ getContentAnchorEl: null }}
                      style={{ width: "380px" }}
                    >
                      {DemandsLevels?.map((val, idx) => (
                        <MenuItem key={val?.id} value={val}>
                          <ListItemText primary={val} />
                          <Checkbox
                            checked={selectDL.indexOf(val) > -1}
                            style={{ color: "rgb(48,111,188)" }}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={dateRangeState}
                          onChange={(e) => {
                            setDateRangeState(e.target.checked);
                          }}
                          style={{ color: "rgb(48,111,188)" }}
                        />
                      }
                      label="Date Range"
                    />
                  </FormControl>
                  {dateRangeState && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePickerSection
                        style={{
                          display: "flex",
                        }}
                      >
                        <Typography
                          style={{
                            marginRight: "10px",
                            fontSize: "inherit",
                            letterSpacing: "0px",
                            color: "rgb(48,111,188)",
                          }}
                        >
                          From
                        </Typography>
                        <DatePicker
                          disableToolbar
                          autoOk
                          inputFormat={"DD/MM/YYYY"}
                          value={startDate}
                          onChange={(e) => setStartDate(e)}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                svg: {
                                  color: "rgb(48,111,188)",
                                  marginLeft: "-140px",
                                },
                              }}
                              variant="standard"
                              {...params}
                            />
                          )}
                          style={{ width: "162px" }}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />

                        <Typography
                          style={{
                            marginRight: "10px",
                            fontSize: "inherit",
                            letterSpacing: "0px",
                            color: "rgb(48,111,188)",
                          }}
                        >
                          To
                        </Typography>
                        <DatePicker
                          disableToolbar
                          autoOk
                          inputFormat={"DD/MM/YYYY"}
                          renderInput={(params) => (
                            <TextField
                              sx={{
                                svg: {
                                  color: "rgb(48,111,188)",
                                  marginLeft: "-140px",
                                },
                              }}
                              variant="standard"
                              {...params}
                            />
                          )}
                          style={{ width: "162px" }}
                          minDate={
                            new Date(
                              new Date(startDate).setDate(
                                new Date(startDate).getDate() + 1
                              )
                            )
                          }
                          value={endDate}
                          onChange={(e) => setEndDate(e)}
                          error={false}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      </DatePickerSection>
                    </LocalizationProvider>
                  )}
                </>
              )}
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            justifyContent="space-evenly"
            alignItems={"center"}
            style={{ marginTop: "60px" }}
          >
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              style={{ background: "rgb(48,111,188)", color: "white" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              style={{ background: "rgb(48,111,188)", color: "white" }}
              disabled={
                new Date(endDate).setHours(0, 0, 0) <
                  new Date(startDate).setHours(0, 0, 0) ||
                hotelId === null ||
                hotelId === parseInt(currentHotelId)
                  ? true
                  : false
              }
              onClick={() => {
                setCopyFromHotelId(hotelId);
                setOpen(false);
                setCheckbox(dateRangeState);
                setIsChangeDl(dLCheckbox);
              }}
            >
              Submit
            </Button>
          </Stack>
        </Stack>
      </ModalContainer>
    </StyledModal>
  );
}
