import { CloseRounded, ChevronLeft, ChevronRight } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CardContainer,
  DateSection,
  DisplayDate,
  HeadSection,
  PageContainer,
  ToggelLeft,
  Toggle,
  ToggleRight,
} from "./Styles";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Select as SL,
} from "@mui/material";
import { styled } from "@mui/system";
import Autocomplete from "@mui/material/Autocomplete";
import { ErrorPage, LoadingPage, PrimaryButton, useAuth } from "../../sdk";
import UploadTable from "./components/UploadTable";
import { TextField as TF } from "@mui/material";

const TextField = styled(TF)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const StyledContainer = styled(PageContainer)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
  },
}));
const HotelNameText = styled(Box)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
`;
const Select = styled(SL)`
  .MuiSelect-root {
    color: red;
    height: 25px;
  }
  .MuiSelect-filled {
    color: white;
    background: smokewhite 0% 0% no-repeat padding-box;
    height: 36px;
    padding: 0px;
    width: 100%;
  }
`;

const Label = styled(Box)`
  font-family: Roboto;
`;
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  :hover {
    cursor: pointer;
  }
`;

const MonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function UploadInfoPage() {
  const { hotelId } = useParams();
  const { hotelSelect, hotels, token, authFetch, permission } = useAuth();
  const [selectedHotelId, setSelectedHotelId] = useState(null);
  const [selectedHotel, setSelectedHotel] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [uploadData, setUploadData] = useState();
  const [chains, setChains] = useState([]);
  const [managingOrganizations, setManagingOrganizations] = useState([]);
  const [selectedChainId, setSelectedChainId] = useState(null);
  const [selectedChain, setSelectedChain] = useState("");
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [file, setFile] = useState("");
  const [fileType, setFileType] = useState("");
  let acrossHotel = null;
  let fileUploadedDelete = false;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "acrossHotel") {
        acrossHotel = permission[key];
      }
      if (permission[key].name === "fileUploadedDelete") {
        fileUploadedDelete = true;
      }
    }
  }

  const [hotelList, setHotelList] = useState(acrossHotel ? [] : hotels);

  useEffect(() => {
    if (token && acrossHotel) {
      getManagingOrganization();
      setSelectedOrganizationId(hotelSelect?.managedBy);
      setSelectedOrganization(hotelSelect?.managedByName);
      setSelectedChainId(hotelSelect?.organizationId);
      setSelectedChain(hotelSelect?.organizationName);
      setSelectedHotelId(hotelSelect?.id);
      setSelectedHotel(hotelSelect?.name);
    } else {
      return;
    }
  }, [token]);

  useEffect(() => {
    if (token && !acrossHotel) {
      setHotelList(hotels);
      setSelectedHotelId(hotelSelect?.id);
      setSelectedHotel(hotelSelect?.name);
    }
  }, [token]);

  useEffect(() => {
    if (acrossHotel && token) {
      if (!selectedOrganizationId) {
        return;
      } else {
        getChains();
      }
    } else {
      return;
    }
  }, [token, selectedOrganizationId]);
  useEffect(() => {
    if (acrossHotel && token) {
      if (!selectedOrganizationId || !selectedChainId) {
        return;
      } else {
        getHotels();
      }
    } else {
      return;
    }
  }, [token, selectedOrganizationId, selectedChainId]);

  useEffect(() => {
    if (acrossHotel && token) {
      if (!selectedOrganizationId || !selectedChainId || !selectedHotelId) {
        return;
      } else {
        getUploads();
      }
    } else if (token && !acrossHotel) {
      if (!selectedHotelId) {
        return;
      }
      getUploads();
    }
  }, [
    token,
    selectedOrganizationId,
    selectedChainId,
    selectedHotelId,
    month,
    year,
  ]);

  const defaultOrgProps = {
    options: [
      ...managingOrganizations.map((org) => {
        return org.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultChainProps = {
    options: [
      ...chains.map((chain) => {
        return chain.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultHotelProps = {
    options: [
      ...hotelList.map((hotel) => {
        return hotel.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  function toggelLeft(month) {
    setMonth(month - 1);
    if (month === 0) {
      setMonth(11);
      setYear((prev) => prev - 1);
    }
  }

  function toggelRight(month) {
    setMonth(month + 1);
    if (month === 11) {
      setMonth(0);
      setYear((prev) => prev + 1);
    }
  }

  async function getManagingOrganization() {
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      setManagingOrganizations(data);
    } else {
      console.log(error);
    }
  }

  async function getChains() {
    const { get } = await authFetch({
      path: `/managing-org/${selectedOrganizationId}/chains`,
    });
    const { data, error } = await get();
    if (data) {
      setChains(data);
    } else {
      console.log(error);
    }
  }

  async function getHotels() {
    const { get } = await authFetch({
      path: `/managing-org/${selectedOrganizationId}/chains/${selectedChainId}/hotels/1`,
    });
    const { data, error } = await get();
    if (data) {
      setHotelList(data?.hotels);
    } else {
      console.log(error);
    }
  }

  const handleOrgChange = (value) => {
    const orgId = managingOrganizations.find((org) => org.name === value);
    if (orgId) {
      setSelectedOrganizationId(orgId.id);
      setSelectedOrganization(orgId.name);
      setSelectedChainId(null);
      setSelectedChain("");
      setSelectedHotelId(null);
      setSelectedHotel("");
      setUploadData([]);
    } else {
      setSelectedOrganizationId(null);
    }
  };

  const handleChainChange = (value) => {
    const chainId = chains.find((chain) => chain.name === value);
    if (chainId) {
      setSelectedChainId(chainId.id);
      setSelectedChain(chainId.name);
      setSelectedHotelId(null);
      setSelectedHotel("");
      setUploadData([]);
    } else {
      setSelectedChainId(null);
    }
  };

  const handleHotelChange = (value) => {
    const hotelName = value.split("(")[0].trim();
    const selectedHotel = hotelList.find((hotel) => hotel.name === hotelName);
    if (selectedHotel) {
      setSelectedHotelId(selectedHotel.id);
      setSelectedHotel(selectedHotel.name);
    } else {
      setSelectedHotelId(null);
    }
  };

  async function getUploads() {
    setLoading(true);
    const { post } = await authFetch({
      path: `/hotel/${selectedHotelId}/hotel-logs`,
    });
    const { data, error } = await post({
      fromDate: new Date(year, month, 2),
      toDate: new Date(year, month + 1, 2),
    });
    if (data) {
      data.map((data) =>
        data?.forecastFile?.sort((a, b) => new Date(a.time) - new Date(b.time))
      );
      data.map((data) =>
        data?.competitorFile?.sort(
          (a, b) => new Date(a.time) - new Date(b.time)
        )
      );

      setUploadData(data);
      setError(false);
    } else {
      setError(true);
      setUploadData([]);
      console.log(error);
    }

    setLoading(false);
  }

  async function deleteFile() {
    if (file) {
      setLoading(true);
      if (fileType === "forecast") {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/file/${file.id}`,
        });
        const { data, error } = await del();
        if (data) {
          setShouldBlockNavigation(false);
          getUploads();
        } else if (error) {
          console.log(error);
        }
      }
      if (fileType === "competitor") {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/competitor-price/${file.id}`,
        });
        const { data, error } = await del();
        if (data) {
          setShouldBlockNavigation(false);
          getUploads();
        } else if (error) {
          console.log(error);
        }
      }
      setLoading(false);
    }
  }

  function confirmation() {
    const val = window.confirm("Please confirm again");
    if (val) {
      deleteFile();
    }
    return;
  }

  return !Error ? (
    !Loading ? (
      <StyledContainer>
        <Dialog
          open={shouldBlockNavigation}
          onClose={() => setShouldBlockNavigation(false)}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Label> Are you sure you want to delete this file? </Label>
              <Cross onClick={() => setShouldBlockNavigation(false)}>
                &times;
              </Cross>
            </div>
          </DialogTitle>
          <DialogActions
            style={{ justifyContent: "space-evenly", paddingBottom: 16 }}
          >
            <PrimaryButton
              autoFocus
              next
              onClick={() => {
                setShouldBlockNavigation(false);
                confirmation();
              }}
            >
              Yes
            </PrimaryButton>
            <PrimaryButton next onClick={() => setShouldBlockNavigation(false)}>
              No
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <CardContainer>
          <HeadSection>
            <DateSection>
              <DisplayDate>
                <div style={{ paddingRight: "4px" }}>{MonthName[month]}</div>
                <div>{year}</div>
              </DisplayDate>
              <Toggle>
                <ToggelLeft onClick={() => toggelLeft(month)}>
                  <ChevronLeft />
                </ToggelLeft>
                <ToggleRight>
                  <ChevronRight onClick={() => toggelRight(month)} />
                </ToggleRight>
              </Toggle>
            </DateSection>
            {acrossHotel && (
              <Autocomplete
                {...defaultOrgProps}
                value={selectedOrganization}
                style={{ width: "30%", padding: "0 8px 0 8px" }}
                freeSolo={true}
                onChange={(event, newValue) => {
                  handleOrgChange(newValue);
                }}
                classes={{
                  paper: "dropdownStyle",
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} label="Organization" />
                )}
              />
            )}
            {acrossHotel && (
              <Autocomplete
                {...defaultChainProps}
                value={selectedChain}
                style={{ width: "30%", padding: "0 8px 0 8px" }}
                freeSolo={true}
                disableClearable={true}
                onChange={(event, newValue) => {
                  handleChainChange(newValue);
                }}
                classes={{
                  paper: "dropdownStyle",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Chain" />
                )}
              />
            )}
            <Autocomplete
              {...defaultHotelProps}
              value={selectedHotel}
              style={{ width: "30%", padding: "0 8px 0 8px" }}
              freeSolo={true}
              disableClearable={true}
              classes={{
                paper: "dropdownStyle",
              }}
              onChange={(event, newValue) => {
                handleHotelChange(newValue);
              }}
              renderInput={(params) => <TextField {...params} label="Hotel" />}
            />
          </HeadSection>
          <UploadTable
            data={uploadData}
            setShouldBlockNavigation={setShouldBlockNavigation}
            setFile={setFile}
            setFileType={setFileType}
            fileUploadedDelete={fileUploadedDelete}
          />
        </CardContainer>
      </StyledContainer>
    ) : (
      <LoadingPage />
    )
  ) : (
    <ErrorPage />
  );
}
