import React from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export const SearchPropertyIdDialog = ({
  openPropertyIdModal,
  setOpenPropertyIdModal,
  isLoading,
  roomData,
}) => {
  return (
    <StyledDialog
      open={openPropertyIdModal}
      onClose={() => setOpenPropertyIdModal(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className="content">Mapping Data</DialogTitle>

      <CloseIcon
        className="closeButton"
        onClick={() => setOpenPropertyIdModal(false)}
      />
      <DialogContent className="content">
        {isLoading ? (
          <CircularProgress />
        ) : (
          <pre>{JSON.stringify(roomData, null, 2)}</pre>
        )}
      </DialogContent>
    </StyledDialog>
  );
};
const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
