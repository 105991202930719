import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { SearchPropertyIdDialog } from "./SearchPropertyIdDialog";
export const ExculdeRooms = ({
  excludeRoomToggle,
  setExcludeRoomToggle,
  propertyId,
  hotelId,
  authFetch,
  handleSearchPropertyId,
  roomData,
}) => {
  const [roomsData, setRoomsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openPropertyIdModal, setOpenPropertyIdModal] = useState(false);
  const [loaderRoomIndex, setLoaderRoomIndex] = useState(null);
  const getRoomMapping = useCallback(async () => {
    try {
      setIsLoading(true);
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/get-excluded-rooms`,
      });
      const { data, response } = await get();
      if (data && response.status === 200) {
        setRoomsData(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, [authFetch, hotelId]);
  const handleSearch = useCallback(async () => {
    await handleSearchPropertyId();
    setOpenPropertyIdModal(true);
  }, [handleSearchPropertyId]);
  const handleSaveRooms = useCallback(
    async (value, index) => {
      setLoaderRoomIndex(index);
      try {
        const requestBody = {
          roomId: Number(value?.roomId),
          roomName: value?.roomName,
          rateId: Number(value?.rateId),
          hotelId: Number(hotelId),
        };

        const { post } = await authFetch({
          path: `/hotel/${hotelId}/add-excluded-rooms`,
        });

        const { response } = await post(requestBody);
        if (response.status === 200) {
          getRoomMapping();
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoaderRoomIndex(null);
      }
    },
    [authFetch, getRoomMapping, hotelId]
  );

  const changeHandler = useCallback((index, key, value) => {
    setRoomsData((prevState) => {
      return prevState.map((data, idx) => {
        if (index === idx) {
          return {
            ...data,
            isEdit: true,
            [key]: value,
          };
        }
        return data;
      });
    });
  }, []);
  useEffect(() => {
    if (!hotelId) return;
    getRoomMapping();
  }, []);
  return (
    <ExcludeRoomsPageStack>
      {openPropertyIdModal ? (
        <SearchPropertyIdDialog
          isLoading={isLoading}
          openPropertyIdModal={openPropertyIdModal}
          roomData={roomData}
          setOpenPropertyIdModal={setOpenPropertyIdModal}
        />
      ) : null}
      <Button
        variant="contained"
        onClick={() => setExcludeRoomToggle(false)}
        sx={{
          maxWidth: "100px",
        }}
      >
        Back
      </Button>
      <Stack className="toggleContainer">
        <Typography className="pageHeading">Exclude Rooms</Typography>
      </Stack>
      <Stack className="borderStack" gap="16px">
        <Stack className="propertyStack">
          <TextField
            sx={{ width: "33%" }}
            label="Property Id"
            type="number"
            className="remove-arrows"
            value={propertyId}
            disabled
          />
          <Button
            variant="contained"
            sx={{ height: "40px" }}
            onClick={handleSearch}
            disabled={!propertyId}
          >
            Search
          </Button>
        </Stack>
      </Stack>

      {!isLoading ? (
        roomsData?.map((room, index) => (
          <Stack className="borderStack" gap="16px">
            <Typography className="pageHeading">
              Room Mapping For Exclude Rooms
            </Typography>
            <Stack gap="16px" flexDirection={"row"}>
              <Stack
                display={"flex"}
                flexGrow={"1"}
                flexDirection={"column"}
                gap={"16px"}
              >
                <Stack direction="row" gap="16px">
                  <TextField
                    disabled={!room?.isNewRoom}
                    label="Room Id"
                    type="number"
                    className="remove-arrows"
                    value={room?.roomId}
                    onChange={(e) => {
                      changeHandler(index, "roomId", e.target.value);
                    }}
                  />
                  <TextField
                    disabled={!room?.isNewRoom}
                    label="Room Name"
                    type="text"
                    value={room?.roomName}
                    onChange={(e) => {
                      changeHandler(index, "roomName", e.target.value);
                    }}
                  />
                  <TextField
                    disabled={!room?.isNewRoom}
                    label="Rate Id"
                    type="number"
                    className="remove-arrows"
                    value={room?.rateId}
                    onChange={(e) => {
                      changeHandler(index, "rateId", e.target.value);
                    }}
                  />
                </Stack>
              </Stack>
              {room?.isNewRoom && (
                <LoadingButton
                  variant="contained"
                  onClick={() => handleSaveRooms(roomsData?.[index], index)}
                  loading={isLoading && loaderRoomIndex === index}
                >
                  Save
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        ))
      ) : (
        <Stack alignItems="center">
          <CircularProgress />
        </Stack>
      )}
      <Stack
        className=""
        style={{
          marginLeft: "auto",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            // Add New Room to Array
            setRoomsData((prev) =>
              prev.concat({
                isNewRoom: true,
              })
            );
          }}
        >
          Add Room
        </Button>
      </Stack>
    </ExcludeRoomsPageStack>
  );
};
const ExcludeRoomsPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "24px",

  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
  ".pageHeading": {
    fontSize: "16px",
    fontWeight: "600",
    paddingLeft: "16px",
  },
  ".toggleContainer": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ".propertyStack": {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  ".buttonStack": {
    display: "flex",
    // flexDirection: "row",
    gap: "16px",
    justifyContent: "end",
  },
  ".remove-arrows": {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    ".MuiInputAdornment-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
}));
