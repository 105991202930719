import { SwapVert, ArrowForwardIos } from "@mui/icons-material";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  InputLabel,
  IconButton,
  Menu,
  Paper,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as XDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useCallback, useEffect, useMemo } from "react";
import DatePicker, { utils } from "react-modern-calendar-datepicker";
import { Column } from "react-virtualized";
import { CalenderIcon } from "../Pace";
import {
  DatePickerSection,
  IconPaper,
  Mainpaper,
  RateshopPageBox,
  SelectHeader,
  StyledCalenderWrapper,
  StyledDialog,
} from "./Styles";
import { AddEmail } from "./components/AddEmail";
import { GraphRate } from "./components/GraphRate";
import { PriceCheckerCalendar } from "./components/PriceCheckerCalendar";
import usePriceChecker from "./hooks/usePriceChecker";
import { MonthPicker, useAuth } from "../sdk";
import { styled } from "@mui/system";
import { useHistory } from "react-router-dom";

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: "8px !important",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25) !important",
    backgroundColor: "white !important",
  },
}));

const PriceCheck = ({ setPageHeader }) => {
  useEffect(() => {
    setPageHeader("Rate Shopping");
    return () => {
      setPageHeader("");
    };
  }, [setPageHeader]);

  const {
    selectedMonthYear,
    setSelectedMonthYear,
    anchorEl,
    addEmailDialog,
    setAddEmailDialog,
    open,
    exportFileMenuOpen,
    exportFileMenuClose,
    fromDateNew,
    setFromDateNew,
    toDateNew,
    settoDateNew,
    isLoading,
    // renderCustomInput,
    // renderCustomInputToDate,
    calendarData,
    tableData,
    pastDateData,
    channels,
    oldestRate,
    sources,
    nights,
    guests,
    roomTypes,
    mealTypes,
    fileExport,
    openSnackbar,
    setOpenSnackbar,
    errorSnackbar,
    setErrorSnackbar,
    errorMessage,
    filters,
    setFilters,
    openCustom,
    openCustomDialog,
    closeCustomDialog,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    togglePage,
    setTogglePage,
    canPostRateMetric,
    postRateMetric,
    exportFile,
    handleCloseAddEmailDialog,
    handleIncrementDecrementDate,
    pendingCredits,
    canRateMetricLoading,
    showSkeleton,
    mobileChannels,
  } = usePriceChecker();

  const { permission, currentHotel } = useAuth();
  let createRateshop = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "createRateshop") {
        createRateshop = permission[key];
      }
    }
  }

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  const optionsToShow = useMemo(() => {
    if (channels.length && mobileChannels.length) return sources;
    else if (channels.length) return [sources[0]];

    return [sources[1]];
  }, [channels.length, mobileChannels.length, sources]);

  const sourcesToShow = useMemo(() => {
    if (filters?.sourceName[0] === sources[1])
      return mobileChannels?.sort((a, b) => a.sequence - b.sequence);
    const nonBrandWebsites = channels
      ?.filter((channel) => !channel?.isBrandWebsite)
      ?.sort((a, b) => a.sequence - b.sequence);
    return nonBrandWebsites;
  }, [channels, filters.sourceName, mobileChannels, sources]);

  return (
    <ClassWrapper>
      <AddEmail
        open={addEmailDialog}
        handleClose={handleCloseAddEmailDialog}
        // renderCustomInput={renderCustomInput}
        // renderCustomInputToDate={renderCustomInputToDate}
        fromDateNew={fromDateNew}
        setFromDateNew={setFromDateNew}
        toDateNew={toDateNew}
        settoDateNew={settoDateNew}
        handleSubmit={canPostRateMetric}
      />

      <RateshopPageBox>
        <Mainpaper elevation={0}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            padding="0px 10px 0px 10px"
          >
            <Stack className="headerStack">
              {/* <Stack className="dateStack">
                <Box className="dateBox">
                  <label>
                    <StyledCalenderWrapper>
                      <DatePicker
                        style={{ zIndex: 98 }}
                        value={fromDateNew}
                        onChange={setFromDateNew}
                        renderInput={renderCustomInput}
                        calendarClassName="myCustomCalendar"
                        minimumDate={utils().getToday()}
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </Box>
              </Stack>

              <Stack className="dateStack">
                <Typography className="to-text">To</Typography>
                <Box className="dateBox">
                  <label>
                    <StyledCalenderWrapper>
                      <DatePicker
                        value={toDateNew}
                        onChange={settoDateNew}
                        renderInput={renderCustomInputToDate}
                        calendarClassName="myCustomCalendar"
                        minimumDate={fromDateNew}
                      />
                      <CalenderIcon />
                    </StyledCalenderWrapper>
                  </label>
                </Box>
              </Stack> */}
              <Paper className="monthPicker" elevation={2}>
                <Stack direction={"row"}>
                  {selectedMonthYear.getMonth() !== new Date().getMonth() ? <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("left");
                    }}
                  >
                    <ArrowBackIosIcon
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>: null}
                  <MonthPicker
                    value={selectedMonthYear}
                    onChange={setSelectedMonthYear}
                    disablePastMonths
                  />
                  <IconButton
                    onClick={() => {
                      handleIncrementDecrementDate("right");
                    }}
                  >
                    <ArrowForwardIos
                      style={{ fontSize: "14px", color: "rgb(48,111,188)" }}
                    />
                  </IconButton>
                </Stack>
              </Paper>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                gap={{ md: 1, lg: 2 }}
              >
                <SelectHeader size="small">
                  <InputLabel>Platform</InputLabel>
                  <Select
                    variant="standard"
                    label="Platform"
                    value={filters.sourceName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        sourceName: [e.target.value],
                        channelIds:
                          e.target.value === sources[0]
                            ? [channels[0]?.sourceId]
                            : [mobileChannels[0]?.sourceId],
                      });

                      const value =
                        e.target.value === sources[0]
                          ? channels[0]?.sourceId
                          : mobileChannels[0]?.sourceId;

                      searchParams.set("sources", value);
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      minWidth: 116,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {optionsToShow?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.sourceName?.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  <InputLabel>Channel</InputLabel>
                  <Select
                    variant="standard"
                    label="Channel"
                    value={filters.channelIds}
                    onChange={(e) => {
                      const value = Array.isArray(e.target.value)
                        ? e.target.value
                        : [e.target.value];
                      setFilters({
                        ...filters,
                        channelIds: value?.includes("All")
                          ? sourcesToShow?.map((channel) => channel.sourceId)
                          : value,
                      });
                      searchParams.set("sources", value);
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => {
                      // console.log({ selected, sourcesToShow }, "here")
                      return selected.length === sourcesToShow.length
                        ? "All"
                        : selected?.map(
                            (sourceId) =>
                              sourcesToShow?.find(
                                (channel) => channel.sourceId === sourceId
                              )?.name || ""
                          );
                    }}
                    sx={{
                      minWidth: 120,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {/* <MenuItem value={"All"}>
                                    <ListItemText primary={"All"} />
                                    <Checkbox
                                        sx={{ color: "#163A90" }}
                                        checked={
                                            filters.channelIds.length ===
                                            channels.length
                                        }
                                    />
                                </MenuItem> */}
                    {sourcesToShow?.map((channel) => (
                      <MenuItem key={channel.sourceId} value={channel.sourceId}>
                        <ListItemText primary={channel.name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.channelIds?.includes(
                            channel.sourceId
                          )}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  <InputLabel>LOS</InputLabel>
                  <Select
                    variant="standard"
                    label="LOS"
                    value={filters.nightName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        nightName: [e.target.value],
                      });
                      searchParams.set("los", e.target.value[0]);
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      minWidth: 113,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {nights?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.nightName?.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                <SelectHeader size="small">
                  <InputLabel>Guest</InputLabel>
                  <Select
                    variant="standard"
                    label="Guest"
                    value={filters.guestName}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        guestName: [e.target.value],
                      });
                      searchParams.set("occupancy", e.target.value[0]);
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => selected}
                    sx={{
                      minWidth: 113,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {guests?.map((name) => (
                      <MenuItem key={name} value={name}>
                        <ListItemText primary={name} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.guestName?.indexOf(name) > -1}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>

                {currentHotel?.isRoomTypeMapped ? <SelectHeader size="small">
                  <InputLabel>Room Type</InputLabel>
                  <Select
                    variant="standard"
                    label="Room Type"
                    value={filters?.roomType?.[0]}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        roomType: [e.target.value],
                      });
                      if(e.target.value.value !== "") {
                        searchParams.set("roomType", e.target.value.value);
                      }else{
                        searchParams.delete("roomType")
                      }
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => 
                      <Tooltip arrow placement="top" title="Room Type">
                        {selected.label}
                      </Tooltip>
                    }
                    sx={{
                      minWidth: 113,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {roomTypes?.map((roomType) => (
                      <MenuItem key={roomType.value} value={roomType}>
                        <ListItemText primary={roomType.label} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.roomType?.some(({value})=>value===roomType.value)}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>: 
                null }

                <SelectHeader size="small">
                  <InputLabel>Meal Type</InputLabel>
                  <Select
                    variant="standard"
                    label="Meal Type"
                    value={filters?.mealPlan?.[0]}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        mealPlan: [e.target.value],
                      });
                      if(e.target.value.value !== "") {
                        searchParams.set("mealPlan", e.target.value.value);
                      } else{
                        searchParams.delete("mealPlan")
                      }
                      history.replace({
                        pathname: history.location.pathname,
                        search: searchParams.toString(),
                      });
                    }}
                    renderValue={(selected) => 
                      <Tooltip arrow placement="top" title="Meal Plan">
                        {selected.label}
                      </Tooltip>
                      }
                    sx={{
                      minWidth: 129,
                      paddingLeft:2,
                      "& .MuiSvgIcon-root": {
                        color: "#306FBC",
                        paddingBottom: "3px",
                      },
                    }}
                    disableUnderline
                    MenuProps={{
                      classes: {
                        paper: "dropdownStyle",
                      },
                    }}
                  >
                    {mealTypes?.map((mealPlan) => (
                      <MenuItem key={mealPlan.value} value={mealPlan}>
                        <ListItemText primary={mealPlan.label} />
                        <Checkbox
                          sx={{ color: "#163A90" }}
                          checked={filters?.mealPlan?.some(({value})=>value===mealPlan.value)}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </SelectHeader>
              </Stack>

              <Stack direction={"row"} gap={{ md: 1, lg: 2 }}>
                <Stack direction={"row"} spacing={2}>
                  <Box
                    className="exportButton"
                    id="export-file-button"
                    title="Export file"
                    aria-controls={open ? "export-file-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={exportFileMenuOpen}
                  >
                    <img src="/assets/Export.svg" alt="export" />
                  </Box>
                  <Menu
                    id="export-file-menu"
                    aria-labelledby="export-file-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={exportFileMenuClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {fileExport?.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        onClick={() => {
                          const currentDate = new Date();
                          exportFileMenuClose();
                          switch (true) {
                            case name === "Next 60 Days":
                              currentDate.setDate(currentDate.getDate() + 60);
                              exportFile(new Date(), currentDate);
                              break;
                            case name === "Next 90 Days":
                              currentDate.setDate(currentDate.getDate() + 90);
                              exportFile(new Date(), currentDate);
                              break;
                            case name === "Selected Month":
                              exportFile(
                                new Date(
                                  selectedMonthYear.getFullYear(),
                                  selectedMonthYear.getMonth(),
                                  1
                                ),
                                new Date(
                                  selectedMonthYear.getFullYear(),
                                  selectedMonthYear.getMonth() + 1,
                                  0
                                )
                              );
                              break;
                            case name === "Custom":
                              openCustomDialog();
                              break;
                            default:
                              break;
                          }
                        }}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Stack>

                {/* <IconPaper
                //will be using it in future
                  elevation={2}
                  title="Calender view"
                  onClick={() => setTogglePage(false)}
                  sx={{
                    backgroundColor: !togglePage ? "lightBlue  " : "white",
                  }}
                >
                  <EventAvailableIcon
                    sx={{
                      color: "#306FBC",
                    }}
                  />
                </IconPaper> */}

                {/* 
                <IconPaper
                  elevation={2}
                  title="Graph view"
                  onClick={() => setTogglePage(true)}
                  sx={{
                    backgroundColor: togglePage ? "lightBlue  " : "white",
                  }}
                >
                  <ListIcon
                    sx={{
                      color: "#306FBC",
                      height: "25px",
                      width: "25px",
                    }}
                  />
                </IconPaper> */}
              </Stack>
            </Stack>
          </Stack>
        </Mainpaper>

        <Stack className="pendingCredits">
          {!!oldestRate && oldestRate !== "0001-01-01T00:00:00Z" && (
            <Typography>
              Latest updated at {moment(oldestRate).fromNow()}{" "}
            </Typography>
          )}
          <Typography color="#306FBC">
            Your {pendingCredits} Rateshop credits are remaining for this month{" "}
          </Typography>
        </Stack>

        <Stack
          direction={Column}
          display="flex"
          justifyContent="center"
          paddingRight="30px"
        >
          <Stack position="relative">
            {togglePage === false ? (
              <PriceCheckerCalendar
                calendarData={calendarData}
                channels={sourcesToShow}
                filters={filters}
                clickonRefreshBtn={true}
                isLoading={isLoading}
                canPostRateMetric={canPostRateMetric}
                canRateMetricLoading={canRateMetricLoading}
                showSkeleton={showSkeleton}
                createRateshop={createRateshop}
              />
            ) : (
              <GraphRate tableData={tableData} pastDateData={pastDateData} />
            )}
          </Stack>
          {createRateshop ? (
            <Stack gap="20px">
              <Box
                className="refreshIcon"
                variant="contained"
                title="Refresh"
                onClick={() => setAddEmailDialog(true)}
              >
                <SwapVert
                  sx={{
                    color: "blue",
                  }}
                ></SwapVert>
              </Box>
            </Stack>
          ) : null}
        </Stack>
      </RateshopPageBox>

      {/* Render date picker component only when 'Custom' is selected */}
      <StyledDialog
        PaperProps={{
          className: "paperProps",
        }}
        open={openCustom}
        onClose={closeCustomDialog}
      >
        <>
          <Box className="dialogHeader">
            <Typography className="dialogTitle">
              RateShopping: Select Date
            </Typography>
          </Box>

          <Box padding={3}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography className="dateHeading">Start Date</Typography>
              <Typography className="dateHeading">End Date</Typography>
            </Stack>

            <Stack direction="row" justifyContent="space-between">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePickerSection>
                  <XDatePicker
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputFormat="DD/MM/YYYY"
                    value={startDate}
                    onChange={(date) => setStartDate(new Date(date))}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: "#163A90" },
                          width: "234px",
                        }}
                        variant="outlined"
                        {...params}
                      />
                    )}
                    sx={{ height: "35px" }}
                  />

                  <XDatePicker
                    InputProps={{
                      disableUnderline: true,
                    }}
                    inputFormat="DD/MM/YYYY"
                    value={endDate}
                    renderInput={(params) => (
                      <TextField
                        sx={{
                          svg: { color: "#163A90" },
                          width: "234px",
                        }}
                        variant="outlined"
                        {...params}
                      />
                    )}
                    sx={{ height: "35px" }}
                    onChange={(date) => setEndDate(new Date(date))}
                  />
                </DatePickerSection>
              </LocalizationProvider>
            </Stack>

            <Stack justifyContent="space-evenly" direction="row" mt={3}>
              <Button
                variant="outlined"
                onClick={closeCustomDialog}
                className="cancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="confirmButton"
                onClick={async () => {
                  await exportFile(startDate, endDate);
                  closeCustomDialog();
                }}
              >
                Confirm
              </Button>
            </Stack>
          </Box>
        </>
      </StyledDialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setOpenSnackbar(false);
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message=" Set RateMetricHotelCode & Channels from Setup Page"
        />
      </Snackbar>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={errorSnackbar}
        autoHideDuration={3000}
        onClose={() => {
          setErrorSnackbar(false);
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={errorMessage}
        />
      </Snackbar>
    </ClassWrapper>
  );
};

export default PriceCheck;
