import { Dialog, FormControl, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

export const Calendar = styled(Box)`
  margin: 18px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Week = styled(Box)`
  background: #306fbc;
  border-radius: 16px;
  height: 36px;
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: 3vh;
`;

export const Name = styled(Box)`
  background: transparent;
  font: normal bold normal 14px/16px Roboto;
  color: white;
  text-align: center;
  margin-top: 10px;
`;

export const GridBox = styled(Box)`
  display: grid;
  grid-template-columns: repeat(7, 12vw);
  grid-template-rows: repeat(autofill, 10vh);
  min-height: 450px;
  border-radius: 8px;
  background: white;
  // border: 0.5px solid #929191;
  margin-top: 12px;
  overflow: auto;

  /* Change scrollbar color */
  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #163a90;
    border-radius: 8px;
  }
`;

export const Cell = styled(Box)`
  padding: 6px;
  font: normal bold normal 14px/16px Roboto;
  text-align: center;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  border-radius: 8px;
`;

export const Gradient = styled(Box)`
  width: 498px;
  height: 24px;
  background: linear-gradient(
    270deg,
    #008e1f 0%,
    rgba(245, 223, 24, 0.88) 46.94%,
    rgba(254, 59, 43, 0.990106) 95.62%,
    #ff2d2d 100%
  );
  border: 1px solid #1106415a;
  margin-top: 9px;
  border-radius: 12.5px;
`;

export const DatePickerSection = styled(Box)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const Page = styled(Box)`
  width: 100%;
  height: 100%;
  padding: 22px 0px 0px 76px;
`;

export const SelectHeader = styled(FormControl)(() => ({
  minWidth: 70,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  boxShadow: "0px 4px 7px 0px  rgba(48, 111, 188, 0.2)",
  borderRadius: "4px",
  marginLeft: "0 !important",
  border: "1px solid rgba(0, 0, 0, 0.4)",
  height: "40px",
  "& .MuiInputBase-root.MuiInput-root":{
    margin: 0
  },
  "& .MuiFormLabel-root": {
    margin: 0,
    backgroundColor: "#FFFFFF",
    padding: "0 4px",
    borderRadius: 2,
    color: "#000000"
  }
}));

export const Mainpaper = styled(Paper)(() => ({
  padding: "10px 0px 10px 6px",
  backgroundColor: "#CDDBFC",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "fixed",
  right: "0px",
  left: "0px",
  zIndex: 999,
}));

export const IconPaper = styled(Paper)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "40px",
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  borderRadius: "8px",
  cursor: "pointer",
}));

export const TruncateText = styled(Typography)(() => ({
  overflow: "hidden",
  maxWidth: "10ch",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

export const RateshopPageBox = styled(Box)(() => ({
  padding: "60px 0px 0px 40px",
  background: "#fff",
  ".headerStack": {
    padding: "9px 12px",
    display: "flex",
    flexDirection: "row",
    height: "55px",
    gap: "16px",
    minWidth: "217px",
    borderRadius: "8px",
    ".monthPicker": {
      padding: "4px 12px 9px 12px",
      height: "40px",
      width: "250px",
      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
      borderRadius: "8px",
    },
    ".dateBox": {
      padding: "5px",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      boxShadow: "0px 4px 7px 0px  rgba(48, 111, 188, 0.2)",
      zIndex: 101,
    },
    ".dateStack": {
      display: "flex",
      flexDirection: "row",
      width: "50%",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      color: "#281e53",
      ".to-text": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "18px",
        lineHeight: "16px",
        letterSpacing: "0px",
        color: "#281e53",
        paddingRight: "15px",
      },
    },
    ".exportButton": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: "8px",
      width: "50px",
      boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  ".pendingCredits": {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "5%",
    marginTop: "80px",
    fontSize: "16px",
    gap: "40px",
  },
  ".refreshIcon": {
    width: "40px",
    height: "40px",
    borderRadius: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 10px 2px rgba(3, 4, 94, 0.2)",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ":hover": {
      backgroundColor: "lightblue",
    },
  },
}));

export const StyledDialog = styled(Dialog)(() => ({
  position: "absolute",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(217, 217, 217, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 100,
  ".paperProps": {
    minHeight: "236px",
    maxWidth: "576px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  ".dialogHeader": {
    background: "#163A90",
    height: "58px",
    width: "576px",
    padding: "16px",
    ".dialogTitle": {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "24px",
      color: "#FFFFFF",
    },
  },
  ".dateHeading": {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  ".cancelButton": {
    border: "1px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "8px",
    textTransform: "none",
    height: "33px",
    color: " rgba(0, 0, 0, 0.6)",
  },
  ".confirmButton": {
    background: "#163A90",
    borderRadius: "8px",
    textTransform: "none",
    height: "33px",
  },
}));

export const StyledExport = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  width: "50px",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover": {
    cursor: "pointer",
  },
}));
