import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { useHistory, useParams } from "react-router-dom";
import { DatePicker, getISODate, LoadingPage, Nodata, useAuth } from "../sdk";
import LeadTimevsBoBTable from "./components/LeadTimevsBoBTable";
import {
  Body,
  Graph,
  Header,
  Label,
  Page,
  Segment,
  SubHeader,
  TableCard,
} from "./Styles";
import { styled } from "@mui/system";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

export const StyledPage = styled(Page)(({ theme }) => ({
  ".dropdownStyle": {
    background: "White",
    color: "black",
  },
  ".MuiMenuItem": {
    root: {
      fontSize: 12,
    },
  },
  icon: {
    fill: "white",
    display: "none",
  },
  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
    margin: 0,
    width: "144px",
  },
  ".label": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
  },
}));
export default function LeadTimevsBoB({ setPageHeader }) {
  const { token, authFetch, currentHotel } = useAuth();
  const { hotelId, DATE } = useParams();
  const [selectedData, setSelectedData] = useState([]);
  const [comparitiveData, setComparitiveData] = useState([]);
  const [graphData, setGraphData] = useState({ datasets: [], labels: [] });
  const [Loading, setLoading] = useState();
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState(() => {
    if (!isNaN(new Date(DATE).getTime())) {
      const [year, mon, day] = DATE.split("-");
      if (year && mon && day) {
        if (day && day.length === 1) {
          const validDate = [year, mon, "0" + day].join("-");
          return new Date(validDate);
        } else {
          return new Date(DATE);
        }
      } else {
        return new Date(new Date().setDate(new Date().getDate() - 1));
      }
    } else {
      return new Date(new Date().setDate(new Date().getDate() - 1));
    }
  });
  const [isComparitive, setIsComparitive] = useState(false);
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 8))
  );

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, hotelId]);
  useEffect(() => {
    setPageHeader("Lead Time - BoB Daily");
    return () => {
      setPageHeader("");
    };
  }, []);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      fetchData();
    }
  }, [token, hotelId, selectedDate]);

  useEffect(() => {
    if (!token) {
      return;
    } else {
      if (isComparitive && comparitiveDate) {
        fetchComparitiveData();
      } else {
        setComparitiveData([]);
      }
    }
  }, [token, hotelId, comparitiveDate, isComparitive]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    }
    history.replace(
      `/hotel/${hotelId}/analytics/lead_time_vs_bob_daily/${
        getISODate(selectedDate).split("T")[0]
      }`
    );
  }, [selectedDate, hotelId, token]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      if (selectedData) dataForGraph();
    }
  }, [token, hotelId, selectedData]);

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      if (comparitiveData) dataForGraph();
    }
  }, [token, hotelId, comparitiveData]);

  const graphOptions = useMemo(() => {
    function getDetails(value, data, datasetIndex) {
      console.log(data);
      let occ = null,
        compSet = null;
      if (datasetIndex === 1) {
        comparitiveData.forEach((data) => {
          if (value === data.leadTime) {
            occ = data.occupancyPercentage;
            compSet = data.compSet;
          }
        });
      } else if (datasetIndex === 0) {
        selectedData.forEach((data) => {
          if (value === data.leadTime) {
            occ = data.occupancyPercentage;

            compSet = data.compSet;
          }
        });
      }
      return { occ, compSet };
    }
    return {
      maintainAspectRatio: false,
      title: {
        text: "Lead Time - BoB Daily",
        display: true,
        fontFamily: "Roboto",
        fontSize: 20,
        fontColor: "black",
        fontStyle: "normal",
      },
      legend: {
        position: "bottom",
        align: "left",
        labels: {
          fontFamily: "Roboto",
          fontSize: 10,
          boxWidth: 10,
        },
      },
      tooltips: {
        bodyFontFamily: "Roboto",
        backgroundColor: "black",
        callbacks: {
          label: function (tooltipItem, data) {
            console.log(tooltipItem);
            let { occ, compSet } = getDetails(
              tooltipItem.xLabel,
              data,
              tooltipItem.datasetIndex
            );
            let array = [];
            array.push(`BoB : ${tooltipItem.yLabel}`);
            if (occ !== null) {
              array.push(
                occ === -1
                  ? `Occupancy% : -`
                  : `Occupancy% : ${parseFloat(occ).toFixed(2)}%`
              );
            }
            if (compSet !== null) {
              compSet.map((comp) => {
                array.push(
                  comp.price === -1
                    ? comp.competitorName + " : " + "Sold Out"
                    : comp.price === -999
                    ? comp.competitorName + " : " + "-"
                    : comp.competitorName + " : " + comp.price
                );
              });
            }
            return array;
          },
        },
      },
      elements: {
        line: {
          fill: false,
          borderColor: "#FBBC05",
          pointColor: "#FBBC05",
          backgroundColor: "#FBBC05",
          tension: 0,
        },
        point: {
          borderColor: "#FBBC05",
          backgroundColor: "#FBBC05",
        },
      },
      scales: {
        xAxes: [
          {
            offset: true,
            display: true,
            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
            },
            ticks: {
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,
            },
            scaleLabel: {
              display: true,
              labelString: "Lead Time",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
        yAxes: [
          {
            type: "linear",
            display: true,
            position: "left",

            gridLines: {
              display: true,
              drawTicks: true,
              drawOnChartArea: false,
            },
            ticks: {
              beginAtZero: true,
              fontFamily: "Roboto",
              fontColor: "black",
              fontSize: 10,
              suggestedMin: 0,
              suggestedMax: 20,
            },
            scaleLabel: {
              display: true,
              labelString: "BoB",
              fontColor: "black",
              fontFamily: "Roboto",
            },
          },
        ],
      },
    };
  }, [selectedData, comparitiveData]);

  async function fetchData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/lead-time-vs-bob/date/${
        getISODate(selectedDate).split("T")[0]
      }`,
    });

    const { data, error } = await get();
    if (data) {
      setSelectedData(data);
    } else {
      setSelectedData([]);
      console.log(error);
    }
    setLoading(false);
  }
  async function fetchComparitiveData() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/lead-time-vs-bob/date/${
        getISODate(comparitiveDate).split("T")[0]
      }`,
    });
    const { data, error } = await get();
    if (data) {
      setComparitiveData(data);
    } else {
      setComparitiveData([]);
      console.log(error);
    }
    setLoading(false);
  }

  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleComparitiveDateChange = (date) => {
    setComparitiveDate(date);
  };

  function dataForGraph() {
    if (selectedData) {
      const array = {
        labels: selectedData.map((data) => data.leadTime),
        datasets: [
          {
            label: `${new Date(selectedDate).toLocaleDateString("en-GB")}`,
            data: selectedData.map((data) =>
              data.bob === -1 ? null : data.bob
            ),
            borderColor: "#2541B2",
            pointBorderColor: "#2541B2",
            pointBackgroundColor: "#2541B2",
          },
          comparitiveData.length > 0
            ? {
                label: `${new Date(comparitiveDate).toLocaleDateString(
                  "en-GB"
                )}`,
                data: comparitiveData.map((data) =>
                  data.bob === -1 ? null : data.bob
                ),
                borderColor: "#1E90FF",
                pointBorderColor: "#1E90FF",
                pointBackgroundColor: "#1E90FF",
              }
            : undefined,
        ].filter(Boolean),
      };
      setGraphData(array);
    }
  }

  return currentHotel?.isPreciumEnabled ? (
    <StyledPage>
      <Body>
        <Header>
          <SubHeader>
            <Segment>
              <Label>Lead Time For</Label>
              <DatePicker
                date={selectedDate}
                inputFormat={"dd/MM/yyyy"}
                
                onChange={handleSelectedDateChange}
              />
            </Segment>
            <Segment>
              <FormControlLabel
                classes={{ label: "label" }}
                control={
                  <Checkbox
                    onChange={() => setIsComparitive(!isComparitive)}
                    value={isComparitive}
                    style={{
                      color: "#110641",
                    }}
                  />
                }
                label="Compare with"
              />
            </Segment>
            {isComparitive === true ? (
              <DatePicker
                inputFormat={"dd/MM/yyyy"}

                date={comparitiveDate}
                onChange={handleComparitiveDateChange}
              />
            ) : (
              <DatePicker
                inputFormat={"dd/MM/yyyy"}

                date={comparitiveDate}
                onChange={handleComparitiveDateChange}
                disabled
              />
            )}
          </SubHeader>
        </Header>
        {!Loading ? (
          selectedData ? (
            <div style={{ height: "85%" }}>
              <TableCard style={{ height: "70%", marginBottom: "20px" }}>
                <Graph>
                  <Line data={graphData} options={graphOptions} />
                </Graph>
              </TableCard>
              <TableCard>
                <LeadTimevsBoBTable selectedData={selectedData} />
              </TableCard>
            </div>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
      </Body>
    </StyledPage>
  ) : (
    <PreciumDisabledPage />
  );
}
