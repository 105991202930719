import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import { styled } from "@mui/system";
import { ChevronLeft, ChevronRight, CloseRounded } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DatePicker,
  ErrorPage,
  getISODate,
  LoadingPage,
  PrimaryButton,
  useAuth,
} from "../../sdk";
import UploadTable from "./components/UploadTable";
import {
  CardContainer,
  DateSection,
  DisplayDate,
  HeadSection,
  Label,
  PageContainer,
  TableSection,
  ToggelLeft,
  Toggle,
  ToggleRight,
  ToggleRightDisabled,
} from "./Styles";
import { TextField as TF } from "@mui/material";

const TextField = styled(TF)`
  input {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
  }
`;
const StyledPageContainer = styled(PageContainer)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
  },
}));

const MonthName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const Cross = styled(CloseRounded)`
  width: 20px;
  height: 20px;
  margin-left: 15px;
  :hover {
    cursor: pointer;
  }
`;

export default function UploadInfoPage() {
  const { hotelId } = useParams();
  const { token, authFetch, hotelSelect, permission } = useAuth();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [uploadData, setUploadData] = useState();
  const [hotelData, setHotelData] = useState();
  const [managingOrganizations, setManagingOrganizations] = useState([]);
  const [selectedChainId, setSelectedChainId] = useState();
  const [selectedChain, setSelectedChain] = useState();
  const [selectedOrganizationId, setSelectedOrganizationId] = useState();
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [chains, setChains] = useState([]);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState();

  const organizationId = hotelSelect?.organizationId;
  const All = "All";
  let acrossHotel = null;
  let fileUploadedDelete = false;
  useEffect(() => {
    if (token && acrossHotel) {
      getManagingOrganization();
      setSelectedOrganizationId(hotelSelect?.managedBy);
      setSelectedOrganization(hotelSelect?.managedByName);
      setSelectedChainId(hotelSelect?.organizationId);
      setSelectedChain(hotelSelect?.organizationName);
    } else {
      return;
    }
  }, [token]);

  useEffect(() => {
    if (token && acrossHotel) {
      if (!selectedOrganizationId) {
        return;
      } else {
        getChains();
      }
    } else {
      return;
    }
  }, [token, selectedOrganizationId]);

  useEffect(() => {
    if (token) {
      getUploads();
    }
  }, [token, selectedDate]);

  useEffect(() => {
    if (acrossHotel && token && uploadData?.length > 0) {
      if (!selectedOrganizationId || !selectedChainId) {
        return;
      } else {
        selectUploads();
      }
    } else if (token) {
      selectUploads();
    } else {
      return;
    }
  }, [
    token,
    selectedChainId,
    selectedOrganizationId,
    selectedDate,
    uploadData,
  ]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "acrossHotel") {
        acrossHotel = permission[key];
      }
      if (permission[key].name === "fileUploadedDelete") {
        fileUploadedDelete = true;
      }
    }
  }

  const defaultOrgProps = {
    options: [
      ...managingOrganizations.map((org) => {
        return org.name;
      }),
    ],
    getOptionLabel: (option) => option,
  };
  const defaultChainProps = {
    options: [
      ...chains.map((chain) => {
        return chain.name;
      }),
      All,
    ],
    getOptionLabel: (option) => option,
  };

  function toggelLeft() {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() - 1)));
  }

  function toggelRight() {
    setSelectedDate(new Date(selectedDate.setDate(selectedDate.getDate() + 1)));
  }

  async function getManagingOrganization() {
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      setManagingOrganizations(data);
    } else {
      console.log(error);
    }
  }

  async function getChains() {
    setLoading(true);

    const { get } = await authFetch({
      path: `/managing-org/${selectedOrganizationId}/chains`,
    });
    const { data, error } = await get();
    if (data) {
      setChains(data);
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  const handleOrgChange = (value) => {
    const orgId = managingOrganizations.find((org) => org.name === value);
    if (orgId) {
      setSelectedOrganizationId(orgId.id);
      setSelectedOrganization(orgId.name);
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
    } else {
      setSelectedOrganizationId(null);
    }
  };

  const handleChainChange = (value) => {
    if (value === All) {
      setSelectedChainId(All.toLowerCase());
      setSelectedChain(All);
      setHotelData([]);
    } else {
      const chainId = chains.find((chain) => chain.name === value);
      if (chainId) {
        setSelectedChainId(chainId.id);
        setSelectedChain(chainId.name);
        setHotelData([]);
      } else {
        setSelectedChainId(null);
      }
    }
  };

  async function getUploads() {
    setLoading(true);
    const { post } = await authFetch({
      path: `/all-hotels-logs`,
    });
    const { data, error } = await post({ date: new Date(selectedDate) });
    if (data) {
      setUploadData(data);
      setError(false);
    } else {
      setError(true);
      setUploadData([]);
      console.log(error);
    }
    setLoading(false);
  }

  function selectUploads() {
    acrossHotel
      ? !(selectedChain === All)
        ? setHotelData(
            uploadData?.hotels?.filter(
              (hotel) => hotel.organizationID === selectedChainId
            )
          )
        : setHotelData(
            uploadData?.hotels?.filter(
              (hotel) => hotel.managedByID === selectedOrganizationId
            )
          )
      : setHotelData(
          uploadData?.hotels?.filter(
            (hotel) => hotel.organizationID === organizationId
          )
        );
  }

  async function deleteFile() {
    if (file) {
      setLoading(true);
      if (fileType === "forecast") {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/file/${file.fileID}`,
        });
        const { data, error } = await del();
        if (data) {
          setShouldBlockNavigation(false);
          getUploads();
        } else if (error) {
          console.log(error);
        }
      } else if (fileType === "competitor") {
        const { del } = await authFetch({
          path: `/hotel/${hotelId}/competitor-price/${file.fileID}`,
        });
        const { data, error } = await del();
        if (data) {
          setShouldBlockNavigation(false);
          getUploads();
        } else if (error) {
          console.log(error);
        }
      }
      setLoading(false);
    }
  }
  function confirmation() {
    const val = window.confirm("Please confirm again");
    if (val) {
      deleteFile();
    }
    return;
  }
  return !Error ? (
    !Loading ? (
      <StyledPageContainer>
        <Dialog
          open={shouldBlockNavigation}
          onClose={() => setShouldBlockNavigation(false)}
        >
          <DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Label> Are you sure you want to delete this file? </Label>
              <Cross onClick={() => setShouldBlockNavigation(false)}>
                &times;
              </Cross>
            </div>{" "}
          </DialogTitle>
          <DialogActions
            style={{ justifyContent: "space-evenly", paddingBottom: 16 }}
          >
            <PrimaryButton
              autoFocus
              next
              onClick={() => {
                setShouldBlockNavigation(false);
                confirmation();
              }}
            >
              Yes
            </PrimaryButton>
            <PrimaryButton next onClick={() => setShouldBlockNavigation(false)}>
              No
            </PrimaryButton>
          </DialogActions>
        </Dialog>
        <CardContainer>
          <HeadSection>
            <DateSection>
              <Toggle>
                <ToggelLeft onClick={() => toggelLeft()}>
                  <ChevronLeft />
                </ToggelLeft>
                {getISODate(selectedDate).split("T")[0] !==
                getISODate(new Date()).split("T")[0] ? (
                  <ToggleRight>
                    <ChevronRight onClick={() => toggelRight()} />
                  </ToggleRight>
                ) : (
                  <ToggleRightDisabled>
                    <ChevronRight />
                  </ToggleRightDisabled>
                )}
              </Toggle>
              <DatePicker
                inputFormat={"dd/MM/yyyy"}
                style={{ zIndex: 98 }}
                value={selectedDate}
                onChange={(e) => setSelectedDate(new Date(e))}
              />
            </DateSection>
            {acrossHotel && (
              <Autocomplete
                {...defaultOrgProps}
                value={selectedOrganization}
                style={{ width: "30%", padding: "0 8px 0 8px" }}
                freeSolo={true}
                onChange={(event, newValue) => {
                  handleOrgChange(newValue);
                }}
                classes={{
                  paper: "dropdownStyle",
                }}
                disableClearable={true}
                renderInput={(params) => (
                  <TextField {...params} label="Organization" />
                )}
              />
            )}
            {acrossHotel && (
              <Autocomplete
                {...defaultChainProps}
                value={selectedChain}
                style={{ width: "30%", padding: "0 8px 0 8px" }}
                freeSolo={true}
                disableClearable={true}
                onChange={(event, newValue) => {
                  handleChainChange(newValue);
                }}
                classes={{
                  paper: "dropdownStyle",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Chain" />
                )}
              />
            )}
          </HeadSection>
          <TableSection>
            <UploadTable
              data={hotelData}
              setShouldBlockNavigation={setShouldBlockNavigation}
              setFile={setFile}
              setFileType={setFileType}
              fileUploadedDelete={fileUploadedDelete}
            ></UploadTable>
          </TableSection>
        </CardContainer>
      </StyledPageContainer>
    ) : (
      <LoadingPage />
    )
  ) : (
    <ErrorPage />
  );
}
