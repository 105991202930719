import Autocomplete from "@mui/material/Autocomplete";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getFullDate, getISODate, useAuth } from "../../sdk";
import { styled } from "@mui/system";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import {
  Cross,
  Done,
  Forecast,
  Head,
  InputField,
  Label,
  ScheduleIconDisabled,
  Section,
  TextFieldBig,
  UploadSection,
} from "../Styles";
import {
  Box,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { CalendarToday, Schedule } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function getModalStyle() {
  const top = 9;
  const left = 30;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}, -${left})`,
  };
}
const StyledSection = styled(Section)(({ theme }) => ({
  position: "absolute",
  width: "600px",
  backgroundColor: "#fff",
  boxShadow: 5,
}));
export function ModelBody({
  fileId,
  setOpenSelectBar,
  contactDetails,
  emailid,
  setnetworkMsg,
  setOpen,
  setFileId,
}) {
  const [modalStyle] = useState(getModalStyle);
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [email, setEmail] = useState([]);
  const [fromDateFiles, setFromDateFiles] = useState([]);
  const [toDateFiles, setToDateFiles] = useState([]);
  const [toFileId, setToFileId] = useState(0);
  const [fromDate, setFromDate] = useState(() => {
    return new Date(new Date().setDate(new Date().getDate() - 1));
  });
  const [fromFileId, setFromFileId] = useState(0);
  const [toDate, setToDate] = useState(() => {
    return new Date();
  });
  const [pickup, setPickup] = useState(
    Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24))
  );
  const maximumToDate = new Date();

  function handlePickup(value) {
    if (value === "") {
      setPickup(value);
      setFromDate(
        new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate() - 1)
      );

      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }
    setPickup(value);
    setFromDate(new Date(toDate.getTime() - value * 24 * 60 * 60 * 1000));
  }
  const defaultProps = {
    options: [...contactDetails.map((detail) => detail.email), emailid],
    getOptionLabel: (option) => option,
  };
  async function submitEmail() {
    if (email.length === 0) {
      setnetworkMsg("Enter a email");
      setOpen(true);
      return;
    }
    setOpenSelectBar(false);

    const { post } = await authFetch({
      path: `/hotel/${hotelId}/email-excel/${toFileId}`,
    });
    const { data, error, response } = await post({
      emailList: email.map((mail) => mail.trim()),
      pickup: parseInt(pickup),
      FromFile: fromFileId,
    });
    if (!response?.ok) {
      setnetworkMsg(data?.messageToUser || "Something Went Wrong");
      setOpen(true);
    } else if (!!data && data?.message === "Email Sent Successfully") {
      setnetworkMsg("Email Sent Successfully");
      setOpen(true);
      console.log("Email sent");
    }
    setFileId(toFileId);
  }
  const handleChange = (newValue, type = "from") => {
    if (type === "from") {
      setFromFileId(newValue);
    } else {
      setToFileId(newValue);
    }
  };

  const getFileTimes = useCallback(async () => {
    try {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/bookings/file/${
          getISODate(fromDate).split("T")[0]
        }`,
      });
      const { data, error } = await get();

      if (error) {
        throw new Error(error);
      }

      const modifiedData = modifiedDateFiles(data);
      setFromDateFiles(modifiedData);

      const latestFileId =
        [...data].sort(
          (a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn)
        )[0]?.id ?? 0;

      setFromFileId(latestFileId);
    } catch (error) {
      console.error("Error fetching file times:", error);
    }
  }, [authFetch, fromDate, hotelId]);

  function modifiedDateFiles(data) {
    if (!data || !data.length) {
      return [];
    }
    return data
      .sort((a, b) => new Date(b.uploadedOn) - new Date(a.uploadedOn))
      .map((file) => {
        let hrs = new Date(new Date(file.uploadedOn).getTime()).getHours();
        let mins = new Date(new Date(file.uploadedOn).getTime()).getMinutes();
        if (mins < 10) {
          mins = "0" + mins;
        }
        if (hrs / 12 > 1) {
          return {
            ...file,
            time: (hrs % 12) + ":" + mins + " PM ",
          };
        } else {
          return {
            ...file,
            time: hrs + ":" + mins + " AM ",
          };
        }
      });
  }

  useEffect(() => {
    getFileTimes();
  }, [getFileTimes]);

  const fetchToFiles = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/price-recommendation/${
        getISODate(toDate).split("T")[0]
      }/all`,
    });
    const { data } = await get();
    if (data && data.length) {
      setToDateFiles(
        data
          .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated))
          .map((file) => {
            const date = getFullDate(file.lastUpdated);
            let hrs = new Date(new Date(file.lastUpdated).getTime()).getHours();
            let mins = new Date(
              new Date(file.lastUpdated).getTime()
            ).getMinutes();
            let sec = new Date(
              new Date(file.lastUpdated).getTime()
            ).getSeconds();
            if (mins < 10) {
              mins = "0" + mins;
            }
            if (sec < 10) {
              sec = "0" + sec;
            }
            if (hrs / 12 > 1) {
              return {
                ...file,
                time: date + "  " + (hrs % 12) + ":" + mins + " pm ",
              };
            } else if (hrs / 12 === 1) {
              return {
                ...file,
                time: date + "  " + hrs + ":" + mins + " pm ",
              };
            } else {
              return {
                ...file,
                time: date + "  " + hrs + ":" + mins + " am ",
              };
            }
          })
      );

      const file = [...data].sort(
        (a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)
      )[0];
      setToFileId(file.id);
    } else {
      setToDateFiles([]);
      setToFileId(0);
    }
  }, [authFetch, hotelId, toDate]);

  useEffect(() => {
    fetchToFiles();
  }, [fetchToFiles]);

  return (
    <StyledSection style={modalStyle}>
      <Head>
        <Forecast>Send Email</Forecast>
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            width: "45%",
          }}
        >
          <Cross onClick={() => setOpenSelectBar(false)}>&#x274C;</Cross>
        </Box>
      </Head>
      <UploadSection>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"center"}
          mt={2}
        >
          <HeaderCard>
            <LabelNew>From</LabelNew>
            
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledCalenderWrapper>
              <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    style={{ zIndex: 98 }}
                    value={fromDate}
                    onChange={(date)=>{
                      const diffDays = Math.round((toDate - date) / (1000 * 60 * 60 * 24));
                      setFromDate(date)
                      setPickup(diffDays);
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => <Box 
                    className="my-custom-input-class" sx={{ display: 'flex', position: "relative", alignItems: 'center', justifyContent: "flex-start" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      readOnly
                      style={{
                        textAlign: "left",
                        marginBottom: "2px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        color: "#000000",
                        outline: "none",
                        height: "30px",
                        border: "none",
                        font: "bold 16px / 20px Roboto",
                        cursor: "pointer",
                        width: "140px",
                        paddingLeft: "14px",
                      }}
                    />
                    <Box className="date-button-container" sx={{position: "absolute", right: 0, width:"100%"}}>
                    {InputProps?.endAdornment}
                    </Box>
                  </Box>}
                  maxDate={toDate}
                  />
              </StyledCalenderWrapper>
            </LocalizationProvider>
          </HeaderCard>
          <HeaderCardCenterNew
            sx={{
              paddingLeft: "0px",
            }}
          >
            {fromDateFiles.length > 0 ? (
              <Select
                disableUnderline
                MenuProps={{
                  classes: {
                    paper: "dropdownStyle",
                  },
                }}
                variant="standard"
                IconComponent="none"
                inputProps={{
                  classes: {
                    icon: "icon",
                  },
                }}
                value={fromFileId}
                onChange={(e) => handleChange(e.target.value, "from")}
              >
                {fromDateFiles.map((file, index) => (
                  <MenuItem value={file.id} key={index}>
                    <Tab
                      style={{
                        marginRight: "10px",
                      }}
                      label={file.time}
                      value={file.id}
                      {...a11yProps(file.id)}
                    />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Box style={{ width: "56px" }}></Box>
            )}
            {fromDateFiles.length > 0 ? (
              <Schedule
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#306FBC",
                  marginLeft: "-42px",
                }}
              />
            ) : (
              <ScheduleIconDisabled
                style={{
                  color: "#6f6f6f",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
          </HeaderCardCenterNew>
          <HeaderCard>
            <LabelNew>To</LabelNew>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StyledCalenderWrapper>
              <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    style={{ zIndex: 98 }}
                    value={toDate}
                    onChange={(date)=>{
                      let newDate = new Date(date)
                      setToDate(date)
                      setFromDate(new Date(newDate.setDate(newDate.getDate()-pickup)))
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => <Box 
                    className="my-custom-input-class" sx={{ display: 'flex', position: "relative", alignItems: 'center', justifyContent: "flex-start" }}>
                    <input
                      ref={inputRef}
                      {...inputProps}
                      readOnly
                      style={{
                        textAlign: "left",
                        marginBottom: "2px",
                        fontSize: "14px",
                        borderRadius: "10px",
                        color: "#000000",
                        outline: "none",
                        height: "30px",
                        border: "none",
                        font: "bold 16px / 20px Roboto",
                        cursor: "pointer",
                        width: "140px",
                        paddingLeft: "14px",
                      }}
                    />
                    <Box className="date-button-container" sx={{position: "absolute", right: 0, width:"100%"}}>
                    {InputProps?.endAdornment}
                    </Box>
                  </Box>}
                    maxDate={maximumToDate}
                  />
              </StyledCalenderWrapper>
            </LocalizationProvider>
          </HeaderCard>
          <HeaderCardCenterNew
            sx={{
              paddingLeft: "0px",
            }}
          >
            {toDateFiles.length > 0 ? (
              <Select
                disableUnderline
                MenuProps={{
                  classes: {
                    paper: "dropdownStyle",
                  },
                }}
                variant="standard"
                IconComponent="none"
                inputProps={{
                  classes: {
                    icon: "icon",
                  },
                }}
                value={toFileId}
                onChange={(e) => handleChange(e.target.value, "to")}
              >
                {toDateFiles.map((file, index) => (
                  <MenuItem value={file.id} key={index}>
                    <Tab
                      label={file.time?.split("  ")[1]}
                      value={file.id}
                      {...a11yProps(file.id)}
                    />
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Box style={{ width: "56px" }}></Box>
            )}

            {toDateFiles.length > 0 ? (
              <Schedule
                style={{
                  width: "20px",
                  height: "20px",
                  color: "#306FBC",
                  marginLeft: "-42px",
                }}
              />
            ) : (
              <ScheduleIconDisabled
                style={{
                  color: "#6f6f6f",
                  width: "20px",
                  height: "20px",
                }}
              />
            )}
          </HeaderCardCenterNew>
        </Stack>
        <Stack
          direction={"row"}
          width={"100%"}
          sx={{
            marginLeft: "33px",
          }}
          justifyContent={"space-between"}
        >
          <InputField>
            <Label htmlFor="picker">Email</Label>

            <Autocomplete
              multiple
              {...defaultProps}
              value={email}
              freeSolo
              autoSelect
              onChange={(event, newValue) => setEmail(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    label: {
                      background: "white",
                    },
                    padding: "4px !important",
                    whiteSpace: "normal",
                    ".MuiAutocomplete-inputRoot": {
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                      width: 200,
                      height: "70px",
                      overflowY: "auto",
                      padding: "4px !important",
                      alignItems: "start !important",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      " -ms-overflow-style": "none",
                      " scrollbar-width": "none",
                      input: {
                        minWidth: 100,
                        padding: "4px !important",
                        alignItems: "start !important",
                      },
                      fieldset: {
                        display: "none",
                      },
                      "& .MuiAutocomplete-tag": {
                        maxWidth: "160px",
                        wordBreak: "break-word",
                      },
                    },
                    "& .MuiAutocomplete-inputRoot.Mui-focused": {
                      border: "1px solid #1976d2",
                    },
                  }}
                />
              )}
              PaperComponent={(props) => (
                <Paper
                  {...props}
                  sx={{
                    "& .MuiAutocomplete-listbox": {
                      "& li": {
                        whiteSpace: "normal",
                        wordBreak: "break-word",
                      },
                    },
                  }}
                />
              )}
            />
          </InputField>
          <InputField sx={{ width: "100%" }}>
            <Label>Pickup</Label>
            <TextFieldBig
              type="number"
              variant="standard"
              value={pickup}
              InputProps={{ inputProps: { min: 1, max: 100 } }}
              onChange={(e) => handlePickup(e.target.value)}
            />
          </InputField>
        </Stack>
      </UploadSection>

      <Done
        disabled={toDateFiles?.length === 0}
        next
        onClick={submitEmail}
        type="button"
      >
        Send
      </Done>
    </StyledSection>
  );
}
export const HeaderCard = styled(Box)`
  display: flex;
  align-items: center;
  color: #281e53;
`;
export const LabelNew = styled(Typography)`
  font: normal normal bold 14px/16px Roboto;
  color: #130453;
  line-height: 16px;
  letter-spacing: 0px;
  font-weight: 500;
  padding-right: 5px;
`;
export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 98;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;
const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));
export const HeaderCardCenterNew = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  height: 32px;
  button.MuiButtonBase-root {
    opacity: 1;
    min-width: 100px;
    span.MuiTab-wrapper {
      font: normal normal bold 16px / 20px Roboto;
      color: #281e53;
    }
  }
`;
