import {
  Button,
  MenuItem,
  Select as SL,
  Snackbar,
  SnackbarContent,
  Checkbox,
  FormControlLabel,
  Tooltip,
  Modal,
  Box,
  Typography,
  Stack,
  Link,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  ArrowBackIos,
  ArrowForwardIos,
  CalendarToday,
  CallMade,
  KeyboardArrowDown,
} from "@mui/icons-material";
import Chart from "chart.js";
import chartDataLabels from "chartjs-plugin-datalabels";
import React, { useCallback, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import {
  Bar as BarNew,
  BarChart,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line as LineNew,
  ResponsiveContainer,
  Tooltip as TooltipNew,
  XAxis,
  YAxis,
} from "recharts";
import {
  ErrorPage,
  LoadingPage,
  MonthPicker,
  NoAccess,
  Nodata,
  useAuth,
} from "../sdk";
import { ModelBody } from "./components/GraphPopover";
import LastNightCard from "./components/LastNightCard";
import MonthlyBookingCard from "./components/MonthlyBookingCard";
import YearlyBookingCard from "./components/YearlyBookingCard";
import useDashboard from "./hooks/useDashboard";

import {
  ARRGraph,
  Body,
  BottomRow,
  CardName,
  Head,
  HybridGraphCard,
  Label,
  Message,
  MessageBox,
  MiddleRow,
  MonthPickerContainer,
  OccupancyGraph,
  Page,
  RevPARGraph,
  RightHeader,
  SimpleGraphCard,
  TopCard,
  TopRow,
  WeeklyBody,
  WeeklyCard,
  WeeklyHeader,
} from "./Styles";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

Chart.plugins.unregister(chartDataLabels);

function getModalStyle() {}
const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    color: "black",
  },
  ".MuiMenuItem": {
    ".root": {
      fontSize: 10,
    },
  },
  ".icon": {
    fill: "white",
    display: "none",
  },
  ".input": {
    font: "normal normal bold 12px / 15px Roboto",
  },
  ".label": {
    font: "normal normal bold 17px / 20px Roboto",
    alignItems: "baseline",
    whiteSpace: "nowrap",
    marginRight: "-2px",
  },

  ".underline": {
    width: "100%",
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));
const OpenWithIcons = styled(CallMade)`
  background-color: #e0e0e0;
  &:hover {
    cursor: pointer;
  }
`;

const OccupancyGraphNew = styled(OccupancyGraph)`
  border: 3px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 0px;
`;

const ARRGraphNew = styled(ARRGraph)`
  border: 3px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 0px;
`;

const RevPARGraphNew = styled(RevPARGraph)`
  border: 3px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 16px;
  padding: 0 20px 0 0px;
`;
const CardNameNew = styled(CardName)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
`;
const TitleNew = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
`;

const MonthPickerContainerNew = styled(MonthPickerContainer)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
`;

const LabelNew = styled(Label)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;

  color: #000000;
`;
export const Select = styled(SL)`
  text-align: center;

  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;

  width: 100px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;

  color: #11263c;
`;
export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
`;
export default function Dashboard({ setPageHeader }) {
  const [modalStyle] = useState(getModalStyle);
  const {
    token,
    hotelSelect,
    selectHotel,
    permission,
    currentHotel,
  } = useAuth();
  const { hotelId } = useParams();
  const [Loading, setLoading] = useState(false);
  const [HaveData, setHaveData] = useState(false);
  const [Error, setError] = useState(false);
  const [networkMsg, setNetworkMsg] = useState();
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 28)
  );
  const history = useHistory();
  const [check, setCheck] = useState(false);
  const [isComparitive, setIsComparitive] = useState(false);
  const [openSelectbar, setOpenSelectBar] = useState(false);
  const [isSevenDayData, checkIsSevenDayData] = useState(true);
  const [isFourteenDayData, checkIsFourteenDayData] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [comparitiveDate, setComparitiveDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 2, 28)
  );

  let dayArray = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday",
  };
  const [day, setDay] = useState(7);
  useEffect(() => {
    setPageHeader("Dashboard");
    return () => {
      setPageHeader("");
    };
  }, []);
  let dashboard = null;
  let ViewAllHotelsDashboard = null;
  const {
    topdata,
    pickup,
    pickupOptions,
    price,
    priceOptions,
    occupancy,
    arr,
    revpar,
    weeklyOptions,
    occupancyOptions,
    arrOptions,
    revenueOptions,
    open,
    handleCloseStatus,
    priceForFifteenDay,
    priceOptionsForFifteenDay,
    priceForSevenDay,
    priceOptionsForSevenDay,
    priceForFourteenDay,
    priceOptionsForFourteenDay,
    priceForTwentyOneDay,
    priceOptionsForTwentyOneDay,
    idx,
    setIndex,
    idxNew,
    setIndexNew,
    comp1,
    priceColorPalette,
    hotelName,
    opacity,
    setOpacity,
    dataKeys,
    setDataKeys,
    opacitySevenDays,
    setOpacitySevenDays,
    dataKeysSevenDays,
    setDataKeysSevenDays,
    opacityThirtyDays,
    setOpacityThirtyDays,
    dataKeysThirtyDays,
    setDataKeysThirtyDays,
    occDataNew,
    arrDataNew,
    revParDataNew,
    occDataCurrent,
    comparitiveDateNew,
    selectedDateNew,
    arrDataCurrent,
    revParDataCurrent,
    sevenDayMax,
    fourteenDayMax,
    selectedYear,
    setSelectedYear,
    yearOptions,
  } = useDashboard(
    setLoading,
    setHaveData,
    setError,
    selectedDate,
    isComparitive,
    comparitiveDate,
    setIsComparitive,
    setNetworkMsg
  );
  const CustomTooltip = ({ active, payload, label }) => {
    const dataNew = payload[0]?.payload;

    if (!dataNew) return null;
    const tooltipData = Object?.entries(dataNew);

    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <p>{dayArray[` ${tooltipData[1][1]}`.trim()]}</p>

          {tooltipData.map((data, idx) => {
            return (
              <>
                <Box key={idx}>
                  <p
                    style={
                      data[0] === "Day"
                        ? { display: "none" }
                        : {
                            color:
                              idx === 0 ? "#306FBC" : "rgba(48, 111, 188, 0.4)",
                          }
                    }
                  >{` ${data[1]}`}</p>
                </Box>
              </>
            );
          })}
        </Box>
      );
    }

    return null;
  };
  const CustomTooltipARR = ({ active, payload, label }) => {
    const dataNew = payload[0]?.payload;

    if (!dataNew) return null;
    const tooltipData = Object?.entries(dataNew);

    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <p>{dayArray[` ${tooltipData[1][1]}`.trim()]}</p>

          {tooltipData.map((data, idx) => {
            return (
              <>
                <Box key={idx}>
                  <p
                    style={
                      data[0] === "Day"
                        ? { display: "none" }
                        : {
                            color:
                              idx === 0 ? "#306FBC" : "rgba(48, 111, 188, 0.4)",
                          }
                    }
                  >{` ${data[1]}`}</p>
                </Box>
              </>
            );
          })}
        </Box>
      );
    }

    return null;
  };
  const CustomTooltipRevPAR = ({ active, payload, label }) => {
    const dataNew = payload[0]?.payload;

    if (!dataNew) return null;
    const tooltipData = Object?.entries(dataNew);

    if (active && payload && payload.length) {
      return (
        <Box className="custom-tooltip">
          <p>{dayArray[` ${tooltipData[1][1]}`.trim()]}</p>

          {tooltipData.map((data, idx) => {
            return (
              <>
                <Box key={idx}>
                  <p
                    style={
                      data[0] === "Day"
                        ? { display: "none" }
                        : {
                            color:
                              idx === 0 ? "#306FBC" : "rgba(48, 111, 188, 0.4)",
                          }
                    }
                  >{` ${data[1]}`}</p>
                </Box>
              </>
            );
          })}
        </Box>
      );
    }

    return null;
  };

  useEffect(() => {
    if (!token && !hotelId) {
      return;
    } else {
      window.scrollTo(0, 0);
    }
  }, [token, hotelId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "dashboard") {
        dashboard = permission[key];
      }
      if (permission[key].name === "ViewAllHotelsDashboard") {
        ViewAllHotelsDashboard = permission[key];
      }
    }
  }
  const handleChange = (value) => {
    if (value === 14) {
      checkIsSevenDayData(false);
      checkIsFourteenDayData(true);
      setDay(14);
    } else {
      checkIsSevenDayData(true);
      checkIsFourteenDayData(false);
      setDay(7);
    }
  };

  const handleClick = (val) => {
    if (val === "inc") {
      setIndex(idx + 1);
    } else {
      setIndex(idx - 1);
    }
  };
  const handleClickNew = (val) => {
    if (val === "inc") {
      setIndexNew(idxNew + 1);
    } else {
      setIndexNew(idxNew - 1);
    }
  };
  const getIndexAsKey = () => {
    return Math.random();
  };
  const handleClickN = (o) => {
    const { dataKey } = o;
    if (
      dataKeysSevenDays[dataKey] === dataKey &&
      opacitySevenDays[dataKey] === 1
    ) {
      setOpacitySevenDays({ ...opacitySevenDays, [dataKey]: 0 });
      setDataKeysSevenDays({
        ...dataKeysSevenDays,
        [dataKey]: dataKeysSevenDays[dataKey],
      });
    } else {
      setOpacitySevenDays({ ...opacitySevenDays, [dataKey?.trim()]: 1 });
      setDataKeysSevenDays({
        ...dataKeysSevenDays,
        [dataKey?.trim()]: dataKeysSevenDays[dataKey?.trim()],
      });
    }
  };

  const handleColorChange = useCallback(
    (entry, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );
  const handleColor = useCallback(
    (entry, index) => {
      setActiveIndex(-1);
    },
    [setActiveIndex]
  );
  const handleMouseEnter = useCallback(
    (o) => {
      const { dataKey } = o;

      if (dataKeys[dataKey] === dataKey && opacity[dataKey] === 1) {
        setOpacity({ ...opacity, [dataKey]: 0 });
        setDataKeys({ ...dataKeys, [dataKey]: dataKeys[dataKey] });
      } else {
        setOpacity({ ...opacity, [dataKey]: 1 });
        setDataKeys({
          ...dataKeys,
          [dataKey]: dataKeys[dataKey],
        });
      }
    },
    [opacity, setOpacity, setDataKeys, dataKeys]
  );
  return currentHotel?.isPreciumEnabled ? (
    !Error && dashboard ? (
      <Page>
        <ClassWrapper>
          <Head>
            {!Loading && (
              <MessageBox>
                <Message>{topdata?.message}</Message>
              </MessageBox>
            )}
          </Head>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleCloseStatus}
          >
            <SnackbarContent
              style={{ backgroundColor: "#CA3433" }}
              message={networkMsg}
            />
          </Snackbar>
          {!Loading && HaveData ? (
            <Body>
              <Stack direction={"row"} alignItems={"center"} mt={2}>
                {Boolean(
                  ViewAllHotelsDashboard && !["353", "356"].includes(hotelId)
                ) ? (
                  <Button
                    sx={{
                      background: "#fff",
                      color: "#303030",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      boxShadow: "0px 4px 4px 0px #00000040",
                      "&:hover": {
                        background: "#fff",
                      },
                    }}
                    onClick={() => history.push("/dashboard/all-hotels")}
                  >
                    View All Hotels
                  </Button>
                ) : null}
              </Stack>
              <TopRow>
                <TopCard
                  style={{
                    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
                  }}
                >
                  <LastNightCard data={topdata} />
                </TopCard>
                <TopCard
                  style={{
                    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <MonthlyBookingCard
                    selectedMonthYear={selectedYear}
                    setSelectedMonthYear={setSelectedYear}
                    data={topdata}
                    yearOptions={yearOptions}
                  />
                </TopCard>
                <TopCard
                  style={{
                    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <YearlyBookingCard
                    selectedYear={selectedYear}
                    setSelectedYear={setSelectedYear}
                    data={topdata}
                    yearOptions={yearOptions}
                  />
                </TopCard>
              </TopRow>
              <MiddleRow>
                <HybridGraphCard
                  style={{
                    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
                    width: "65.5%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "98%",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          marginLeft: "34px",
                        }}
                      >
                        <LabelNew>Occupancy% vs Price</LabelNew>
                      </Box>
                      {!openSelectbar && (
                        <Box
                          style={{
                            // position: "absolute",
                            // left: "30%",
                            // top: -3,
                            display: "flex",
                            width: "100px",
                            height: "32px",
                          }}
                        >
                          <Select
                            value={day}
                            label=""
                            onChange={(e) => handleChange(e.target.value)}
                            className="dropdownStyle"
                            disableUnderline
                            required={true}
                            IconComponent={KeyboardArrowDown}
                          >
                            <MenuItem
                              style={{
                                font: "normal normal normal 16px/20px Roboto",
                              }}
                              value={7}
                            >
                              {"7 Days"}
                            </MenuItem>
                            <MenuItem
                              style={{
                                font: "normal normal normal 16px/20px Roboto",
                              }}
                              value={14}
                            >
                              {"14 Days"}
                            </MenuItem>
                          </Select>
                        </Box>
                      )}
                      <Tooltip
                        disableFocusListener
                        interactive
                        disableTouchListener
                        arrow
                        title={"Expand"}
                      >
                        <OpenWithIcons onClick={() => setOpenSelectBar(true)} />
                      </Tooltip>
                    </Box>
                    {isSevenDayData && (
                      <ResponsiveContainer width="100%" height="95%">
                        <ComposedChart
                          data={priceForSevenDay.datasets}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid stroke="#FFFFFF" />
                          <XAxis
                            dataKey="date"
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            interval={0}
                          />
                          <YAxis
                            yAxisId="left"
                            orientation="left"
                            stroke="#777777"
                            type="number"
                            domain={[0, 100]}
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            tickFormatter={(tick) => {
                              return `${tick}%`;
                            }}
                            label={{
                              value: "Occupancy%",
                              angle: -90,
                              position: "insideLeft",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              color: "#777777",
                            }}
                          />
                          <YAxis
                            yAxisId="right"
                            orientation="right"
                            stroke="#777777"
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            type="number"
                            ticks={[
                              0,
                              Math.trunc(sevenDayMax / 4),
                              Math.trunc(sevenDayMax / 2),
                              Math.trunc((3 * sevenDayMax) / 4),
                              sevenDayMax,
                              sevenDayMax + Math.trunc(sevenDayMax / 4),
                            ]}
                            domain={[0, sevenDayMax]}
                          />
                          <TooltipNew
                            formatter={(label, name) =>
                              label === 0 && name !== `${hotelName}` + " (Occ%)"
                                ? "Sold Out"
                                : name.includes("(Occ%)")
                                ? label + "%"
                                : label === -2
                                ? "NA"
                                : label
                            }
                            wrapperStyle={{
                              fontSize: "12px",
                              fontFamily: "Roboto",
                            }}
                          />
                          <Legend
                            onClick={handleClickN}
                            formatter={(value, entry, index) => (
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {value}
                              </span>
                            )}
                          />
                          <BarNew
                            yAxisId="left"
                            dataKey={`${hotelName}` + " (Occ%)"}
                            barSize={40}
                            fill={
                              opacitySevenDays[`${hotelName}` + " (Occ%)"] === 1
                                ? "#AAAAAA"
                                : "rgba(220, 220, 220)"
                            }
                            opacity={
                              opacitySevenDays[`${hotelName}` + " (Occ%)"] === 1
                                ? 1
                                : 0
                            }
                            onMouseOver={handleColorChange}
                            onMouseOut={handleColor}
                          >
                            {priceForSevenDay.datasets.map((entry, index) => (
                              <Cell
                                cursor="pointer"
                                fill={
                                  index === activeIndex ? "#306FBC" : "#EBEBEB"
                                }
                                key={`cell-${index}`}
                              />
                            ))}
                          </BarNew>

                          {comp1.map((res, idx1) => (
                            <React.Fragment key={idx1}>
                              <LineNew
                                yAxisId="right"
                                type="monotone"
                                dataKey={res?.name}
                                stroke={
                                  opacitySevenDays[res?.name] === 1
                                    ? priceColorPalette[idx1]
                                    : "rgba(220, 220, 220)"
                                }
                                strokeWidth={2}
                                activeDot={{ r: 4 }}
                                dot={{ r: 0 }}
                                strokeOpacity={opacitySevenDays[res?.name]}
                              />
                            </React.Fragment>
                          ))}
                          <LineNew
                            yAxisId="right"
                            type="monotone"
                            dataKey={`${hotelName}` + " (Recommended Price)"}
                            stroke={
                              opacitySevenDays[
                                `${hotelName}` + " (Recommended Price)"
                              ] === 1
                                ? priceColorPalette[
                                    priceColorPalette.length - 1
                                  ]
                                : "rgba(220, 220, 220)"
                            }
                            activeDot={{ r: 4 }}
                            strokeWidth={2}
                            dot={{ r: 0 }}
                            strokeOpacity={
                              opacitySevenDays[
                                `${hotelName}` + " (Recommended Price)"
                              ]
                            }
                          />
                          <LineNew
                            yAxisId="right"
                            type="monotone"
                            dataKey={`${hotelName}` + " (Current Price)"}
                            stroke={
                              opacitySevenDays[
                                `${hotelName}` + " (Current Price)"
                              ] === 1
                                ? "#808080"
                                : "rgba(220, 220, 220)"
                            }
                            activeDot={{ r: 4 }}
                            strokeWidth={2}
                            dot={{ r: 0 }}
                            strokeOpacity={
                              opacitySevenDays[
                                `${hotelName}` + " (Current Price)"
                              ]
                            }
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    )}
                    {isFourteenDayData && (
                      <ResponsiveContainer width="100%" height="95%">
                        <ComposedChart
                          data={priceForFourteenDay.datasets}
                          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                        >
                          <CartesianGrid stroke="#FFFFFF" />
                          <XAxis
                            dataKey="date"
                            interval={0}
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                          />
                          <YAxis
                            yAxisId="left"
                            orientation="left"
                            stroke=" #777777"
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            type="number"
                            domain={[0, 100]}
                            tickFormatter={(tick) => {
                              return `${tick}%`;
                            }}
                            label={{
                              value: "Occupancy%",
                              angle: -90,
                              position: "insideLeft",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                              color: "#777777",
                            }}
                          />
                          <YAxis
                            yAxisId="right"
                            orientation="right"
                            stroke=" #777777"
                            style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            type="number"
                            ticks={[
                              0,
                              Math.trunc(fourteenDayMax / 4),
                              Math.trunc(fourteenDayMax / 2),
                              Math.trunc((3 * fourteenDayMax) / 4),
                              fourteenDayMax,
                              fourteenDayMax + Math.trunc(fourteenDayMax / 4),
                            ]}
                            domain={[0, fourteenDayMax]}
                          />
                          <TooltipNew
                            formatter={(label, name) =>
                              label === 0 && name !== `${hotelName}` + " (Occ%)"
                                ? "Sold Out"
                                : name.includes("(Occ%)")
                                ? label + "%"
                                : label === -2
                                ? "NA"
                                : label
                            }
                            wrapperStyle={{
                              fontSize: "12px",
                              fontFamily: "Roboto",
                            }}
                          />

                          <Legend
                            onClick={handleMouseEnter}
                            formatter={(value, entry, index) => (
                              <span
                                style={{
                                  fontSize: "14px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {value}
                              </span>
                            )}
                          />

                          <BarNew
                            yAxisId="left"
                            dataKey={`${hotelName}` + " (Occ%)"}
                            barSize={30}
                            fill={
                              opacity[`${hotelName}` + " (Occ%)"] === 1
                                ? "#AAAAAA"
                                : "rgba(220, 220, 220)"
                            }
                            opacity={
                              opacity[`${hotelName}` + " (Occ%)"] === 1 ? 1 : 0
                            }
                            onMouseOver={handleColorChange}
                            onMouseOut={handleColor}
                          >
                            {priceForFourteenDay.datasets.map(
                              (entry, index) => (
                                <Cell
                                  cursor="pointer"
                                  fill={
                                    index === activeIndex
                                      ? "#306FBC"
                                      : "#EBEBEB"
                                  }
                                  key={`cell-${index}`}
                                />
                              )
                            )}
                          </BarNew>
                          {comp1.map((res, idx1) => (
                            <React.Fragment key={idx1}>
                              <LineNew
                                yAxisId="right"
                                type="monotone"
                                dataKey={res?.name}
                                stroke={
                                  opacity[res?.name] === 1
                                    ? priceColorPalette[idx1]
                                    : "rgba(220, 220, 220)"
                                }
                                activeDot={{ r: 4 }}
                                strokeWidth={2}
                                dot={{ r: 0 }}
                                strokeOpacity={opacity[res?.name]}
                              />
                            </React.Fragment>
                          ))}
                          <LineNew
                            yAxisId="right"
                            type="monotone"
                            dataKey={`${hotelName}` + " (Recommended Price)"}
                            stroke={
                              opacity[
                                `${hotelName}` + " (Recommended Price)"
                              ] === 1
                                ? priceColorPalette[
                                    priceColorPalette.length - 1
                                  ]
                                : "rgba(220, 220, 220)"
                            }
                            activeDot={{ r: 4 }}
                            strokeWidth={2}
                            dot={{ r: 0 }}
                            strokeOpacity={
                              opacity[`${hotelName}` + " (Recommended Price)"]
                            }
                          />
                          <LineNew
                            yAxisId="right"
                            type="monotone"
                            dataKey={`${hotelName}` + " (Current Price)"}
                            stroke={
                              opacity[`${hotelName}` + " (Current Price)"] === 1
                                ? "#808080"
                                : "rgba(220, 220, 220)"
                            }
                            activeDot={{ r: 4 }}
                            strokeWidth={2}
                            dot={{ r: 0 }}
                            strokeOpacity={
                              opacity[`${hotelName}` + " (Current Price)"]
                            }
                          />
                        </ComposedChart>
                      </ResponsiveContainer>
                    )}
                    {/* {!openSelectbar && (
                  <Box
                    style={{
                      position: "absolute",
                      left: "30%",
                      display: "flex",
                      top: -3,
                      width: "100px",
                      height: "32px",
                    }}
                  >
                    <Select
                      value={day}
                      label=""
                      onChange={(e) => handleChange(e.target.value)}
                      className={classes.dropdownStyle}
                      disableUnderline
                      required={true}
                      IconComponent={KeyboardArrowDown}
                    >
                      <MenuItem
                        style={{
                          font: "normal normal normal 16px/20px Roboto",
                        }}
                        value={7}
                      >
                        {"7 Days"}
                      </MenuItem>
                      <MenuItem
                        style={{
                          font: "normal normal normal 16px/20px Roboto",
                        }}
                        value={14}
                      >
                        {"14 Days"}
                      </MenuItem>
                    </Select>
                  </Box>
                )} */}
                    {!openSelectbar && (
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          position: "absolute",
                          top: "30%",
                        }}
                      >
                        {isSevenDayData ? (
                          <>
                            <Button
                              disabled={idx <= 0}
                              style={
                                idx < 1
                                  ? {
                                      cursor: "not-allowed",
                                      disabled: "true",
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      marginLeft: "-20px",
                                      zIndex: "1",
                                      textTransform: "none",
                                    }
                                  : {
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      cursor: "pointer",
                                      zIndex: "1",
                                      marginLeft: "-20px",
                                    }
                              }
                            >
                              <ArrowBackIos
                                style={{
                                  color: "#585858",
                                  height: "30px",
                                  zIndex: "9999",
                                  marginLeft: "-6px",
                                }}
                                onClick={() => {
                                  handleClick("dec");
                                }}
                              />
                            </Button>
                            <Button
                              style={
                                idx + 7 === price.datasets?.length
                                  ? {
                                      cursor: "not-allowed",
                                      disabled: "true",
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      zIndex: "1",
                                      marginRight: "-22px",
                                    }
                                  : {
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      cursor: "pointer",
                                      marginRight: "-22px",
                                      zIndex: "1",
                                    }
                              }
                              disabled={
                                idx + 7 === price.datasets?.length
                                  ? true
                                  : false
                              }
                            >
                              <ArrowForwardIos
                                style={{
                                  color: "#585858",
                                  height: "28px",
                                  zIndex: "9999",
                                  marginRight: "-14px",
                                }}
                                onClick={() => {
                                  handleClick("inc");
                                }}
                              />
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              style={
                                idxNew < 1
                                  ? {
                                      cursor: "not-allowed",
                                      disabled: "true",
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",

                                      marginLeft: "-20px",
                                      zIndex: "1",
                                    }
                                  : {
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      cursor: "pointer",
                                      marginLeft: "-20px",
                                      zIndex: "1",
                                    }
                              }
                              disabled={idxNew <= 0}
                            >
                              <ArrowBackIos
                                style={{
                                  color: "#585858",
                                  height: "30px",
                                  zIndex: "9999",
                                  marginLeft: "-6px",
                                }}
                                onClick={() => {
                                  handleClickNew("dec");
                                }}
                              />
                            </Button>
                            <Button
                              style={
                                idx + 14 === price.datasets?.length
                                  ? {
                                      cursor: "not-allowed",
                                      disabled: "true",
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",

                                      marginRight: "-22px",
                                    }
                                  : {
                                      padding: " 9px",
                                      backgroundColor: "transparent",
                                      outline: "none",
                                      border: "none",
                                      cursor: "pointer",
                                      marginRight: "-22px",
                                    }
                              }
                              disabled={
                                idxNew + 14 === price.datasets?.length
                                  ? true
                                  : false
                              }
                            >
                              <ArrowForwardIos
                                style={{
                                  color: "#585858",
                                  height: "28px",
                                  zIndex: "9999",
                                  marginRight: "-14px",
                                }}
                                onClick={() => {
                                  handleClickNew("inc");
                                }}
                              />
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                    {/* <Doughnut data={priceForFifteenDay} options={priceOptionsForFifteenDay}></Doughnut> */}
                  </Box>
                </HybridGraphCard>
                <SimpleGraphCard
                  style={{
                    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
                    width: "33%",
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                      height: "99%",
                      position: "relative",
                    }}
                  >
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                      <LabelNew>Pick-Up by Month</LabelNew>
                    </Box>
                    <Bar
                      data={pickup}
                      options={pickupOptions}
                      plugins={[chartDataLabels]}
                    />
                  </Box>
                </SimpleGraphCard>
              </MiddleRow>
              <BottomRow>
                <WeeklyCard style={{ height: "440px" }}>
                  <WeeklyHeader style={{ justifyContent: "space-between" }}>
                    <CardNameNew style={{ textAlign: "left" }}>
                      Performance by Day of Week
                    </CardNameNew>
                    <RightHeader style={{ width: "38%" }}>
                      <MonthPickerContainer
                        style={{
                          marginRight: "18px",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <CalenderIcon style={{ marginLeft: "4px" }} />
                        <MonthPicker
                          value={selectedDate}
                          onChange={setSelectedDate}
                          disableFutureMonths={true}
                        />
                      </MonthPickerContainer>
                      <MonthPickerContainer>
                        <FormControlLabel
                          classes={{ label: "label" }}
                          control={
                            <Checkbox
                              onChange={() => {
                                setIsComparitive(!isComparitive);
                              }}
                              value={isComparitive}
                              style={{
                                color: "#110641",
                              }}
                            />
                          }
                          label="Compare with"
                          style={{ color: "#110641" }}
                        />
                      </MonthPickerContainer>
                      <MonthPickerContainer
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <CalenderIcon style={{ marginLeft: "7px" }} />
                        <MonthPicker
                          value={comparitiveDate}
                          onChange={setComparitiveDate}
                          disabled={!isComparitive}
                          disableFutureMonths={true}
                        />
                      </MonthPickerContainer>
                    </RightHeader>
                  </WeeklyHeader>
                  <WeeklyBody>
                    <OccupancyGraphNew>
                      <TitleNew>Occupancy</TitleNew>
                      {isComparitive ? (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart data={occDataCurrent}>
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              tickFormatter={(tick) => {
                                return `${tick}%`;
                              }}
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltip />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                            <BarNew
                              dataKey={comparitiveDateNew}
                              fill="rgba(48, 111, 188, 0.4)"
                              animationBegin={100}
                              animationDuration={400}
                              animationEasing="linear"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart
                            data={occDataNew}
                            margin={{
                              top: 20,
                            }}
                          >
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                              tickFormatter={(tick) => {
                                return `${tick}%`;
                              }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltip />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </OccupancyGraphNew>
                    <ARRGraphNew>
                      <TitleNew style={{ marginBottom: "28px" }}>
                        Average Room Rate
                      </TitleNew>
                      {isComparitive ? (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart data={arrDataCurrent}>
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltipARR />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                            <BarNew
                              dataKey={comparitiveDateNew}
                              fill="rgba(48, 111, 188, 0.4)"
                              animationBegin={100}
                              animationDuration={400}
                              animationEasing="linear"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart data={arrDataNew}>
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltipARR />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </ARRGraphNew>
                    <RevPARGraphNew>
                      <TitleNew style={{ marginBottom: "28px" }}>
                        RevPAR
                      </TitleNew>
                      {isComparitive ? (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart data={revParDataCurrent}>
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltipRevPAR />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                            <BarNew
                              dataKey={comparitiveDateNew}
                              fill="rgba(48, 111, 188, 0.4)"
                              animationBegin={100}
                              animationDuration={400}
                              animationEasing="linear"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height="80%">
                          <BarChart data={revParDataNew}>
                            <CartesianGrid stroke="#FFFFFF" />
                            <XAxis
                              dataKey="Day"
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <YAxis
                              style={{ fontFamily: "Roboto", fontSize: "13px" }}
                            />
                            <TooltipNew
                              cursor={{ fill: "rgb(0,0,0,0.09)" }}
                              wrapperStyle={{
                                fontSize: "13px",
                                fontFamily: "Roboto",

                                padding: "8px",
                                background: "#fff",
                                width: "120px",
                              }}
                              content={<CustomTooltipRevPAR />}
                            />
                            <Legend
                              formatter={(value, entry, index) => (
                                <span
                                  style={{
                                    fontSize: "12px",
                                    fontFamily: "Roboto",
                                    color: "#777777",
                                  }}
                                >
                                  {value}
                                </span>
                              )}
                            />

                            <BarNew dataKey={selectedDateNew} fill="#306FBC" />
                          </BarChart>
                        </ResponsiveContainer>
                      )}
                    </RevPARGraphNew>{" "}
                  </WeeklyBody>
                </WeeklyCard>
              </BottomRow>
              <Modal
                open={openSelectbar}
                onClose={() => setOpenSelectBar(false)}
              >
                <Box>
                  <ModelBody
                    setOpenSelectBar={setOpenSelectBar}
                    data={price.datasets}
                    options={priceOptions}
                    datasevenDays={priceForSevenDay}
                    pricesevenDays={priceOptionsForSevenDay}
                    datafourteenDays={priceForFourteenDay}
                    pricefourteenDays={priceOptionsForFourteenDay}
                    dataTwentyOneDays={priceForTwentyOneDay}
                    priceTwentyOneDays={priceOptionsForTwentyOneDay}
                    check={check}
                    setCheck={setCheck}
                    comp1={comp1}
                    priceColorPalette={priceColorPalette}
                    hotelName={hotelName}
                    opacityThirtyDays={opacityThirtyDays}
                    setOpacityThirtyDays={setOpacityThirtyDays}
                    dataKeysThirtyDays={dataKeysThirtyDays}
                    setDataKeysThirtyDays={setDataKeysThirtyDays}
                  />
                </Box>
              </Modal>
            </Body>
          ) : Loading ? (
            <LoadingPage />
          ) : (
            <Nodata />
          )}
        </ClassWrapper>
      </Page>
    ) : !dashboard ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
}
