import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { styled } from "@mui/system";
import { useWarning } from "../../Provider/context";
import { useAuth } from "../../sdk";

const Test = styled(Box)`
  &:-webkit-any-link {
    text-decoration: none;
  }
  font: normal normal bold 14px/18px Roboto;
  letter-spacing: 0px;

  &:hover {
    cursor: pointer;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  color: #306fbc;
`;
const TabNav = (props) => {
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const { children, to, activeStyle } = props;

  return (
    <Test
      onClick={() => {
        handleRedirect(to);
      }}
      style={{
        ...activeStyle,
      }}
    >
      {children}
    </Test>
  );
};
const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  font: normal normal bold 16px/20px Roboto;
  letter-spacing: 0px;
  color: #281e53;
  margin: auto;
  margin-bottom: 20px;
  gap: 20px;
`;
const HeaderCard = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  height: 40px;
  flex: 1;
  border-radius: 8px;
`;

export const PrimarySetup = ({ rateShopAppEnabled }) => {
  const { hotelId } = useParams();
  const { permission, token, version, currentHotel } = useAuth();
  const { formEdit, setformEdit, handleRedirect } = useWarning();
  const history = useHistory();
  let seasonSetup = null,
    hotelDetailsView = null,
    multiplier = null,
    DLF = null,
    compDetailsView = null,
    hotelDetailsEdit = null;
  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "seasonSetup") {
        seasonSetup = permission[key];
      }
      if (permission[key].name === "hotelDetailsView") {
        hotelDetailsView = permission[key];
      }
      if (permission[key].name === "compDetailsView") {
        compDetailsView = permission[key];
      }
      if (permission[key].name === "DLF") {
        DLF = permission[key];
      }
      if (permission[key].name === "multiplier") {
        multiplier = permission[key];
      }
      if (permission[key].name === "hotelDetailsEdit") {
        hotelDetailsEdit = permission[key];
      }
    }
  }

  return (
    <Header>
      {hotelDetailsView && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup`}
              activeStyle={
                history.location.pathname === `/hotel/${hotelId}/setup`
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              Hotel Details
            </TabNav>
          ) : (
            <TabNav exact to={`/hotel`}>
              Hotel Details
            </TabNav>
          )}
        </HeaderCard>
      )}
      {hotelDetailsEdit && currentHotel?.rateShopEnabled && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/scheduling`}
              activeStyle={
                history.location.pathname ===
                `/hotel/${hotelId}/setup/scheduling`
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              RateShop Scheduling
            </TabNav>
          ) : (
            <TabNav exact to={`/hotel`}>
              RateShop Scheduling
            </TabNav>
          )}
        </HeaderCard>
      )}
      {hotelDetailsEdit && currentHotel?.rateShopEnabled && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/room-mapping`}
              activeStyle={
                history.location.pathname?.includes(
                  `/hotel/${hotelId}/setup/room-mapping`
                )
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              RateShop Room Mapping
            </TabNav>
          ) : (
            <TabNav exact to={`/hotel`}>
              RateShop Room Mapping
            </TabNav>
          )}
        </HeaderCard>
      )}
      {hotelDetailsEdit && currentHotel?.isChannelManagerEnabled && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/channel-manager`}
              activeStyle={
                history.location.pathname ===
                `/hotel/${hotelId}/setup/channel-manager`
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              Channel Manager
            </TabNav>
          ) : (
            <TabNav exact to={`/hotel`}>
              Channel Manager
            </TabNav>
          )}
        </HeaderCard>
      )}
      {hotelDetailsEdit && ( //compDetailsView
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/competitors`}
              onClick={() => {
                handleRedirect(`/hotel/${hotelId}/setup/competitors`);
              }}
              activeStyle={
                history.location.pathname.includes("/setup/competitors")
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              {" "}
              Competitor Setup
            </TabNav>
          ) : (
            <TabNav exact to={`#`}>
              Competitor Setup
            </TabNav>
          )}
        </HeaderCard>
      )}
      {hotelDetailsEdit && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/room-adjustment`}
              onClick={() => {
                handleRedirect(`/hotel/${hotelId}/setup/room-adjustment`);
              }}
              activeStyle={
                history.location.pathname.includes("/setup/room-adjustment")
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              {" "}
              Room Adjustment
            </TabNav>
          ) : (
            <TabNav exact to={`#`}>
              Room Adjustment
            </TabNav>
          )}{" "}
        </HeaderCard>
      )}

      {version === "v1" ? (
        ""
      ) : version === "v2" && (hotelDetailsView || hotelDetailsEdit) ? (
        <>
          <HeaderCard>
            {hotelId ? (
              <TabNav
                exact
                to={`/hotel/${hotelId}/setup/market-segment`}
                onClick={() => {
                  handleRedirect(`/hotel/${hotelId}/setup/market-segment`);
                }}
                activeStyle={
                  history.location.pathname.includes("/setup/market-segment")
                    ? {
                        color: "#ffffff",
                        background: "#306FBC",
                        boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                        borderRadius: "8px",
                      }
                    : {}
                }
              >
                {" "}
                Market Segment
              </TabNav>
            ) : (
              <TabNav exact to={`#`}>
                Market Segment
              </TabNav>
            )}{" "}
          </HeaderCard>
          <HeaderCard>
            {hotelId ? (
              <TabNav
                exact
                to={`/hotel/${hotelId}/setup/budget-setup`}
                onClick={() => {
                  handleRedirect(`/hotel/${hotelId}/setup/budget-setup`);
                }}
                activeStyle={
                  history.location.pathname.includes("/setup/budget-setup")
                    ? {
                        color: "#ffffff",
                        background: "#306FBC",
                        boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                        borderRadius: "8px",
                      }
                    : {}
                }
              >
                {" "}
                Budget Setup
              </TabNav>
            ) : (
              <TabNav exact to={`#`}>
                Budget Setup
              </TabNav>
            )}{" "}
          </HeaderCard>
          <HeaderCard>
            {hotelId ? (
              <TabNav
                exact
                to={`/hotel/${hotelId}/setup/budget-allocation`}
                onClick={() => {
                  handleRedirect(`/hotel/${hotelId}/setup/budget-allocation`);
                }}
                activeStyle={
                  history.location.pathname.includes("/setup/budget-allocation")
                    ? {
                        color: "#ffffff",
                        background: "#306FBC",
                        boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                        borderRadius: "8px",
                      }
                    : {}
                }
              >
                {" "}
                Budget Allocation
              </TabNav>
            ) : (
              <TabNav exact to={`#`}>
                Budget Allocation
              </TabNav>
            )}{" "}
          </HeaderCard>
        </>
      ) : (
        ""
      )}

      {hotelDetailsEdit && ( //seasonSetup
        <HeaderCard>
          {hotelId ? (
            <TabNav
              exact
              to={`/hotel/${hotelId}/setup/hotel-pricing`}
              onClick={() => {
                handleRedirect(`/hotel/${hotelId}/setup/hotel-pricing`);
              }}
              activeStyle={
                history.location.pathname.includes("/setup/hotel-pricing")
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              {" "}
              Season Setup
            </TabNav>
          ) : (
            <TabNav exact to={`#`}>
              Season Setup
            </TabNav>
          )}{" "}
        </HeaderCard>
      )}
      {hotelDetailsEdit && DLF && multiplier && (
        <HeaderCard>
          {hotelId ? (
            <TabNav
              to={`/hotel/${hotelId}/setup/algorithm`}
              onClick={() => {
                handleRedirect(`/hotel/${hotelId}/setup/algorithm`);
              }}
              activeStyle={
                history.location.pathname.includes("/setup/algorithm")
                  ? {
                      color: "#ffffff",
                      background: "#306FBC",
                      boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
                      borderRadius: "8px",
                    }
                  : {}
              }
            >
              {" "}
              Algorithm Setup
            </TabNav>
          ) : (
            <TabNav exact to={`#`}>
              Algorithm Setup
            </TabNav>
          )}
        </HeaderCard>
      )}
    </Header>
  );
};
