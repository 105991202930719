import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Paper,
  Stack,
  IconButton,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  styled,
  tableCellClasses,
  CircularProgress,
} from "@mui/material";
import { addDays, format, set } from "date-fns";
import { useParams } from "react-router-dom";
import { LoadingPage, useAuth } from "../../sdk";
import { SaveAlt } from "@mui/icons-material";

const status = {
  SUBMIT: "In Progress",
  CREATED: "Completed",
  FAILED: "Failed",
};

const filterOptions = {
  requestType: [
    {
      label: "Scheduled",
      value: "scheduled",
    },
    {
      label: "On Demand",
      value: "on_demand",
    },
  ],
  duration: [
    {
      label: "Less than 15 minutes",
      value: "15",
    },
    {
      label: "Less than 30 minutes",
      value: "30",
    },
    {
      label: "Less than 45 minutes",
      value: "45",
    },
  ],
};

export default function RateshopLogs() {
  const { hotelId } = useParams();
  const { authFetch } = useAuth();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [sourcesData, setSourcesData] = useState([]);
  const [filter, setFilter] = useState({});
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [networkMsg, setnetworkMsg] = useState(null);
  const [isDownloadLoading, setDownloadLoading] = useState(null);

  const getSources = useCallback(async () => {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/sources`,
    });
    const { data, error } = await get();
    if (data) {
      setSourcesData(data);
    } else {
      console.log(error);
    }
    setIsLoading(false);
  }, [authFetch]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const getRateShopLogs = useCallback(async () => {
    setIsLoading(true);
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/rate-shop-hotel-logs?page=${page}&limit=${rowsPerPage}&searchTime=${
        filter.duration?.value ?? ""
      }&rateShopType=${filter.requestType?.value ?? ""}`,
    });
    const { data, error } = await get();
    if (data) {
      setData(data?.rateShopLogDetails ?? []);
      setCount(data?.totalCount);
    } else {
      console.log(error);
    }
    setIsLoading(false);
  }, [
    authFetch,
    filter.duration,
    filter.requestType,
    hotelId,
    page,
    rowsPerPage,
  ]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  console.log(filter);
  useEffect(() => {
    getRateShopLogs();
  }, [getRateShopLogs]);

  const searchTime = useCallback((createdAt, successAt) => {
    const startTime = format(new Date(createdAt), "dd-MM-yyyy(HH:mm)");
    const successTime = successAt
      ? format(new Date(successAt), "dd-MM-yyyy (HH:mm)")
      : "In progress";
    return `${startTime}-${successTime}`;
  }, []);

  const sourceName = useCallback(
    (sources) => {
      const names = sources.map((sourceId) => {
        const source = sourcesData?.find((item) => item?.sourceId === sourceId);
        return source?.name;
      });
      return names.map((item) => <Typography>{item}</Typography>);
    },
    [sourcesData]
  );

  const losAndGuests = useCallback((requestString, cell) => {
    const requestObject = JSON.parse(requestString);
    if (cell === "los") {
      return requestObject?.los;
    } else {
      return requestObject?.occupancy;
    }
  }, []);
  const hanldeDownloadExcelFile = useCallback(
    async (queueId, index) => {
      setDownloadLoading(index);
      const { get } = await authFetch({
        path: `/queueIdToExcel-parse?queueid=${queueId}`,
      });

      const response = await get();
      const blob = await response.response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${queueId}.xlsx`);
      document.body.appendChild(link);
      await link.click();
      setDownloadLoading(null);

      setnetworkMsg("File Downloaded Successfully");
    },
    [authFetch]
  );

  const compititors = useCallback((requestString) => {
    const requestObject = JSON.parse(requestString);
    return requestObject?.hotelcodes?.length > 1 ? "Yes" : "No";
  }, []);
  const getRatelogDuration = (RateShopstatus, ratelogDuration) => {
    switch (RateShopstatus) {
      case "SUBMIT":
        return "In Progress";
      case "FAILED":
        return "0 minutes";
      default:
        return `${Math.round(ratelogDuration) || 0} minutes`;
    }
  };

  const rangeDate = useCallback((createdAt, requestString) => {
    const horizonexpression = JSON.parse(requestString).horizonexpression;
    const [startRange, endRange] = horizonexpression.split("-");
    const initialDate = addDays(new Date(createdAt), startRange ?? 0);
    const finalDate = addDays(new Date(createdAt), endRange ?? startRange ?? 0);
    const startDate = format(initialDate, "dd-MM-yyyy");
    const endDate = format(finalDate, "dd-MM-yyyy");
    return `${startDate}-${endDate}`;
  }, []);

  return isLoading ? (
    <LoadingPage />
  ) : (
    <>
      <Stack
        direction={"row"}
        gap={2}
        paddingBottom={2}
        justifyContent={"flex-end"}
      >
        <Autocomplete
          value={filter.requestType}
          style={{ width: 200, padding: "0 8px 0 8px" }}
          options={filterOptions.requestType}
          size="small"
          getOptionLabel={(option) => option.label}
          classes={{
            paper: "dropdownStyle",
          }}
          onChange={(event, newValue) => {
            setFilter((prev) => ({ ...prev, requestType: newValue }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ borderRadius: 500 }}
              label="Request Type"
            />
          )}
        />
        <Autocomplete
          value={filter.duration}
          style={{ width: 230, padding: "0 8px 0 8px" }}
          options={filterOptions.duration}
          size="small"
          getOptionLabel={(option) => option.label}
          classes={{
            paper: "dropdownStyle",
          }}
          onChange={(event, newValue) => {
            setFilter((prev) => ({ ...prev, duration: newValue }));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ borderRadius: 500 }}
              label="Duration"
            />
          )}
        />
      </Stack>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "65vh",
          overflowY: "auto",
        }}
      >
        <Table>
          <TableHead>
            <StyledTableRow
              sx={{
                position: "sticky",
                top: 0,
                backgroundColor: "white",
                zIndex: 1,
              }}
            >
              <StyledTableCell>Queue Id</StyledTableCell>
              <StyledTableCell>Search Time</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell>OTA</StyledTableCell>
              <StyledTableCell>Platform</StyledTableCell>
              <StyledTableCell>LOS</StyledTableCell>
              <StyledTableCell>Guest</StyledTableCell>
              <StyledTableCell>Requested By</StyledTableCell>
              <StyledTableCell
                sx={{
                  minWidth: "200px",
                }}
              >
                Date Range
              </StyledTableCell>
              <StyledTableCell>Competitors</StyledTableCell>
              <StyledTableCell>RateShop Type</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {data.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell>{item?.queue_id}</StyledTableCell>
                <StyledTableCell>
                  {searchTime(
                    item?.createdAt,
                    item?.status === "FAILED"
                      ? item?.createdAt
                      : item?.successAt
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {getRatelogDuration(item?.status, item?.rateShopDurationTime)}
                </StyledTableCell>
                <StyledTableCell>{sourceName(item?.Sources)}</StyledTableCell>
                <StyledTableCell>
                  {item.isMobile ? "Mobile" : "Desktop"}
                </StyledTableCell>
                <StyledTableCell>
                  {losAndGuests(item?.requestString, "los")}
                </StyledTableCell>
                <StyledTableCell>
                  {losAndGuests(item?.requestString, "guests")}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.createdBy && item?.email
                    ? `${item?.createdBy}\n(${item?.email})`
                    : "--"}
                </StyledTableCell>

                <StyledTableCell>
                  {rangeDate(item?.createdAt, item?.requestString)}
                </StyledTableCell>
                <StyledTableCell>
                  {compititors(item?.requestString)}
                </StyledTableCell>
                <StyledTableCell>
                  {item?.isScheduled ? "Scheduled" : "On demand"}
                </StyledTableCell>
                <StyledTableCell>
                  {status?.[item?.status] ?? ""}
                </StyledTableCell>
                <StyledTableCell>
                  {"CREATED" === item?.status && (
                    <IconButton
                      disabled={isDownloadLoading === index}
                      onClick={() =>
                        hanldeDownloadExcelFile(item.queue_id, index)
                      }
                    >
                      {isDownloadLoading===index ? <CircularProgress color="inherit" /> : <SaveAlt />}
                    </IconButton>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to }) => null}
        nextIconButtonProps={{
          disabled: data.length < rowsPerPage,
        }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={networkMsg}
        autoHideDuration={3000}
        onClose={() => setnetworkMsg(null)}
      >
        {networkMsg && <SnackbarContent message={networkMsg} />}
      </Snackbar>
    </>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: "#130453",
    textAlign: "center",
    font: "normal normal bold 16px/20px Roboto",
    padding: "16px",
    letterSpacing: "0px",
    width: "11%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    width: "11%",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
