import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useCallback } from "react";
import useChannelManager from "../hooks/useChannelManager";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { ExculdeRooms } from "./ExcludeRooms";
import { SearchPropertyIdDialog } from "./SearchPropertyIdDialog";

const roomsthresoldList = [
  { key: "maxThresholdWeekday", label: "Max Threshold Weekday" },
  { key: "minThresholdWeekday", label: "Min Threshold Weekday" },
  { key: "maxThresholdWeekend", label: "Max Threshold Weekend" },
  { key: "minThresholdWeekend", label: "Min Threshold Weekend" },
];
export default function ChannelManager() {
  const {
    hotelId,
    handleRedirect,
    propertyId,
    channelManagerData,
    currentHotel,
    roomData,
    snackbarMsg,
    setSnackBarMsg,
    isLoading,
    handleClickConnectionButton,
    handlePushDataClick,
    arrData,
    openPropertyIdModal,
    setOpenPropertyIdModal,
    handleSearchPropertyId,
    openWarningModal,
    setOpenWarningModal,
    actionOnConnectionButtonClick,
    roomsData,
    setRoomsData,
    changeHandler,
    handleSaveRooms,
    loaderRoomIndex,
    excludeRoomToggle,
    setExcludeRoomToggle,
    authFetch,
  } = useChannelManager();

  const isSaveForRoomDisabled = useCallback(
    (index) => {
      return !(
        roomsData?.[index]?.roomId &&
        roomsData?.[index]?.roomRateGroupCode &&
        roomsData?.[index]?.isEdit
      );
    },
    [roomsData]
  );
  if (excludeRoomToggle) {
    return (
      <ExculdeRooms
        excludeRoomToggle={excludeRoomToggle}
        setExcludeRoomToggle={setExcludeRoomToggle}
        authFetch={authFetch}
        hotelId={hotelId}
        propertyId={propertyId}
        roomData={roomData}
        handleSearchPropertyId={handleSearchPropertyId}
      />
    );
  }
  return (
    <ChannelManagerPageStack>
      <Stack className="toggleContainer">
        <Typography className="pageHeading">Channel Manager</Typography>
        <Button variant="contained" onClick={() => setExcludeRoomToggle(true)}>
          Exclude Rooms
        </Button>
      </Stack>
      <Stack className="borderStack" gap="16px">
        <Stack className="propertyStack">
          <TextField
            sx={{ width: "33%" }}
            label="Property Id"
            type="number"
            className="remove-arrows"
            value={propertyId}
            disabled={!!propertyId}
          />
          <Button
            variant="contained"
            sx={{ height: "40px" }}
            onClick={() => {
              handleSearchPropertyId();
            }}
            disabled={!propertyId}
          >
            Search
          </Button>
        </Stack>
      </Stack>

      {roomsData?.map((room, index) => (
        <Stack className="borderStack" gap="16px">
          <Typography className="pageHeading">
            Room Mapping For Channel Manager
          </Typography>
          <Stack gap="16px" flexDirection={"row"}>
            <Stack
              display={"flex"}
              flexGrow={"1"}
              flexDirection={"column"}
              gap={"16px"}
            >
              <Stack direction="row" gap="16px">
                <TextField
                  label="Room Id"
                  type="number"
                  className="remove-arrows"
                  value={room?.roomId}
                  onChange={(e) => {
                    changeHandler(index, "roomId", e.target.value);
                  }}
                />
                <TextField
                  label="Room Name"
                  type="text"
                  value={room?.roomName}
                  onChange={(e) => {
                    changeHandler(index, "roomName", e.target.value);
                  }}
                />
                <TextField
                  label="Rate Id"
                  type="number"
                  className="remove-arrows"
                  value={room?.roomRateGroupCode}
                  onChange={(e) => {
                    changeHandler(index, "roomRateGroupCode", e.target.value);
                  }}
                />
                <TextField
                  label="Promotional Discount"
                  type="number"
                  className="remove-arrows"
                  value={room?.discount}
                  onChange={(e) => {
                    changeHandler(index, "discount", e.target.value);
                  }}
                />
                {!room?.isBaseRoom && (
                  <TextField
                    label="Add to base price (+/-)"
                    type="number"
                    className="remove-arrows"
                    value={room?.addToBasePrice}
                    onChange={(e) => {
                      changeHandler(index, "addToBasePrice", e.target.value);
                    }}
                  />
                )}
              </Stack>
              <Stack direction="row" gap="16px">
                {roomsthresoldList?.map((thresold) => (
                  <TextField
                    key={`thresold-column-${thresold.key}`}
                    label={thresold.label}
                    type="number"
                    className="remove-arrows"
                    value={room?.[thresold.key]}
                    onChange={(e) => {
                      changeHandler(index, thresold.key, e.target.value);
                    }}
                  />
                ))}
              </Stack>
            </Stack>
            <Stack justifyContent="space-between">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    onChange={() => {
                      changeHandler(index, "isBaseRoom", !room?.isBaseRoom);
                    }}
                    checked={room?.isBaseRoom}
                  />
                }
                label="Is Base Room"
              />
              <LoadingButton
                variant="contained"
                onClick={() => handleSaveRooms(index)}
                disabled={isSaveForRoomDisabled(index)}
                loading={isLoading && loaderRoomIndex === index}
              >
                Save
              </LoadingButton>
            </Stack>
          </Stack>
        </Stack>
      ))}
      <Stack
        className=""
        style={{
          marginLeft: "auto",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            // Add New Room to Array
            setRoomsData((prev) =>
              prev.concat({
                isNewRoom: true,
              })
            );
          }}
        >
          Add Room
        </Button>
      </Stack>
      <Stack className="buttonStack">
        <Stack direction="row" gap="16px" justifyContent="end">
          <Button
            variant="contained"
            onClick={() =>
              handleRedirect(`/hotel/${hotelId}/setup/competitors`)
            }
          >
            Next
          </Button>
        </Stack>
        <Stack direction="row" gap="16px" justifyContent="space-between">
          <Button
            variant="contained"
            // disabled={currentHotel?.enablePushDataAPI}
            onClick={handlePushDataClick}
          >
            {currentHotel?.enablePushData
              ? "Disable Push Data"
              : "Enable Push Data"}
          </Button>
          <Button
            variant="contained"
            onClick={actionOnConnectionButtonClick}
            // disabled={currentHotel?.enablePropertyConnection}
          >
            {currentHotel?.enablePropertyConnection
              ? "Disable Connection"
              : "Enable Connection"}
          </Button>
        </Stack>
      </Stack>
      {arrData && (
        <Stack>
          <pre>{JSON.stringify(arrData, null, 2)}</pre>
        </Stack>
      )}
      {openPropertyIdModal ? (
        <SearchPropertyIdDialog
          isLoading={isLoading}
          openPropertyIdModal={openPropertyIdModal}
          roomData={roomData}
          setOpenPropertyIdModal={setOpenPropertyIdModal}
        />
      ) : null}

      <StyledDialog
        open={openWarningModal}
        onClose={() => setOpenWarningModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle className="content">
          <Stack direction="row" alignItems="center" gap="8px">
            <Typography fontWeight="600" fontSize="24px">
              Warning
            </Typography>
            <WarningIcon sx={{ color: "#a90f0f" }} />
          </Stack>
        </DialogTitle>

        <CloseIcon
          className="closeButton"
          onClick={() => setOpenWarningModal(false)}
        />
        <DialogContent className="content">
          <Typography textAlign="center">
            Are you sure you want to disable the connection , as this might
            create problem in fetching and pushing the ARR to the channel
            Manager
          </Typography>
          <Stack direction="row" gap="40px" padding="24px">
            <Button
              variant="contained"
              onClick={() => setOpenWarningModal(false)}
            >
              No
            </Button>
            <Button variant="contained" onClick={handleClickConnectionButton}>
              Yes
            </Button>
          </Stack>
        </DialogContent>
      </StyledDialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackbarMsg.open}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBarMsg({
            ...snackbarMsg,
            open: false,
          });
        }}
      >
        <SnackbarContent
          style={{ backgroundColor: snackbarMsg.color }}
          message={snackbarMsg.msg}
        />
      </Snackbar>
    </ChannelManagerPageStack>
  );
}

const ChannelManagerPageStack = styled(Stack)(() => ({
  width: " 100%",
  background: "#ffffff",
  border: "none",
  padding: "20px",
  outline: "none",
  height: "calc(100vh - 160px)",
  overflowY: "scroll",
  overflowX: "hidden",
  boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  gap: "24px",

  ".borderStack": {
    gap: "16px",
    padding: "24px",
    boxShadow: "1px 4px 10px rgba(48, 111, 188, 0.2)",
  },
  ".pageHeading": {
    fontSize: "16px",
    fontWeight: "600",
    paddingLeft: "16px",
  },
  ".toggleContainer": {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ".propertyStack": {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    alignItems: "center",
  },
  ".buttonStack": {
    display: "flex",
    // flexDirection: "row",
    gap: "16px",
    justifyContent: "end",
  },
  ".remove-arrows": {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& input[type=number]": {
      MozAppearance: "textfield",
    },
    ".MuiInputAdornment-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
}));

const StyledDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
