import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LoadingPage, useAuth } from "../../sdk";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import {
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import {
  ButtonContainer,
  HotelSection,
  Link,
  Main,
  OrganizationSection,
  Page,
} from "./Styles";
import { TextField as TF } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { Stack, TableHead, Breadcrumbs } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { RateComparsion } from "../../ChooseHotel";
import moment from "moment";
import DatePicker from "react-modern-calendar-datepicker";
import { CalenderIcon } from "../../AdminEvents";
import { useDebounce } from "use-debounce";
import { useDebounceValue } from "../../sdk/hooks/useDebounceValue";

export const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  position: "relative",
  boxShadow: "0px 0px 4px  rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box style={{ display: "flex" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPage /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPage />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".dropdownStyle": {
    borderRadius: 4,
    font: "normal normal normal 16px/20px Roboto",
  },
  ".table": {
    minWidth: 650,
  },
  ".header": {
    position: "sticky",
    top: 0,
  },
  ".rowHover": {
    "&:hover": {
      backgroundColor: "#E6E6FA !important",
      cursor: "pointer",
    },
  },
}));

export const Status = styled(Button)`
  text-transform: none;
  background: #c6f3d2;
  border-radius: 5px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #42ae40;
  padding: 4px 20px 4px 20px;
  width: 78px;
`;
export const LN = styled(Box)`
  height: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #e13a3a;
`;
export const BOB = styled(Box)`
  height: 20px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #42ae40;
`;
const TableCellHeading = styled(TableCell)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
`;

const TCell = styled(TableCell)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
  text-align: -webkit-right;
`;
const Search = styled(Box)`
  justify-content: flex-end;
  width: 300px;
  height: 40px;
  background: #ffffff;
  box-shadow: 1px 2px 4px 1px rgba(48, 111, 188, 0.2);
  border-radius: 8px;
  margin-right: 22px;
`;
const SearchBox = styled(TF)`
  height: 20px;
  width: 253px;
  padding-left: 16px;
  padding-top: 8px;
  position: relative;
  input {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
  }
`;

export const TopSection = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
export const HotelChain = styled(Box)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margintop: 12px;
  color: #000000;
  width: 100%;
  padding-left: 22px;
`;
export const OrganizationContainer = styled(Box)`
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  cursor: pointer;
`;
const AddHotel = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.46px;
  text-transform: capitalize;
  color: #ffffff;
`;
export function ChooseHotels({
  selectedOrganizationName,
  setSelectedOrganizationName,
  selectedChainName,
  setSelectedChainName,
}) {
  const {
    hotelSelect,
    hotels,
    token,
    authFetch,
    permission,
    selectHotel,
    updateHotels,
  } = useAuth();

  const [Loading, setLoading] = useState();
  const { ORGID, CHAINID } = useParams();
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const [setSelectedHotelId] = useState();
  const [hotelList, setHotelList] = useState([]);
  const [hotelListNew, setHotelListNew] = useState([]);
  const [page, setPage] = React.useState(0);
  const [hotelCount, setHotelCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [active, setActive] = useState(true);
  const [searched, setSearched] = useState("");
  const debouncesearch = useDebounceValue(searched, 300);

  const [filter, setFilter] = useState([]);
  // const today = new Date();
  // const oneMonthLater = new Date(today);
  // oneMonthLater.setMonth(today.getMonth() + 1);
  // const fromDateDefaultValue = {
  //   year: today.getFullYear(),
  //   month: today.getMonth() + 1,
  //   day: today.getDate(),
  // };

  // const toDateDefaultValue = {
  //   year: oneMonthLater.getFullYear(),
  //   month: oneMonthLater.getMonth() + 1,
  //   day: oneMonthLater.getDate(),
  // };
  const [dateNew, setDateNew] = useState({
    from: "",
    to: "",
  });

  const handleFilterChange = useCallback(
    (event) => {
      const {
        target: { value },
      } = event;

      const selectedValues =
        typeof value === "string" ? value.split(",") : value;

      if (selectedValues.length === 0) {
        setHotelListNew(hotelList);
        setFilter(selectedValues);
        setPage(0);
        return;
      }

      const filteredList = hotelList.filter((item) => {
        let includePrecium = false;
        let includeRateShop = false;

        selectedValues.forEach((selectedValue) => {
          switch (selectedValue) {
            case "PreciumEnabled":
              if (item.isPreciumEnabled) {
                includePrecium = true;
              }
              break;
            case "RateShopEnabled":
              if (item.rateShopEnabled) {
                includeRateShop = true;
              }
              break;
            default:
              break;
          }
        });

        if (
          selectedValues.includes("PreciumEnabled") &&
          selectedValues.includes("RateShopEnabled")
        ) {
          return includePrecium && includeRateShop;
        }

        return includePrecium || includeRateShop;
      });

      setHotelListNew(filteredList);
      setFilter(selectedValues);
      setPage(0);
    },
    [hotelList]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [selectedChain] = useState(() => {
    if (CHAINID) {
      return CHAINID;
    } else {
      return 0;
    }
  });
  const [selectedManagingOrganization] = useState(() => {
    if (ORGID) {
      return ORGID;
    } else {
      return 0;
    }
  });

  let listOfOrg = null,
    searchHotel = null,
    addHotel = null,
    deactivateHotel = null,
    FilterHotelsByDate = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
      if (permission[key].name === "searchHotel") {
        searchHotel = permission[key];
      }
      if (permission[key].name === "addHotel") {
        addHotel = permission[key];
      }
      if (permission[key].name === "deactivateHotel") {
        deactivateHotel = permission[key];
      }
      if (permission[key].name === "FilterHotelsByDate") {
        FilterHotelsByDate = permission[key];
      }
    }
  }

  useEffect(() => {
    if (!token) {
      return;
    }
    if (token) {
      hotelList.forEach((_, index) => {
        if (
          hotelList[index + 1] &&
          hotelList[index].organizationId !==
            hotelList[index + 1].organizationId
        ) {
          setDifferentOrganization(true);
        }
      });
    }
  }, [token]);

  const handleHotelChange = (value) => {
    const hotelName = value.split("(")[0].trim();
    const selectedHotel = hotelList.find((hotel) => hotel.name === hotelName);

    if (selectedHotel) {
      selectHotel(selectedHotel, true);
      setSelectedHotelId(selectedHotel.id);
    } else {
      selectHotel(null);
      setSelectedHotelId(null);
    }
  };

  const formatDate = (date) =>
    `${date.year}-${date.month
      .toString()
      .padStart(2, "0")}-${date.day.toString().padStart(2, "0")}`;
  async function getHotels() {
    setLoading(true);

    const filterDateData =
      dateNew?.from && dateNew?.to
        ? `&start_date=${formatDate(dateNew.from)}&end_date=${formatDate(
            dateNew.to
          )}`
        : dateNew?.from
        ? `&start_date=${formatDate(dateNew.from)}`
        : "";

    if (listOfOrg) {
      const { get } = await authFetch({
        path:
          `/managing-org/${selectedManagingOrganization}/chains/${selectedChain}/hotels/${
            active ? 1 : 0
          }?limit=${rowsPerPage > 0 ? rowsPerPage : ""}&page=${
            page + 1
          }&hotelName=${debouncesearch ?? ""}&order=desc` + filterDateData,
      });
      const { data, error } = await get();
      if (data) {
        setHotelCount(data?.count);
        const sortedData = data?.hotels?.length
          ? data.hotels.sort((a, b) => (a.name > b.name ? 1 : -1))
          : [];
        setHotelList(sortedData);
        setHotelListNew(sortedData);
        updateHotels();
      } else {
        console.log(error || "No data received");
        setHotelList([]);
        setHotelListNew([]);
      }
    } else {
      setHotelList(hotels || []);
      setHotelCount(hotels?.length);
      setHotelListNew(hotels || []);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (!token) {
      return;
    }
    getHotels();
  }, [
    token,
    rowsPerPage,
    page,
    dateNew,
    selectedManagingOrganization,
    selectedChain,
    listOfOrg,
    debouncesearch,
  ]);

  async function getChains() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/managing-org/${selectedManagingOrganization}/chains`,
    });
    const { data } = await get();
    if (data) {
      if (CHAINID === "all") {
        setSelectedChainName("All");
      } else if (CHAINID === -1) {
        setSelectedChainName("Standalone");
      } else {
        const chainName = data.find((chain) => chain.id === CHAINID)?.name;
        if (chainName) {
          setSelectedChainName(chainName);
        }
      }
    }
    setLoading(false);
  }

  async function getManagingOrganization() {
    setLoading(true);
    const { get } = await authFetch({
      path: `/all-managing-org`,
    });
    const { data, error } = await get();
    if (data) {
      if (ORGID === "all") {
        setSelectedOrganizationName("All");
      } else if (ORGID === -1) {
        setSelectedOrganizationName("Standalone");
      } else {
        const orgName = data.find((org) => org.id === ORGID)?.name;
        if (orgName) {
          setSelectedOrganizationName(orgName);
        }
      }
    } else {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    getManagingOrganization();
    getChains();
  }, [token]);

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);

    setPage(0);
  };

  const formatInputValue = () => {
    if (dateNew) {
      let fromValue;
      if (dateNew?.from) {
        fromValue =
          `${
            dateNew.from.day < 10 ? "0" + dateNew.from.day : dateNew.from.day
          }` +
          "/" +
          `${
            dateNew.from.month < 10
              ? "0" + dateNew.from.month
              : dateNew.from.month
          }` +
          "/" +
          `${dateNew.from.year}`;
      }
      let toValue;
      if (dateNew?.to) {
        toValue =
          `${dateNew.to.day < 10 ? "0" + dateNew.to.day : dateNew.to.day}` +
          "/" +
          `${
            dateNew.to.month < 10 ? "0" + dateNew.to.month : dateNew.to.month
          }` +
          "/" +
          `${dateNew.to.year}`;
      }

      return `${fromValue ?? "Start Date"} - ${toValue ?? "End Date"}`;
    }
    return "";
  };

  const renderCustomInput = ({ ref }) => (
    <input
      readOnly
      ref={ref}
      value={formatInputValue()}
      style={{
        textAlign: "left",
        marginBottom: "2px",
        fontSize: "12px",
        borderRadius: "10px",
        color: "#000000",
        outline: "none",
        height: "45px",
        border: "none",
        fontWeight: 500,
        font: "500 12px / 14px Roboto",
        cursor: "pointer",
        width: "220px",
        paddingLeft: "10px",
      }}
      className="my-custom-input-class"
    />
  );

  const cancelSearch = () => {
    setSearched("");
    // requestSearch(searched);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - hotelList.length) : 0;

  const handleDeactivateHotel = async (hotelId) => {
    const { del } = await authFetch({
      path: `/hotel/${hotelId}`,
      body: JSON.stringify({
        enable: active ? false : true,
      }),
    });
    const { data, error } = await del();
    getHotels();
  };

  useEffect(() => {
    getHotels();
  }, [active]);

  return (
    <ClassWrapper>
      <Page>
        <HotelSection>
          <Main style={{ height: "10%", padding: "24px 0px 24px 0px" }}>
            {listOfOrg ? (
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                style={{ marginLeft: "20px" }}
              >
                <Link
                  style={{
                    color: "#303030",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  to="/managing_organization"
                >
                  Home
                </Link>
                <Link
                  style={{
                    color: "#303030",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  to={
                    "/managing_organization/" +
                    selectedManagingOrganization +
                    "/chain"
                  }
                >
                  {selectedOrganizationName}
                </Link>
                <Link
                  style={{
                    color: "#2F6FBC",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  to={
                    "/managing_organization/" +
                    selectedManagingOrganization +
                    "/chain/" +
                    selectedChain +
                    "/choose_hotel"
                  }
                >
                  {selectedChainName}
                </Link>
              </Breadcrumbs>
            ) : (
              <Breadcrumbs
                separator="›"
                aria-label="breadcrumb"
                style={{ marginLeft: "20px" }}
              >
                <Link color="inherit" to="/choose_hotel">
                  Home
                </Link>
              </Breadcrumbs>
            )}

            <Stack
              direction="row"
              justifyContent={"flex-end"}
              alignItems="center"
              gap="8px"
            >
              {FilterHotelsByDate && (
                <Tooltip
                  arrow
                  placement="top"
                  title="Filter Hotels by Created Date"
                >
                  <StyledCalenderWrapper>
                    <DatePicker
                      value={dateNew}
                      onChange={(date) => {
                        setDateNew(date);
                        setPage(0);
                      }}
                      colorPrimaryLight="#91ABE9"
                      clearButton
                      colorPrimary="#163A90"
                      calendarPopperPosition="auto" // Set reference to DatePicker
                      calendarClassName="myCustomCalendar"
                      renderInput={renderCustomInput}
                    />
                    {(dateNew?.to || dateNew?.from) && (
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setDateNew({
                            from: "",
                            to: "",
                          });
                        }}
                        sx={{
                          background: "transparent",
                          right: "32px",
                          zIndex: 98,
                          top: "7%",
                          position: "absolute",
                        }}
                      >
                        <Close color="inherit" />
                      </IconButton>
                    )}
                    <CalenderIcon />
                  </StyledCalenderWrapper>
                </Tooltip>
              )}

              <FormControl sx={{ width: "200px" }}>
                <InputLabel id="demo-multiple-checkbox-label">
                  Filter
                </InputLabel>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={filter}
                  onChange={handleFilterChange}
                  input={
                    <OutlinedInput
                      sx={{
                        height: "45px",
                      }}
                      label="Filter"
                    />
                  }
                  renderValue={(selected) => {
                    return selected
                      .map((option) => {
                        return option.replace(/Enabled$/, " Enabled");
                      })
                      .join(", ");
                  }}
                >
                  <MenuItem key={"RateShopEnabled"} value={"RateShopEnabled"}>
                    <Checkbox
                      checked={filter.indexOf("RateShopEnabled") > -1}
                    />
                    <ListItemText primary={"RateShop Enabled"} />
                  </MenuItem>
                  <MenuItem key={"PreciumEnabled"} value={"PreciumEnabled"}>
                    <Checkbox checked={filter.indexOf("PreciumEnabled") > -1} />
                    <ListItemText primary={"Precium Enabled"} />
                  </MenuItem>
                </Select>
              </FormControl>

              <Stack direction="row" sx={{ padding: "2px" }}>
                <Typography style={{ marginTop: "5px", color: "#306FBC" }}>
                  {active ? "Active" : "Inactive"}
                </Typography>
                <Switch
                  checked={active}
                  onChange={() => {
                    setActive(!active);
                  }}
                />
              </Stack>

              <Stack direction="row" justifyContent={"flex-end"}>
                <Search>
                  <SearchBox
                    variant="standard"
                    placeholder="Search by Hotel ID, Name or Location"
                    InputProps={{ disableUnderline: true }}
                    value={searched}
                    onChange={(e) => requestSearch(e.target.value)}
                    onCancelSearch={() => cancelSearch()}
                  />
                  <SearchIcon
                    style={{
                      justifyContent: "flex-end",
                      color: "#306FBC",
                      marginTop: "8px",
                      position: "absolute",
                    }}
                  />
                </Search>
              </Stack>

              {addHotel && (
                <ButtonContainer
                  style={{
                    background: "#2F6FBC",
                    boxShadow:
                      "0px 3px 1px -2px rgba(48, 111, 188, 0.2), 0px 2px 2px rgba(48, 111, 188, 0.2), 0px 1px 5px rgba(48, 111, 188, 0.2)",
                    borderRadius: "8px",
                  }}
                >
                  <Link to="/hotel" style={{ color: "#ffffff" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <AddOutlined
                        style={{
                          width: "20px",
                          color: "white",
                        }}
                      />
                      <AddHotel> Add Hotel</AddHotel>
                    </Box>
                  </Link>
                </ButtonContainer>
              )}
            </Stack>
          </Main>

          {!Loading ? (
            <>
              <TopSection></TopSection>
              <OrganizationSection
                style={{ marginTop: "0px", paddingRight: "0px" }}
              >
                <Paper
                  style={{
                    overflow: "auto",
                    maxHeight: "calc(100vh - 280px)",
                  }}
                >
                  <TableContainer style={{ overflow: "visible" }}>
                    <Table className="table">
                      <TableHead
                        sx={{
                          position: "sticky",
                          zIndex: 1,
                        }}
                      >
                        <TableRow className="header">
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Hotel ID
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Hotel Name
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            City
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Precium Enabled
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            RateShop Enabled
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            BOB
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Rate Comparison
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Last Night Actual
                          </TableCellHeading>

                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Hotel created Date
                          </TableCellHeading>
                          <TableCellHeading
                            style={{
                              backgroundColor: "#306fbc",
                              color: "#ffffff",
                            }}
                            align="center"
                          >
                            Status
                          </TableCellHeading>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {hotelListNew
                          .sort((a, b) => {
                            const dateA = new Date(a.createdAt);
                            const dateB = new Date(b.createdAt);
                            return dateB - dateA;
                          })
                          .map((hotel, hIndex) => (
                            <TableRow key={hIndex} hover className="rowhover">
                              <TCell
                                component="th"
                                scope="row"
                                style={
                                  hotel?.hotelReferenceId
                                    ? {
                                        textAlign: "center",
                                      }
                                    : {
                                        textAlign: "center",
                                      }
                                }
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.hotelReferenceId || "-"}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel.name}
                                {differentOrganization
                                  ? " (" + hotel.organizationName + ")"
                                  : ""}
                              </TCell>

                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.city || ""}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.isPreciumEnabled ? "Yes" : "No"}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.rateShopEnabled ? "Yes" : "No"}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.forecastFileStatus ? (
                                  <BOB
                                    style={{
                                      color: "#42AE40",
                                    }}
                                  >
                                    Uploaded
                                  </BOB>
                                ) : (
                                  <BOB
                                    style={{
                                      color: "#E13A3A",
                                    }}
                                  >
                                    Pending
                                  </BOB>
                                )}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.competitorFileStatus ? (
                                  <RateComparsion
                                    style={{
                                      color: "#42AE40",
                                    }}
                                  >
                                    Uploaded
                                  </RateComparsion>
                                ) : (
                                  <RateComparsion
                                    style={{
                                      color: "#E13A3A",
                                    }}
                                  >
                                    Pending
                                  </RateComparsion>
                                )}
                              </TCell>
                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {hotel?.arrStatus ? (
                                  <LN
                                    style={{
                                      color: "#42AE40",
                                    }}
                                  >
                                    Uploaded
                                  </LN>
                                ) : (
                                  <LN
                                    style={{
                                      color: "#E13A3A",
                                    }}
                                  >
                                    Pending
                                  </LN>
                                )}
                              </TCell>

                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                                onClick={() => {
                                  selectHotel(hotel, true);
                                }}
                              >
                                {moment(hotel.createdAt).format("DD/MM/yyyy")}
                              </TCell>

                              <TCell
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {!active ? (
                                  <Status
                                    style={{
                                      backgroundColor: "#EDE762",
                                    }}
                                    onClick={() => {
                                      deactivateHotel &&
                                        handleDeactivateHotel(hotel.id);
                                    }}
                                  >
                                    Inactive
                                  </Status>
                                ) : (
                                  <Status
                                    onClick={() => {
                                      deactivateHotel &&
                                        handleDeactivateHotel(hotel.id);
                                    }}
                                  >
                                    Active
                                  </Status>
                                )}
                              </TCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                <TablePagination
                  sx={{
                    background: "#FFFFFF",
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  displayedRows={<p></p>}
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={3}
                  count={hotelCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    style: {
                      border: "1px solid #1063A280",
                      borderRadius: "5px",
                    },
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </OrganizationSection>
            </>
          ) : (
            <LoadingPage />
          )}
        </HotelSection>
      </Page>
    </ClassWrapper>
  );
}
