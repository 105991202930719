import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../../sdk";
import { format } from "date-fns";

const websites = [
  "All",
  "Expedia",
  "Brand Website",
  "Agoda",
  "Booking.com",
  "MakeMy Trip",
];
const sources = ["Desktop", "Mobile"];
const nights = ["1 Night", "2 Nights", "3 Nights"];
const guests = ["1 Guest", "2 Guests", "3 Guests", "4 Guests"];
const fileExport = ["Next 60 Days", "Next 90 Days", "Selected Month", "Custom"];

export default function useChannelComparision() {
  const defaultDate = new Date();
  const lastDay = new Date(
    defaultDate.getFullYear(),
    defaultDate.getMonth() + 1,
    0
  );

  const [fromDateNew, setFromDateNew] = useState(defaultDate);
  const [toDateNew, settoDateNew] = useState(defaultDate);

  const [addEmailDialog, setAddEmailDialog] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const abortRef = useRef(null);

  const [selectedMonthYear, setSelectedMonthYear] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );

  useEffect(() => {
    const currentDate = new Date();

    const firstdayOfMonth =
      selectedMonthYear.getMonth() === currentDate.getMonth() &&
      selectedMonthYear.getFullYear() === currentDate.getFullYear()
        ? currentDate.getDate()
        : 1;

    setFromDateNew(
      new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        firstdayOfMonth
      )
    );
    settoDateNew(
      new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        firstdayOfMonth
      )
    );
  }, [selectedMonthYear]);

  const [tableData, setTableData] = useState({ competitors: [], data: [] });
  const [channels, setChannels] = useState([]);
  const [mobileChannels, setMobileChannels] = useState([]);
  const { hotelId } = useParams();
  const [oldestRate, setOldestRate] = useState(null);

  const {
    hotels,
    token,
    authFetch,
    permission,
    selectHotel,
    updateHotels,
    currentHotel,
  } = useAuth();
  const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const [monthlyPendingCredits, setMonthlyPendingCredits] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [clickonRefreshBtn, setClickonRefreshBtn] = React.useState(true);
  const openRefreshDialog = () => {
    setOpen(true);
  };

  const closeRefreshDialog = () => {
    setOpen(false);
  };

  const [hotelList, setHotelList] = useState([]);
  const [differentOrganization, setDifferentOrganization] = useState(false);
  const [selectedHotelId, setSelectedHotelId] = useState("");

  const [openCustom, setOpenCustom] = React.useState(false);
  const openCustomDialog = () => {
    setOpenCustom(true);
  };

  const closeCustomDialog = () => {
    setOpenCustom(false);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [togglePage, setTogglePage] = useState(false);

  const [filters, setFilters] = useState({
    channelIds: [],
    sourceName: [sources?.[0].toString()],
    nightName: [nights?.[0].toString()],
    guestName: [guests?.[0].toString()],
    exportName: "",
  });

  let listOfOrg = null;
  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "listOfOrg") {
        listOfOrg = permission[key];
      }
    }
  }

  const getSources = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/sources`,
    });
    const { data, error, response } = await get();
    if (response?.ok) {
      const newData = [...data];
      setChannels(newData?.filter((data) => !data?.isMobile));
      const mobileData = newData?.filter((i) => i?.isMobile);
      setMobileChannels(mobileData);
    } else {
      setErrorSnackbar(true);
      setErrorMsg(data?.messageToUser || "Something went wrong");
    }
  }, [authFetch, hotelId]);

  useEffect(() => {
    if (token) {
      getSources();
    }
  }, [token, getSources]);

  //api to get sources name

  const formatDate = useCallback((date) => {
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
    const day = new Date(date).getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }, []);

  const formatDateforTable = useCallback(
    (date) => {
      const year = new Date(date).getFullYear();
      const month = (new Date(date).getMonth() + 1).toString().padStart(2, "0");
      const day = new Date(date).getDate().toString().padStart(2, "0");
      const weekday = Days[new Date(date).getDay()];
      return `${day}-${month}-${year} ${weekday}`;
    },
    [Days]
  );
  const getDatesArray = useCallback((startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const endDateObj = new Date(endDate);
    while (currentDate <= endDateObj) {
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      dates.push(dateString);
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }, []);

  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const isCalledOnce = useRef(false);

  useEffect(() => {
    const queryDate = searchParams.get("date");
    const parsedDate = new Date(queryDate);

    const year = selectedMonthYear.getFullYear();
    const month = selectedMonthYear.getMonth();
    const date = new Date(year, month, 1);
    const finalDate = format(date, "yyyy-MM-dd");
    !queryDate && searchParams.set("date", finalDate);
    history.replace({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });

    queryDate && setSelectedMonthYear(parsedDate);
  }, []);

  const getRateShop = useCallback(async () => {
    setIsLoading(true);

    if (filters?.channelIds?.length === 0) {
      setTableData({ competitors: [], data: [] });
      setIsLoading(false);
      return;
    }

    const { get, abort } = await authFetch({
      path: `/hotel/${hotelId}/rate-shop`,
    });

    abortRef.current = abort;

    const sources = filters?.channelIds;
    const los = filters?.nightName?.[0]?.[0];
    const occupancy = filters?.guestName?.[0]?.[0];
    const today = new Date();

    let startDate;
    let endDate;

    if (
      selectedMonthYear.getFullYear() === today.getFullYear() &&
      selectedMonthYear.getMonth() === today.getMonth()
    ) {
      startDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate()
      );
      endDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + 30
      );
    } else {
      startDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        selectedMonthYear.getDate()
      );
      endDate = new Date(
        selectedMonthYear.getFullYear(),
        selectedMonthYear.getMonth(),
        selectedMonthYear.getDate() + 30
      );
    }

    const start = formatDate(startDate);
    const end = formatDate(endDate);

    const body = {
      startDate: start,
      endDate: end,
      sources,
      los: los?.toString() || 1,
      occupancy: occupancy?.toString() || 1,
      hotelOnly: true,
    };

    const { data, error, response } = await get(
      `?${new URLSearchParams(body)}`
    );
    if (response?.ok) {
      const allDates = getDatesArray(startDate, endDate);
      setOldestRate(data.oldestRate);
      setMonthlyPendingCredits(data?.monthlyPendingCredits);

      data.finalResult = data?.finalResult?.map((item) => {
        item.hotelResult = item?.hotelResult?.reduce(
          (acc, hotel, index) => {
            acc.sourceSortedArray = [
              ...acc?.sourceSortedArray,
              ...hotel?.sourceSortedArray,
            ];
            return acc;
          },
          { sourceSortedArray: [] }
        );

        return item;
      });

      const competitors = data?.finalResult?.find(
        (data) => data.competitorsResults
      )?.competitorsResults;
      const paddedData = allDates?.map((date, index) => {
        const matchingData =
          data &&
          data?.finalResult?.find(
            (d) => d.rateDate.slice(0, 10) === date.slice(0, 10)
          );
        if (matchingData) {
          return {
            ...matchingData,
          };
        } else {
          return {
            rateDate: date,
            minHotelPrice: "",
            meanCompetitorPrice: "",
            hotelResult: {
              hotelId: "",
              hotelName: "",
              minPrice: "",
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            },
            competitorsResults: {
              hotelId: "",
              hotelName: "",
              minPrice: "",
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            },
          };
        }
      });

      setTableData({
        competitors,
        data: paddedData?.map((data) => ({
          ...data,
          rateDate: formatDateforTable(data.rateDate),
          competitorsResults: competitors?.map(() => {
            return {
              hotelId: "",
              hotelName: "",
              minPrice: null,
              sourceSortedArray: channels?.map(({ id }) => ({
                sourceId: id,
                price: null,
              })),
            };
          }),
        })),
      });
    } else {
      setErrorSnackbar(true);
      setErrorMsg(data?.messageToUser || "Something went wrong");
      console.log(error);
    }
    setIsLoading(false);
  }, [
    authFetch,
    filters,
    formatDate,
    getDatesArray,
    hotelId,
    selectedMonthYear,
    channels,
  ]);

  const getRateShopDetails = useCallback(async () => {
    try {
      isCalledOnce.current = true;

      const queryLos = searchParams.get("los");
      const queryOccupancy = searchParams.get("occupancy");
      const querySources = searchParams.getAll("sources");

      const firstDay = format(selectedMonthYear, "yyyy-MM-dd");

      const { get } = await authFetch({
        path: `/hotel/${hotelId}/last-search-result-for-month?date=${firstDay}&hotelOnly=true`,
      });

      const { data, response } = await get();

      setFilters({
        ...filters,
        channelIds: data?.sources ?? channels?.map((item) => item?.sourceId),
        guestName: guests.filter((item) =>
          queryOccupancy
            ? item[0] === queryOccupancy
            : item[0] === data?.occupancy?.toString()
        ),
        nightName: nights.filter((item) =>
          queryLos ? item[0] === queryLos : item[0] === data?.los?.toString()
        ),
      });

      !querySources && searchParams.set("sources", data?.sources ?? []);
      !queryLos && searchParams.set("los", data?.los);
      !queryOccupancy && searchParams.set("occupancy", data?.occupancy);
      const lastRateShopSource = [...mobileChannels, ...channels]?.find(
        (source) => source?.sourceId === data?.sources[0]
      );

      const sourceName = !lastRateShopSource?.isMobile
        ? sources[0]
        : sources[1];

      setFilters((prv) => ({
        ...prv,
        sourceName: [sourceName],
      }));

      history.replace({
        pathname: history.location.pathname,
        search: searchParams.toString(),
      });
    } catch (err) {
      console.log(err);
    }
  }, [
    authFetch,
    channels,
    filters,
    history,
    hotelId,
    mobileChannels,
    searchParams,
    selectedMonthYear,
  ]);

  useEffect(() => {
    if (!token || !channels.length || !mobileChannels.length) return;
    getRateShopDetails();
  }, [channels.length, mobileChannels.length, token]);

  const canPostRateMetric = async (values) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/can-rate-metric`,
    });

    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const horizonrange = `${fromDateNew.getFullYear()}/${(
      fromDateNew.getMonth() + 1
    )
      ?.toString()
      ?.padStart(2, "0")}/${fromDateNew
      .getDate()
      ?.toString()
      ?.padStart(2, "0")}-${toDateNew.getFullYear()}/${(
      toDateNew.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${toDateNew.getDate()?.toString()?.padStart(2, "0")}`;
    const horizon = toDateNew.getDate() - fromDateNew.getDate() + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: false,
    };

    try {
      const { data, response } = await post(requestBody);
      if (response.status === 200) {
        postRateMetric({
          email: values?.email,
          refreshDate: values?.refreshDate,
        });
      } else {
        setErrorSnackbar(true);
        setErrorMsg(data?.messageToUser || "Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postRateMetric = async (values) => {
    const { post } = await authFetch({
      path: `/hotel/${hotelId}/rate-metric`,
    });

    const losIndex = nights.indexOf(filters.nightName?.[0]);
    const los = losIndex >= 0 ? losIndex + 1 : null;

    const guestsIndex = guests.indexOf(filters.guestName?.[0]);
    const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;

    const horizonrange = `${fromDateNew.getFullYear()}/${(
      fromDateNew.getMonth() + 1
    )
      ?.toString()
      ?.padStart(2, "0")}/${fromDateNew
      .getDate()
      ?.toString()
      ?.padStart(2, "0")}-${toDateNew.getFullYear()}/${(
      toDateNew.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${toDateNew.getDate()?.toString()?.padStart(2, "0")}`;
    const horizon = toDateNew.getDate() - fromDateNew.getDate() + 1;

    const requestBody = {
      sources: filters.channelIds,
      los: los || null,
      occupancy: occupancy || null,
      horizonrange,
      horizon,
      hotelOnly: true,
      email: values?.email,
    };

    try {
      const { data } = await post(requestBody);
      if (data?.messageToUser) {
        setErrorSnackbar(true);
        setErrorMsg(data?.messageToUser || "Something went wrong");
      }
      getRateShop();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isCalledOnce.current) return;
    if (
      filters.channelIds.length ||
      filters.guestName.length ||
      filters.nightName.length ||
      filters.sourceName.length
    ) {
      getRateShop();
    }
  }, [
    filters.guestName,
    filters.channelIds,
    filters.nightName,
    filters.sourceName,
    getRateShop,
  ]);

  useEffect(() => {
    if (!isCalledOnce.current) return;
    getRateShopDetails();
  }, [selectedMonthYear]);

  const [showSkeleton, setShowSkeleton] = useState(true);
  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      timeoutId = setInterval(async () => {
        setShowSkeleton(false);
        await getRateShop();
        setShowSkeleton(true);
      }, 30 * 1000);
    };
    fetchData();
    return () => clearInterval(timeoutId);
  }, [getRateShop]);

  const handleCloseAddEmailDialog = () => {
    setAddEmailDialog(false);
  };

  const handleIncrementDecrementDate = useCallback(
    (tag) => {
      const year = selectedMonthYear.getFullYear();
      const month = selectedMonthYear.getMonth();
      let date = new Date(year, month, 1);

      if (tag === "left") {
        date.setMonth(date.getMonth() - 1);
      } else if (tag === "right") {
        date.setMonth(date.getMonth() + 1);
      }

      const parsedDate = format(date, "yyyy-MM-dd");
      const newSearchParams = new URLSearchParams();
      newSearchParams.set("date", parsedDate);

      history.replace({
        pathname: history.location.pathname,
        search: newSearchParams.toString(),
      });

      setSelectedMonthYear(date);
    },
    [history, selectedMonthYear]
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openExport = Boolean(anchorEl);

  const exportFileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const exportFileMenuClose = () => {
    setAnchorEl(null);
  };

  const exportFile = useCallback(
    async (startDate, endDate) => {
      const { get } = await authFetch({
        path: `/hotel/${hotelId}/rate-parity/excel`,
      });
      const losIndex = nights.indexOf(filters.nightName?.[0]);
      const los = losIndex >= 0 ? losIndex + 1 : null;
      const guestsIndex = guests.indexOf(filters.guestName?.[0]);
      const occupancy = guestsIndex >= 0 ? guestsIndex + 1 : null;
      const channelIds = filters.channelIds.map((id) => String(id));
      const sources = channelIds.join(",");
      const body = {
        los: los?.toString(),
        occupancy: occupancy?.toString(),
        hotelOnly: true,
        endDate: formatDate(endDate),
        startDate: formatDate(startDate),
      };

      const response = await get(
        `?${new URLSearchParams(body)}&sources=${sources}`
      );
      const blob = await response.response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${formatDate(startDate)}-${formatDate(endDate)}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
    },
    [filters, hotelId, guests, nights, authFetch]
  );
  return {
    lastDay,
    fromDateNew,
    setFromDateNew,
    toDateNew,
    settoDateNew,
    addEmailDialog,
    setAddEmailDialog,
    errorSnackbar,
    setErrorSnackbar,
    abortRef,
    selectedMonthYear,
    setSelectedMonthYear,
    // formatInputValue,
    // formatInputValueToDate,
    // renderCustomInput,
    // renderCustomInputToDate,
    tableData,
    setTableData,
    channels,
    setChannels,
    hotelId,
    oldestRate,
    setOldestRate,
    isLoading,
    open,
    clickonRefreshBtn,
    setClickonRefreshBtn,
    openRefreshDialog,
    closeRefreshDialog,
    hotelList,
    setHotelList,
    differentOrganization,
    setDifferentOrganization,
    selectedHotelId,
    setSelectedHotelId,
    openCustom,
    setOpenCustom,
    openCustomDialog,
    closeCustomDialog,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    togglePage,
    setTogglePage,
    filters,
    setFilters,
    listOfOrg,
    formatDate,
    getDatesArray,
    getRateShop,
    canPostRateMetric,
    postRateMetric,
    getSources,
    handleCloseAddEmailDialog,
    handleIncrementDecrementDate,
    showSkeleton,
    monthlyPendingCredits,
    fileExport,
    openExport,
    exportFileMenuOpen,
    exportFileMenuClose,
    anchorEl,
    guests,
    exportFile,
    mobileChannels,
    errorMsg,
    setErrorMsg,
  };
}
