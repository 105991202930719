import {
  MenuItem,
  Select,
  Snackbar,
  SnackbarContent,
  TextField as TF,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DatePicker,
  ErrorPage,
  LoadingPage,
  NoAccess,
  Nodata,
  useAuth,
} from "../sdk";
import PaceCalculation from "./components/PaceCalculation";
import {
  Grid,
  Header,
  HeaderCard,
  Label,
  PacePage,
  SubHeader,
  TableCard,
} from "./components/Styles";
import { useDebouncedEffect } from "./hooks/useDebounceEffect";
import usePace from "./hooks/usePace";
import { PreciumDisabledPage } from "../sdk/components/PreciumDiabledPage";

const typesOfPickup = [
  { value: "occPickup", name: "Occupancy Pickup" },
  { value: "arrPickup", name: "ADR Pickup" },
  { value: "revPickup", name: "Revenue Pickup" },
];

const Options = styled(Select)`
  .MuiSelect-select.MuiSelect-select {
    padding-left: 8px;
  }
`;

export default function Pace({ setPageHeader }) {
  const { hotelId } = useParams();
  const { token, permission, currentHotel } = useAuth();
  const [fromDate1, setFromDate1] = useState(
    new Date(new Date().setDate(new Date().getDate() - 1))
  );
  useEffect(() => {
    setPageHeader("Pace Analysis");
    return () => {
      setPageHeader("");
    };
  }, []);
  const [fromDate2, setFromDate2] = useState(
    new Date(
      new Date(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate() - 1
      )
    )
  );

  const [fromDatePeriod1, setFromDatePeriod1] = useState(
    new Date(new Date().setDate(new Date().getDate() - 6))
  );

  const [fromDatePeriod2, setFromDatePeriod2] = useState(
    new Date(new Date().setDate(new Date().getDate() - 6))
  );

  const [selectedPickup, setSelectedPickup] = useState("occPickup");

  const [toDate1, setToDate1] = useState(new Date());
  const [toDate2, setToDate2] = useState(
    new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    )
  );

  const [toDatePeriod1, setToDatePeriod1] = useState(new Date());
  const [toDatePeriod2, setToDatePeriod2] = useState(new Date());

  const [pickup1, setPickup1] = useState(
    Math.round((toDate1 - fromDate1) / (1000 * 60 * 60 * 24))
  );
  const [pickup2, setPickup2] = useState(
    Math.round((toDate2 - fromDate2) / (1000 * 60 * 60 * 24))
  );
  const [pickupPeriod1, setPickupPeriod1] = useState(
    Math.round((toDatePeriod1 - fromDatePeriod1) / (1000 * 60 * 60 * 24)) + 1
  );
  const [pickupPeriod2, setPickupPeriod2] = useState(
    Math.round((toDatePeriod2 - fromDatePeriod2) / (1000 * 60 * 60 * 24)) + 1
  );

  const [monthChangeDetector1, setMonthChangeDetector1] = useState(
    toDate1.getMonth()
  );

  useDebouncedEffect(
    () => {
      if (fromDatePeriod1.getMonth() !== monthChangeDetector1) {
        setMonthChangeDetector1(fromDatePeriod1.getMonth());
      }
    },
    900,
    [fromDatePeriod1]
  );

  const [monthChangeDetector2, setMonthChangeDetector2] = useState(
    toDate1.getMonth()
  );

  useDebouncedEffect(
    () => {
      if (fromDatePeriod2.getMonth() !== monthChangeDetector2) {
        setMonthChangeDetector2(fromDatePeriod2.getMonth());
      }
    },
    900,
    [fromDatePeriod2]
  );

  let analytics = null;
  const {
    monthlyBookings,
    open,
    handleCloseStatus,
    networkMsg,
    Loading,
    Error,
    variance,
  } = usePace({
    fromDate1,
    toDate1,
    fromDate2,
    toDate2,
    fromDatePeriod1,
    toDatePeriod1,
    fromDatePeriod2,
    toDatePeriod2,
    selectedPickup,
    monthChangeDetector1,
    monthChangeDetector2,
    setFromDatePeriod1,
    setFromDatePeriod2,
    setToDatePeriod1,
    setToDatePeriod2,
    setPickupPeriod1,
  });

  useEffect(() => {
    if (token && hotelId) {
      window.scrollTo(0, 0);
    } else {
      return;
    }
  }, [token, hotelId]);

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "analytics") {
        analytics = permission[key];
      }
    }
  }

  function handleFromDateChangePeriod1(date) {
    setFromDatePeriod1(new Date(date));
    const diffDays = Math.round((toDatePeriod1 - date) / (1000 * 60 * 60 * 24));
    setPickupPeriod1(diffDays);
  }
  function handleFromDateChangePeriod2(date) {
    setFromDatePeriod2(new Date(date));
    const diffDays = Math.round((toDatePeriod2 - date) / (1000 * 60 * 60 * 24));
    setPickupPeriod2(diffDays);
  }

  function handleToDateChange1(date) {
    setToDate1(new Date(date));
    setFromDate1(new Date(date.getTime() - pickup1 * 24 * 60 * 60 * 1000));
  }
  function handleToDateChange2(date) {
    setToDate2(new Date(date));
    setFromDate2(new Date(date.getTime() - pickup2 * 24 * 60 * 60 * 1000));
  }
  function handleToDateChangePeriod1(date) {
    setToDatePeriod1(new Date(date));
    setFromDatePeriod1(
      new Date(date.getTime() - (pickupPeriod1 - 1) * 24 * 60 * 60 * 1000)
    );
  }
  function handleToDateChangePeriod2(date) {
    setToDatePeriod2(new Date(date));
    setFromDatePeriod2(
      new Date(date.getTime() - (pickupPeriod2 - 1) * 24 * 60 * 60 * 1000)
    );
  }

  function handlePickup2(value) {
    if (value === "") {
      setPickup2(value);
      setPickup1(value);
      setFromDate2(
        new Date(
          toDate2.getFullYear(),
          toDate2.getMonth(),
          toDate2.getDate() - 1
        )
      );
      setFromDate1(
        new Date(
          toDate1.getFullYear(),
          toDate1.getMonth(),
          toDate1.getDate() - 1
        )
      );
      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }

    setPickup2(value);
    setFromDate2(new Date(toDate2.getTime() - value * 24 * 60 * 60 * 1000));
    setPickup1(value);
    setFromDate1(new Date(toDate1.getTime() - value * 24 * 60 * 60 * 1000));
  }

  function handlePickupPeriod1(value) {
    if (value === "") {
      setPickupPeriod1(value);
      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }
    // if (value === "1") {
    //   value = 2;
    // }
    setPickupPeriod1(value);
    setFromDatePeriod1(
      new Date(toDatePeriod1.getTime() - (value - 1) * 24 * 60 * 60 * 1000)
    );
  }

  function handlePickupPeriod2(value) {
    if (value === "") {
      setPickupPeriod2(value);
      return;
    }
    if (value < 0) {
      value = value * -1;
    }
    if (value === "0" || value === "-0") {
      value = 1;
    }
    // if (value === "1") {
    //   value = 2;
    // }

    setPickupPeriod2(value);
    setFromDatePeriod2(
      new Date(toDatePeriod2.getTime() - (value - 1) * 24 * 60 * 60 * 1000)
    );
  }

  return currentHotel?.isPreciumEnabled ? (
    !Error && analytics ? (
      <PacePage style={{ width: "100%" }}>
        <Header sx={{ position: "sticky", top: 54, zIndex: "8" }}>
          <SubHeader>
            <Grid>
              <HeaderCard style={{ width: "140px" }}>
                <Label>BOB as of</Label>
                <DatePicker
                  inputFormat={"dd/MM/yyyy"}
                  date={toDate1}
                  onChange={(date) => {
                    handleToDateChange1(date);
                  }}
                />
              </HeaderCard>
            </Grid>
          </SubHeader>

          <SubHeader>
            <Grid>
              <HeaderCard>
                <Label>Compared with</Label>
                <DatePicker
                  inputFormat={"dd/MM/yyyy"}
                  date={toDate2}
                  onChange={(date) => {
                    handleToDateChange2(date);
                  }}
                />
              </HeaderCard>
            </Grid>
          </SubHeader>
          <SubHeader>
            <Grid>
              <HeaderCard>
                <Label>Pick-up Days</Label>
                <TF
                  type="number"
                  variant="standard"
                  value={pickup2}
                  onChange={(e) => {
                    handlePickup2(e.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  style={{ width: "35px" }}
                  InputProps={{
                    disableUnderline: true,
                    inputProps: { min: 1, max: 100 },
                  }}
                />
              </HeaderCard>
            </Grid>
          </SubHeader>
          <SubHeader>
            <Grid>
              <HeaderCard
                style={{
                  justifyContent: "flex-start",
                  paddingRight: "20px",
                }}
              >
                <Options
                  disableUnderline
                  variant="standard"
                  value={typesOfPickup.name}
                  defaultValue="occPickup"
                  onChange={(e) => setSelectedPickup(e.target.value)}
                  style={{ fontFamily: "Roboto" }}
                >
                  {typesOfPickup.map((pickup, idx) => (
                    <MenuItem
                      style={{ fontFamily: "Roboto" }}
                      key={idx}
                      value={pickup.value}
                    >
                      {pickup.name}
                    </MenuItem>
                  ))}
                </Options>
              </HeaderCard>
            </Grid>
          </SubHeader>
        </Header>

        {!Loading ? (
          monthlyBookings && monthlyBookings.length > 0 ? (
            <>
              <TableCard>
                {
                  <PaceCalculation
                    fromDate1={fromDate1}
                    toDate1={toDate1}
                    fromDate2={fromDate2}
                    toDate2={toDate2}
                    monthlyBookings={monthlyBookings}
                    variance={variance}
                    selectedPickup={selectedPickup}
                    fromDatePeriod1={fromDatePeriod1}
                    handleFromDateChangePeriod1={handleFromDateChangePeriod1}
                    toDatePeriod1={toDatePeriod1}
                    handleToDateChangePeriod1={handleToDateChangePeriod1}
                    pickupPeriod1={pickupPeriod1}
                    handlePickupPeriod1={handlePickupPeriod1}
                    fromDatePeriod2={fromDatePeriod2}
                    handleFromDateChangePeriod2={handleFromDateChangePeriod2}
                    toDatePeriod2={toDatePeriod2}
                    handleToDateChangePeriod2={handleToDateChangePeriod2}
                    pickupPeriod2={pickupPeriod2}
                    handlePickupPeriod2={handlePickupPeriod2}
                  />
                }
              </TableCard>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                open={open}
                autoHideDuration={3000}
                onClose={handleCloseStatus}
              >
                <SnackbarContent
                  style={{ backgroundColor: "#CA3433" }}
                  message={networkMsg}
                />
              </Snackbar>
            </>
          ) : (
            <Nodata />
          )
        ) : (
          <LoadingPage />
        )}
      </PacePage>
    ) : !analytics ? (
      <NoAccess />
    ) : (
      <ErrorPage />
    )
  ) : (
    <PreciumDisabledPage />
  );
}
