import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../sdk";

export default function usePickup({ fromDate, toDate, selectedPickup }) {
  const { token, hotelSelect, authFetch, currentHotel } = useAuth();
  const { hotelId } = useParams();
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const [monthlyRevenue, setMonthlyReveue] = useState([]);
  const [monthlyArr, setMonthlyArr] = useState([]);
  const [monthlyOccu, setMonthlyOccu] = useState([]);
  const [networkMsg, setnetworkMsg] = useState();
  const [open, setOpen] = useState();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [ids, setSelectedIds] = useState([parseInt(hotelId)]);
  const [hotelOptions, setHotelOptions] = useState([]);

  
  const getHotels = useCallback(async () => {
  try {
      const { get } = await authFetch({
        path: `/managing-org/${currentHotel?.managedBy || ""}/chains/${
          currentHotel?.organizationId || ""
        }/hotels/${currentHotel?.id || ""}`,
      });
      const { data, response } = await get();

      if (response?.ok) {
        setHotelOptions(data?.hotels);
      }
    } catch (err) {
      console.log(err);
    }
  }, [authFetch, currentHotel]);
  useEffect(() => {
    getHotels();
  }, [getHotels]);

  useEffect(() => {
    if (
      monthlyArr.length <= 0 ||
      monthlyRevenue.length <= 0 ||
      monthlyOccu.length <= 0
    ) {
      return;
    } else {
      if (selectedPickup === "occPickup") {
        setMonthlyBookings(monthlyOccu);
      } else if (selectedPickup === "arrPickup") {
        setMonthlyBookings(monthlyArr);
      } else if (selectedPickup === "revPickup") {
        setMonthlyBookings(monthlyRevenue);
      }
    }
  }, [monthlyArr, monthlyOccu, monthlyRevenue, selectedPickup]);

  const fetchRevData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/cumulative-revenue-pickup?ids=${ids?.length > 0 ? ids : hotelId}`,
    });

    const localFromDate = new Date(
      fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
    ).toISOString();

    const localToDate = new Date(
      toDate.getTime() - toDate.getTimezoneOffset() * 60000
    ).toISOString();

    const { data, response } = await post({
      fromDate: localFromDate,
      toDate: localToDate,
    });

    if (response?.ok) {
      setMonthlyReveue(data);
    } else {
      setError(true);
      setnetworkMsg(data?.messageToUser || "Something went wrong");
    }
  }, [authFetch, fromDate, hotelId, ids, toDate]);

  const fetchOccData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/cumulative-pickup?ids=${ids?.length > 0 ? ids : hotelId}`,
    });
    const localFromDate = new Date(
      fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
    ).toISOString();

    const localToDate = new Date(
      toDate.getTime() - toDate.getTimezoneOffset() * 60000
    ).toISOString();
    const { data, response } = await post({
      fromDate: localFromDate,
      toDate: localToDate,
    });

    if (response?.ok) {
      setMonthlyOccu(data);
    } else {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setError(true);
      setMonthlyOccu([]);
    }
  }, [authFetch, fromDate, hotelId, ids, toDate]);

  const fetchARRData = useCallback(async () => {
    const { post } = await authFetch({
      path: `/cumulative-arr-pickup?ids=${ids?.length > 0 ? ids : hotelId}`,
    });
    const localFromDate = new Date(
      fromDate.getTime() - fromDate.getTimezoneOffset() * 60000
    ).toISOString();

    const localToDate = new Date(
      toDate.getTime() - toDate.getTimezoneOffset() * 60000
    ).toISOString();
    const { data, response } = await post({
      fromDate: localFromDate,
      toDate: localToDate,
    });

    if (response?.ok) {
      setMonthlyArr(data);
    } else {
      setnetworkMsg(data?.messageToUser || "Something went wrong");
      setError(true);
    }
  }, [authFetch, fromDate, hotelId, ids, toDate]);

  useEffect(() => {
    if (!token) {
      return;
    }
    async function fetchData() {
      setLoading(true);
      fetchRevData();
      await fetchOccData();
      await fetchARRData();
      setLoading(false);
    }
    fetchData();
  }, [fetchARRData, fetchOccData, fetchRevData, token]);

  function handleCloseStatus() {
    setOpen(false);
  }
  return {
    monthlyBookings,
    setMonthlyBookings,
    open,
    setOpen,
    setnetworkMsg,
    handleCloseStatus,
    Loading,
    networkMsg,
    Error,
    ids,
    setSelectedIds,
    hotelOptions,
  };
}
