import {
  TableContainer,
  Table as TB,
  TextField as TF,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { DatePicker, getDateDDMMYYYY } from "../../sdk";
import { styled } from "@mui/system";
import { getDateDDMM, getDay } from "../../sdk";
import {
  DATE,
  DAY,
  Grid,
  HeaderCard,
  HeaderCardBottom,
  Label,
  MonthCard,
  MonthCardWrapper,
  VarianceCard,
  Wrapper,
} from "./Styles";

const Table = styled(TB)`
  margin: auto;
  font-family: Roboto;
  font-size: 16px;
  line-height: 1.25;
`;

const Th = styled(TableCell)`
  &.MuiTableCell-head {
    vertical-align: bottom;
    font: normal normal bold 16px/20px Roboto;
    color: white;
    background-color: #306fbc;
    border: none;
    text-align: center;
    padding: 10px 0px;
  }
`;
const Tfoot = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: white;
    background-color: #306fbc;
    max-height: 10px;
    padding: 0 16px;
    text-align: center;
    max-width: 100px;
    width: 70px;
    height: 36px;
  }
`;
const Td = styled(TableCell)`
  &.MuiTableCell-body {
    font: normal normal normal 16px/20px Roboto;
    color: #333333;
    border-bottom: 0.5px solid #e0e0e0;
    max-height: 10px;
    padding: 0 16px;
    text-align: center;
    max-width: 100px;
    width: 70px;
    height: 36px;
  }
`;

const Tr = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(48, 81, 221,0.09)",
  },
}));

const Thead = styled(TableHead)``;
const Tbody = styled(TableBody)``;

const StyledWrapper = styled(Wrapper)(() => ({
  ".root": {
    width: "100%",
  },
  ".container": {
    minWidth: 450,
  },
  ".input": {
    font: "normal normal bold 16px / 20px Roboto",
    color: "#281E53",
    margin: 0,
  },
  ".arrow": {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  ".rowHover": {
    "&:hover": {
      color: "red",
      backgroundColor: "rgba(48, 81, 221,0.4) !important",
    },
  },
}));
export default function PaceCalculation({
  fromDate1,
  toDate1,
  fromDate2,
  toDate2,
  monthlyBookings,
  variance,
  selectedPickup,
  fromDatePeriod1,
  handleFromDateChangePeriod1,
  toDatePeriod1,
  handleToDateChangePeriod1,
  pickupPeriod1,
  handlePickupPeriod1,
  fromDatePeriod2,
  handleFromDateChangePeriod2,
  toDatePeriod2,
  handleToDateChangePeriod2,
  pickupPeriod2,
  handlePickupPeriod2,
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <StyledWrapper>
      <MonthCardWrapper>
        {monthlyBookings.map((monthList, index) => (
          <MonthCard key={index}>
            {index === 0 ? (
              <Grid>
                <HeaderCardBottom style={{ paddingLeft: "20px" }}>
                  <Label>For Period</Label>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    minWidth={"108px"}
                    date={fromDatePeriod1}
                    onChange={(date) => {
                      handleFromDateChangePeriod1(date);
                    }}
                    disabled
                  />
                </HeaderCardBottom>

                <HeaderCardBottom>
                  <Label>To</Label>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    minWidth={"150px"}
                    date={toDatePeriod1}
                    onChange={(date) => {
                      handleToDateChangePeriod1(date);
                    }}
                  />
                </HeaderCardBottom>
                <HeaderCardBottom>
                  <Label>Days</Label>
                  <TF
                    type="number"
                    variant="standard"
                    value={pickupPeriod1}
                    onChange={(e) => {
                      handlePickupPeriod1(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "40px" }}
                    InputProps={{
                      className: "input",
                      disableUnderline: true,
                      inputProps: { min: 1, max: 100 },
                    }}
                  />
                </HeaderCardBottom>
              </Grid>
            ) : (
              <Grid>
                <HeaderCardBottom style={{ paddingLeft: "20px" }}>
                  <Label>Compare with</Label>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    minWidth={"108px"}
                    date={fromDatePeriod2}
                    onChange={(date) => {
                      handleFromDateChangePeriod2(date);
                    }}
                    disabled
                  />
                </HeaderCardBottom>

                <HeaderCardBottom>
                  <Label>To</Label>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    minWidth={"150px"}
                    date={toDatePeriod2}
                    onChange={(date) => {
                      handleToDateChangePeriod2(date);
                    }}
                  />
                </HeaderCardBottom>
                <HeaderCardBottom>
                  <Label>Days</Label>
                  <TF
                    type="number"
                    variant="standard"
                    value={pickupPeriod2}
                    onChange={(e) => {
                      handlePickupPeriod2(e.target.value);
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ width: "40px" }}
                    InputProps={{
                      className: "input",
                      disableUnderline: true,
                      inputProps: { min: 1, max: 100 },
                    }}
                  />
                </HeaderCardBottom>
              </Grid>
            )}
            <TableContainer className="container" sx={{ maxHeight: "90%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ width: "100%" }}
              >
                <Thead>
                  <Tr>
                    <Th>Date</Th>
                    {index === 0 ? (
                      <Th>{getDateDDMMYYYY(fromDate1)}</Th>
                    ) : (
                      <Th>{getDateDDMMYYYY(fromDate2)}</Th>
                    )}
                    {index === 0 ? (
                      <Th>{getDateDDMMYYYY(toDate1)}</Th>
                    ) : (
                      <Th>{getDateDDMMYYYY(toDate2)}</Th>
                    )}
                    <Th>Pickup</Th>
                    {selectedPickup === "occPickup" && <Th>Occ%</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {monthList.map((monthDate, idx) => (
                    <Tr
                      key={idx}
                      style={idx % 2 === 0 ? { backgroundColor: "white" } : {}}
                    >
                      {monthList.length - 1 !== idx && (
                        <Td>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <DATE>
                              <div style={{ width: "40px" }}>
                                {getDateDDMM(monthDate.date)}
                              </div>
                              <DAY>{getDay(monthDate.date)}</DAY>
                            </DATE>
                          </div>
                        </Td>
                      )}
                      {monthList.length - 1 !== idx && (
                        <Td>
                          {monthDate.fromActuals === -1 ? (
                            "-"
                          ) : (
                            <p
                              style={
                                monthDate.fromActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {Math.round(monthDate.fromActuals)}
                            </p>
                          )}
                        </Td>
                      )}
                      {monthList.length - 1 !== idx && (
                        <Td>
                          {monthDate.toActuals === -1 ? (
                            "-"
                          ) : (
                            <p
                              style={
                                monthDate.toActuals < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {Math.round(monthDate.toActuals)}
                            </p>
                          )}
                        </Td>
                      )}
                      {monthList.length - 1 !== idx && (
                        <Td>
                          {monthDate.fromActuals === -1 ||
                          monthDate.toActuals === -1 ? (
                            "-"
                          ) : (
                            <p
                              style={
                                monthDate.pickup < 0
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {Math.round(monthDate.pickup)}
                            </p>
                          )}
                        </Td>
                      )}

                      {monthList.length - 1 !== idx &&
                        selectedPickup === "occPickup" && (
                          <Td
                            style={
                              monthDate.occupancyColor
                                ? {
                                    background: `linear-gradient(${monthDate.occupancyColor},#FFFFFF)`,
                                  }
                                : {}
                            }
                          >
                            {monthDate.toOccupancy === -1
                              ? "-"
                              : `${parseFloat(monthDate.toOccupancy).toFixed(
                                  2
                                )}%`}
                          </Td>
                        )}
                      {monthList.length - 1 === idx && (
                        <Tfoot>
                          <b>Total</b>
                        </Tfoot>
                      )}
                      {monthList.length - 1 === idx && (
                        <Tfoot>
                          <b>
                            <p
                              style={
                                monthDate.totalFromActual < -1
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.totalFromActual === -1
                                ? "-"
                                : Math.round(monthDate.totalFromActual) + 1}
                            </p>
                          </b>
                        </Tfoot>
                      )}
                      {monthList.length - 1 === idx && (
                        <Tfoot>
                          <b>
                            <p
                              style={
                                monthDate.totalToActual < -1
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.totalToActual === -1
                                ? "-"
                                : Math.round(monthDate.totalToActual) + 1}
                            </p>
                          </b>
                        </Tfoot>
                      )}
                      {monthList.length - 1 === idx && (
                        <Tfoot>
                          <b>
                            <p
                              style={
                                monthDate.totalPickup < -1
                                  ? {
                                      color: `red`,
                                    }
                                  : {}
                              }
                            >
                              {monthDate.totalPickup === -1
                                ? "-"
                                : Math.round(monthDate.totalPickup) + 1}
                            </p>
                          </b>
                        </Tfoot>
                      )}
                      {monthList.length - 1 === idx &&
                        selectedPickup === "occPickup" && (
                          <Tfoot>
                            <b>
                              {monthDate.totalOccu === -1
                                ? "-"
                                : monthDate.totalOccu}
                            </b>
                          </Tfoot>
                        )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </MonthCard>
        ))}
      </MonthCardWrapper>
      <VarianceCard>
        <Grid>
          <HeaderCard>
            <div style={{ visibility: "hidden", paddingBottom: "10px" }}>
              Placeholder
            </div>
          </HeaderCard>
        </Grid>
        <TableContainer sx={{ maxHeight: "90%" }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ width: "100%" }}
          >
            <Thead>
              <Tr>
                <Th>Variance</Th>
              </Tr>
            </Thead>
            <Tbody>
              {variance?.map((data, idx) => (
                <Tr
                  key={idx}
                  style={idx % 2 === 0 ? { backgroundColor: "white" } : {}}
                >
                  <Td>
                    <p
                      style={
                        data < 0
                          ? {
                              color: `red`,
                            }
                          : {}
                      }
                    >
                      {variance.length - 1 === idx ? (
                        <b>{!isNaN(data) ? Math.round(data) : data}</b>
                      ) : (
                        <>{!isNaN(data) ? Math.round(data) : data}</>
                      )}
                    </p>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </VarianceCard>
    </StyledWrapper>
  );
}
