import React, {useCallback, useEffect, useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle as DT, FormControl as FC, IconButton, Snackbar, SnackbarContent, Stack, TextField, Typography } from "@mui/material"
import { styled } from "@mui/system";
import { useAuth } from "../sdk";
import { Close } from "@mui/icons-material";

const DialogTitle = styled(DT)(()=>({
    color: "#306FBC",
    textAlign: "center",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19.36px",
}))

const FormControl = styled(FC)(()=>({
    direction: "row",
    width:"calc(100% - 100px)",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px"
}))

const ListContainer = styled(Stack)(()=>({
    paddingTop: 8,
    maxHeight: "200px",
    overflowY: "auto",
    gap: 16
}))

const TextKeyword = styled(Typography)(()=>({
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "16.41px",
}))

const ExcludeKeywords = ({getEventDetails})=>{
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState("")
    const {
      authFetch,
      setCloneMessage,
    } = useAuth();
    const [formData, setFormData] = useState({keyword: "", error: {keyword: ""}, list: []})

    const handleChange=useCallback(({name, value})=>{
        setFormData((data)=>({
          ...data, 
          [name]: value, 
          error: { [name]: value==="" ? "Empty keyword" : data.list.some((keyword)=>keyword.toLowerCase() === value.toLowerCase()) ? "Keyword Exist" : ""} 
        }))
    },[setFormData])
    const getList = useCallback(async()=>{
      const { get } = await authFetch({
        path: `/get-excluded-keyword`,
      });
      try{
        const { data } = await get();
        if(data){
            setFormData((prev)=>({...prev, keyword: "", list: data}))
        }else{
          setCloneMessage(data.messageToUser??"Unable to get keywords")
        }
      }catch(error){
        setCloneMessage(error.messageToUser??"Unable to get keywords")
      }
    },[authFetch, setCloneMessage])

    const submitKeyword = useCallback(async(keyword)=>{
      const { post } = await authFetch({
        path: `/exclude-keyword`,
      });
      try{
        const { data } = await post({keyword});
        if(data){
          setSuccess(data)
          getList();
          setOpen(false)
          getEventDetails();
        }else{
          setCloneMessage(data.messageToUser??"Unable to add keyword")
        }
      }catch(error){
        setCloneMessage(error.messageToUser??"Unable to add keyword")
      }
    },[authFetch, getEventDetails, getList, setCloneMessage])

    useEffect(()=>{
        getList();
    },[getList])

    return <>
            <Button
                onClick={()=>{
                  setOpen(true)
                  setFormData((prev)=>({...prev, keyword: "", error: {keyword: ""}}))
                }}
                className="eventButton"
            >
                Exclude Keywords
            </Button>

            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={success!==""}
              autoHideDuration={3000}
              onClose={() => {
                setSuccess("");
              }}
            >
              <SnackbarContent
                style={{
                  backgroundColor:"#228B22"
                }}
                message={success}
              />
            </Snackbar>
            <Dialog open={open} onClose={()=>{setOpen(false)}}>
                <DialogTitle>Exclude Keyword</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={()=>{setOpen(false)}}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                  }}
                >
                  <Close />
                </IconButton>
                <DialogContent>
                    <Stack width={400} marginY={1} gap={2} maxWidth="100%" direction="row" alignItems="flex-start">
                        <FormControl>
                            <TextField 
                                size="small" 
                                id="outlined-basic" 
                                label="Keyword" 
                                name="keyword" 
                                value={formData.keyword}
                                placeholder="Type keyword here" 
                                variant="outlined" 
                                error={formData.error.keyword !== ""}
                                helperText={formData.error.keyword}
                                onChange={({target})=>{handleChange(target)}}
                            />
                        </FormControl>
                        <Button onClick={()=>{submitKeyword(formData.keyword)}} sx={{width: 80, height: 40}} disabled={formData.error.keyword !== "" || formData.keyword === ""} variant="contained" size="small">Add New</Button>
                    </Stack>
                    <ListContainer>
                        {formData.list.map((keyword,index)=>{
                            return <TextKeyword key={keyword+index}>
                                {keyword}
                            </TextKeyword>
                        })}
                    </ListContainer>
                </DialogContent>
            </Dialog>
        </>
}

export default ExcludeKeywords