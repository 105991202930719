import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTable } from "react-table";
import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { StaticTableRow } from "./StaticTableRow";
import { DraggableTableRow } from "./DraggableTableRow";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";

const columns = [
  { Header: "Sequence", accessor: "sequence", width: "15%" },
  { Header: "Display Key", accessor: "systemName", width: "15%" },
  { Header: "PMS Name", accessor: "pmsName", width: "15%" },
  { Header: "Description", accessor: "description", width: "25%" },
];
export default function DragableTable({
  data,
  setData,
  handleChange,
  disable,
  onDragEnd,
  reorder,
  setDeleteData,
  setDeleteIndex,
  handleDeleteModal,
}) {
  const [activeId, setActiveId] = useState();
  const items = useMemo(() => data?.map(({ sequence }) => sequence), [data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setData((data) => {
        const newData = [...data];
        const activeIndex = newData.findIndex(
          (item) => item.sequence === active.id
        );
        const overIndex = newData.findIndex(
          (item) => item.sequence === over.id
        );

        if (activeIndex !== -1 && overIndex !== -1) {
          const newItems = reorder(
            data,
            activeIndex,
            overIndex,
            data?.[activeIndex],
            data?.[overIndex]
          );
          return newItems;
        }
      });
    }

    setActiveId(null);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  const selectedRow = useMemo(() => {
    if (!activeId) {
      return null;
    }
    const row = rows.find(({ original }) => original.sequence === activeId);
    prepareRow(row);
    return row;
  }, [activeId, rows, prepareRow]);

  return (
    <div
      style={{
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <DndContext
        sensors={sensors}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <Table sx={{ width: "100%" }} {...getTableProps()}>
          <TableHead sx={{ backgroundColor: "rgb(48,111,188)" }}>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{ width: "100%" }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{
                      width: column?.width,
                      color: "white",
                      fontWeight: "700",
                      textAlign: "center",
                    }}
                    {...column.getHeaderProps()}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}

                <TableCell width="20%">
                  <Stack alignItems="end">
                    <Tooltip
                      title={
                        <Typography textAlign="center">
                          Please drag and drop the items in order to change the
                          sequence
                        </Typography>
                      }
                    >
                      <InfoIcon sx={{ color: "#fff" }} />
                    </Tooltip>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            <SortableContext
              items={items}
              strategy={verticalListSortingStrategy}
            >
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <DraggableTableRow
                    key={row.original.id}
                    row={row}
                    handleChange={handleChange}
                    disable={disable}
                    onDragEnd={ondragend}
                    setDeleteData={setDeleteData}
                    setDeleteIndex={setDeleteIndex}
                    handleDeleteModal={handleDeleteModal}
                  />
                );
              })}
            </SortableContext>
          </TableBody>
        </Table>

        <DragOverlay>
          {activeId && (
            <Table sx={{ width: "100%" }}>
              <TableBody>
                <StaticTableRow row={selectedRow} />
              </TableBody>
            </Table>
          )}
        </DragOverlay>
      </DndContext>
    </div>
  );
}
