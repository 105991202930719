import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CalendarToday } from "@mui/icons-material";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { styled } from "@mui/system";
import {
  ChooseBtn,
  Cross,
  CrossSmall,
  DateSection,
  Done,
  Filename,
  Label,
  ModelHead,
  Section,
  Title,
  UploadSection,
} from "./Styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

function getModalStyle() {
  const top = 9;
  const right = 18;

  return {
    top: `${top}%`,
    right: `${right}%`,
    transform: `translate(-${top}, -${right})`,
  };
}

export const CalenderIcon = styled(CalendarToday)`
  position: absolute;
  width: 20px;
  height: 22px;
  color: #306fbc;
  z-index: 101;
  margin-left: -32px;
  margin-top: 4px;
  cursor: pointer;
`;

const ClassWrapper = styled(Box)(({ theme }) => ({
  ".paper": {
    position: "absolute",
    width: "370px",
    height: "300px",
    backgroundColor: "#fff",
    boxShadow: 5,
  },
}));

export function ModelBody({
  fileHandler,
  setUploadActualDate,
  uploadActualDate,
  fileGetter,
  setOpenSelectBar,
  removeFile,
  filename,
  toggle,
  setDateToggle,
  dateToggle,
}) {
  const [modalStyle] = useState(getModalStyle);
  const defaultValue = new Date();
  const maximumDate = new Date();
  const [selectedDay, setSelectedDay] = useState(defaultValue);

  useEffect(() => {
    if (selectedDay) {
      setUploadActualDate(
        new Date(
          [
            selectedDay?.getFullYear(),
            selectedDay?.getMonth()+1,
            selectedDay?.getDate().length === 1
              ? "0" + selectedDay?.getDate()
              : selectedDay?.getDate(),
          ].join("-")
        )
      );
    }
  }, [selectedDay]);

  const StyledCalenderWrapper = styled(Box)((theme) => ({
    borderRadius: "8px",
    boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
    "&:hover>*": {
      color: "#ffffff",
      ">input": {
        backgroundColor: "#306fbc",
        color: "#ffffff !important",
      },
    },
  }));

  return (
    <ClassWrapper>
      <Section style={modalStyle} className="paper">
        <ModelHead>
          <Title>Upload Forecast</Title>
          <Cross onClick={() => setOpenSelectBar(false)}>&times;</Cross>
        </ModelHead>
        <DateSection>
          <Label htmlFor="picker" style={{ marginLeft: "40px" }}>
            Date
          </Label>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack className="dateStack">
              <Box className="dateBox">
                  <StyledCalenderWrapper>
            <DatePicker
                  inputFormat={"dd/MM/yyyy"}
                  style={{ zIndex: 98 }}
                  value={selectedDay}
                  onChange={
                    setSelectedDay
                  }
                  renderInput={({ inputRef, inputProps, InputProps }) => <Box 
                  className="my-custom-input-class" sx={{ display: 'flex', position: "relative", alignItems: 'center', justifyContent: "flex-start" }}>
                  <input
                    ref={inputRef}
                    {...inputProps}
                    readOnly
                    style={{
                      textAlign: "left",
                      marginBottom: "2px",
                      fontSize: "14px",
                      borderRadius: "10px",
                      color: "#000000",
                      outline: "none",
                      height: "30px",
                      border: "none",
                      font: "bold 16px / 20px Roboto",
                      cursor: "pointer",
                      width: "140px",
                      paddingLeft: "14px",
                    }}
                  />
                  <Box className="date-button-container" sx={{position: "absolute", right: 0, width:"100%"}}>
                  {InputProps?.endAdornment}
                  </Box>
                </Box>}
                maxDate={maximumDate}
                />
              </StyledCalenderWrapper>
              </Box>
            </Stack>
          </LocalizationProvider>
        </DateSection>
        <ChooseBtn
          style={toggle ? { display: "none" } : { display: "block" }}
          htmlFor="upload"
        >
          Choose File
          <input
            type="file"
            id="upload"
            onChange={(e) => fileGetter(e.target.files)}
            style={{ display: "none" }}
          />
        </ChooseBtn>

        <UploadSection
          style={toggle ? { display: "flex" } : { display: "none" }}
        >
          <img
            src="/assets/excel.svg"
            alt="Excel Icon"
            width="36px"
            height="36px"
          />
          <Filename>{filename}</Filename>
          <CrossSmall
            onClick={() => removeFile()}
            style={toggle ? { display: "block" } : { display: "none" }}
          >
            &times;
          </CrossSmall>
        </UploadSection>
        <Done
          disabled={!toggle}
          next
          onClick={fileHandler}
          type="button"
          style={{
            background: "#306fbc 0% 0% no-repeat padding-box",
            color: " #ffffff",
            cursor: "pointer",
          }}
        >
          Upload
        </Done>
      </Section>
    </ClassWrapper>
  );
}
