import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationDialog from "./ConfirmationDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useAuth } from "../../sdk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { TextField } from "../../PaceAnalysis/components/Styles";
import { useForm, useWatch } from "react-hook-form";
import { LoadingButton } from "@mui/lab";

const initialValue = {
  pushRatesTable: [],
};
export default function PushRatesDialog({
  open,
  handleClose,
  pushRateFromDate,
  setPushRateFromDate,
  pushRateToDate,
  setPushRateToDate,
  pushRatesToChannel,
  updatePushRate,
  recommendedData,
  discount,
  currentHotel,
  isLoading,
}) {
  const [step, setStep] = useState(2); // 1 for date selection, 2 for push rate !! skip date selection as per requirement
  const [isChecked, setIsChecked] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false
  );

  const [selectedRow, setSelectedRow] = useState(null);

  const minimumDate = new Date();

  const { hotelId, FILEID } = useParams();
  const { authFetch } = useAuth();

  const {
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
    control,
    handleSubmit,
  } = useForm({
    defaultValues: initialValue,
    mode: "all",
  });

  const tableData = useWatch({ name: "pushRatesTable", control });
  const convertDateFormat = (dateStr) => {
    const year = dateStr?.year;
    const month = dateStr?.month?.toString().padStart(2, "0");
    const day = dateStr?.day?.toString().padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const dateformatforTitle = (dateStr) => {
    const year = dateStr?.year;
    const month = dateStr?.month?.toString().padStart(2, "0");
    const day = dateStr?.day?.toString().padStart(2, "0");

    return `${day}-${month}-${year}`;
  };
  const getTable = useCallback(async () => {
    const { get } = await authFetch({
      path: `/hotel/${hotelId}/get-push-price-details?date=${convertDateFormat(
        pushRateFromDate
      )}&recommendationId=${FILEID}`,
    });
    const { data, error, response } = await get();

    if (response?.ok) {
      const res = data?.[convertDateFormat(pushRateFromDate)];

      setValue("pushRatesTable", res);
    } else {
      console.log(error);
    }
  }, [FILEID, authFetch, hotelId, pushRateFromDate, setValue]);

  useEffect(() => {
    if (pushRateFromDate) {
      setPushRateToDate(pushRateFromDate);
    }
  }, [pushRateFromDate, setPushRateToDate]);

  const handleCheckboxChange = useCallback(
    (event) => {
      if (event.target.checked) {
        const lastDay = new Date(
          pushRateFromDate.year,
          pushRateFromDate.month,
          0
        ); // Get last day of the month
        setPushRateToDate({ ...pushRateFromDate, day: lastDay.getDate() });
      }
    },
    [pushRateFromDate, setPushRateToDate]
  );
  const handleRateComparisonWithMidweekRates = useCallback(
    (value, index) => {
      const date = new Date(
        pushRateFromDate.year,
        pushRateFromDate.month - 1,
        pushRateFromDate.day
      );
      const tableRowData = tableData[index];
      const indexOfDay = date.getDay();
      let minThreshold, maxThreshold;

      if (indexOfDay && currentHotel?.midWeek?.includes(indexOfDay)) {
        minThreshold = tableRowData?.minThresholdWeekday;
        maxThreshold = tableRowData?.MaxThresholdWeekday;
      } else {
        minThreshold = tableRowData?.minThresholdWeekend;
        maxThreshold = tableRowData?.maxThresholdWeekend;
      }

      if (Number(value) < minThreshold) {
        return `Price should be more than ${minThreshold}`;
      }
      if (Number(value) > maxThreshold) {
        return `Price should be less than ${maxThreshold}`;
      }

      return null; // No error
    },
    [currentHotel, pushRateFromDate, tableData]
  );

  const handleChangePushRate = useCallback(
    (event, index) => {
      const { value } = event.target;
      const positiveNumberRegex = /^(?:[0-9]+(?:\.[0-9]*)?|\.[0-9]+)?$/;

      if (positiveNumberRegex.test(value)) {
        const errorMessage = handleRateComparisonWithMidweekRates(value, index);

        if (errorMessage) {
          setError(`pushRatesTable.${index}.pushPrice`, {
            type: "custom",
            message: errorMessage,
          });
        } else {
          clearErrors(`pushRatesTable.${index}.pushPrice`);
        }

        setValue(`pushRatesTable.${index}.pushPrice`, Number(value));
      }
    },
    [clearErrors, handleRateComparisonWithMidweekRates, setError, setValue]
  );
  const handleRowSelect = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
    // setSelectedCell(null); // Clear cell selection when selecting a row
  };

  const tableColumns = [
    { key: "roomName", label: "Room Type", columnStyle: { minWidth: "200px" } },
    { key: "inventory", label: "Rooms left to sell" },
    // { key: "roomId", label: "Room ID" },
    { key: "currentPrice", label: "Current Price in  CM" },
    {
      key: "pushPrice",
      label: "Recommended Price",
      value: (data) => Math.round(data),
    },
    {
      key: "discount",
      label: "Promotional Discount",
      value: (data) => data + "%",
    },
    {
      key: "pushPrice",
      label: "Override Price",
      value: (data, index) => (
        <TextField
          variant="standard"
          fullWidth
          value={
            watch(`pushRatesTable.${index}`)?.pushPrice
              ? Math.round(watch(`pushRatesTable.${index}`)?.pushPrice)
              : null
          }
          sx={{
            minWidth: "60px",
          }}
          error={!!errors?.pushRatesTable?.[index]?.pushPrice}
          helperText={
            errors?.pushRatesTable?.[index]?.pushPrice
              ? errors?.pushRatesTable?.[index]?.pushPrice?.message
              : ""
          }
          inputProps={{
            style: {
              fontSize: "10px",
              textAlign: "center",
            },
          }}
          onChange={(e) => handleChangePushRate(e, index)}
          type="number"
        />
      ),
    },
    { key: "addToBasePrice", label: "Add to Base Price" },
    // { key: "MaxThresholdWeekday", label: "Max Threshold Weekday" },
    // { key: "minThresholdWeekday", label: "Min Threshold Weekday" },
    // { key: "maxThresholdWeekend", label: "Max Threshold Weekend" },
    // { key: "minThresholdWeekend", label: "Min Threshold Weekend" },
  ];
  const onSave = useCallback(
    (values) => {
      updatePushRate(values, pushRateFromDate);
    },
    [pushRateFromDate, updatePushRate]
  );
  useEffect(() => {
    getTable();
  }, [getTable]);

  return (
    <StyledChannelDialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle className="content">
        <Typography variant="body1">
          Push Rates to Channel manager ({dateformatforTitle(pushRateFromDate)})
        </Typography>
      </DialogTitle>

      <DialogContent className="content">
        {step === 1 && (
          <Stack gap="24px" alignItems="center">
            <Stack direction="row" gap="40px">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledCalenderWrapper>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    style={{ zIndex: 98 }}
                    value={pushRateFromDate}
                    onChange={(date) => {
                      setPushRateFromDate(date);
                      if (pushRateToDate < date) {
                        setPushRateToDate(date);
                      }
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        className="my-custom-input-class"
                        sx={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          readOnly
                          style={{
                            textAlign: "left",
                            marginBottom: "2px",
                            fontSize: "14px",
                            borderRadius: "10px",
                            color: "#000000",
                            outline: "none",
                            height: "30px",
                            border: "none",
                            font: "bold 16px / 20px Roboto",
                            cursor: "pointer",
                            width: "140px",
                            paddingLeft: "14px",
                          }}
                        />
                        <Box
                          className="date-button-container"
                          sx={{ position: "absolute", right: 0, width: "100%" }}
                        >
                          {InputProps?.endAdornment}
                        </Box>
                      </Box>
                    )}
                    minDate={minimumDate}
                  />
                </StyledCalenderWrapper>
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledCalenderWrapper>
                  <DatePicker
                    inputFormat={"dd/MM/yyyy"}
                    style={{ zIndex: 98 }}
                    value={pushRateToDate}
                    onChange={setPushRateToDate}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box
                        className="my-custom-input-class"
                        sx={{
                          display: "flex",
                          position: "relative",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <input
                          ref={inputRef}
                          {...inputProps}
                          readOnly
                          style={{
                            textAlign: "left",
                            marginBottom: "2px",
                            fontSize: "14px",
                            borderRadius: "10px",
                            color: "#000000",
                            outline: "none",
                            height: "30px",
                            border: "none",
                            font: "bold 16px / 20px Roboto",
                            cursor: "pointer",
                            width: "140px",
                            paddingLeft: "14px",
                          }}
                        />
                        <Box
                          className="date-button-container"
                          sx={{ position: "absolute", right: 0, width: "100%" }}
                        >
                          {InputProps?.endAdornment}
                        </Box>
                      </Box>
                    )}
                    minDate={pushRateFromDate}
                  />
                </StyledCalenderWrapper>
              </LocalizationProvider>
            </Stack>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#163A90",
                    "&.Mui-checked": {
                      color: "#163A90",
                    },
                  }}
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                    handleCheckboxChange(e);
                  }}
                />
              }
              label="Select complete month"
            />

            <Button
              variant="contained"
              onClick={() => {
                setStep(2);
              }}
            >
              Next
            </Button>
          </Stack>
        )}
        {step === 2 && (
          <>
            <Box
              sx={{ maxHeight: "500px", overflow: "auto", mb: "8px" }}
              component="form"
            >
              <Table>
                <TableHead>
                  <StyledTableRow>
                    {tableColumns.map((column) => (
                      <HeaderTableCell
                        key={column.key}
                        sx={column?.columnStyle}
                      >
                        {column.label}
                      </HeaderTableCell>
                    ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((data, index) => (
                    <StyledTableRow
                      key={index}
                      onSelect={(e) => {
                        handleRowSelect(index);
                      }}
                      sx={{
                        backgroundColor:
                          selectedRow === index
                            ? "rgba(0, 0, 0, 0.04)"
                            : "inherit",

                        border:
                          selectedRow === index
                            ? "2px solid #1976d2;"
                            : "inherit",
                      }}
                    >
                      {tableColumns.map((column) => (
                        <CustomTableCell key={column.key}>
                          {column?.value
                            ? column?.value(data?.[column.key], index)
                            : data?.[column.key]}{" "}
                          {/* {data[column.key] === "discount" && "%"} */}
                        </CustomTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              type="submit"
              disabled={errors?.pushRatesTable || isLoading}
              onClick={handleSubmit(onSave)}
            >
              Submit
            </LoadingButton>
          </>
        )}
      </DialogContent>

      <CloseIcon className="closeButton" onClick={handleClose} />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        handleCloseConfirmation={() => {
          setIsConfirmationDialogOpen(false);
          handleClose();
        }}
        pushRatesToChannel={() => pushRatesToChannel(pushRateFromDate)}
        tableData={tableData}
      />
    </StyledChannelDialog>
  );
}

const StyledChannelDialog = styled(Dialog)(() => ({
  ".MuiPaper-root": {
    overflow: "visible",
  },
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    padding: "7px 24px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "visible",
  },
}));

const StyledCalenderWrapper = styled(Box)((theme) => ({
  borderRadius: "8px",
  boxShadow: "0px 4px 4px rgba(48, 111, 188, 0.2)",
  "&:hover>*": {
    color: "#ffffff",
    ">input": {
      backgroundColor: "#306fbc",
      color: "#ffffff !important",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.white,
    // color: "#130453",
    backgroundColor: "#306fbc",
    color: "#ffffff",
    textAlign: "center",
    font: "normal normal bold 16px/20px Roboto",
    padding: "14px",
    letterSpacing: "0px",
    width: "25%",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: "center",
    width: "25%",
  },
}));
const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#306fbc",
  color: theme.palette.common.white,
  padding: "4px",
  textAlign: "center",
  borderRight: "1px solid #444",
  [`&.${tableCellClasses.head}`]: {
    fontSize: "12px",
  },
}));

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  padding: "6px",
  textAlign: "center",
  borderBottom: "1px solid #eee",
  borderRight: "1px solid #eee",
  borderTop: "1px solid #eee",
  color: "#555",
  [`&.${tableCellClasses.body}`]: {
    fontSize: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme, selected }) => ({
  backgroundColor: theme.palette.common.white,
  "&:hover,&:focus": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    cursor: "pointer",

    "& > td": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  ...(selected && {
    backgroundColor: "rgba(25, 118, 210, 0.16)",
    border: "2px solid red",
    "& > td": {
      backgroundColor: "rgba(25, 118, 210, 0.16)",
    },
  }),
}));
