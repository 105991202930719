import { Box } from "@mui/material";
import React from "react";
import { LocalizationProvider, DatePicker as DP } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export function DatePicker({
  date,
  onChange,
  disabled,
  ...props
}) {
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DP
          style={{ zIndex: 98 }}
          value={date}
          disabled={disabled}
          onChange={onChange}
          {...props}
          renderInput={({ inputRef, inputProps, InputProps }) => <Box 
          sx={{ display: 'flex', position: "relative", alignItems: 'center', justifyContent: "flex-start" }}>
            <input
              ref={inputRef}
              {...inputProps}
              readOnly
              style={{
                textAlign: "left",
                marginBottom: "2px",
                fontSize: "14px",
                borderRadius: "10px",
                color: disabled? "inherit" :"#000000",
                outline: "none",
                height: "30px",
                border: "none",
                font: "bold 16px / 20px Roboto",
                cursor: "pointer",
                width: "140px",
                paddingLeft: "14px",
              }}
            />
            <Box className={disabled ? "disabled-date-button-container" : "date-button-container"} sx={{position: "absolute", right: 0, width:"100%"}}>
              {InputProps?.endAdornment}
            </Box>
          </Box>}
          />
      </LocalizationProvider>
    </div>
  );
}
