import {
  DndContext,
  useDraggable,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  KeyboardCoordinateGetter,
  MouseSensor,
  TouchSensor,
  DragOverlay,
  defaultDropAnimationSideEffects,
  DropAnimation,
  closestCenter,
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DeleteSweep } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Button,
  Box as BX,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  SnackbarContent,
  Stack,
  TextField as TFNew,
  Tooltip,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/system";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ErrorPage, GhostButton, PrimaryButton, useAuth } from "../../sdk";
import { useCompetitor } from "../hooks/useCompetitor";
import {
  Competitors,
  Cross,
  Fields,
  Label,
  RatingContainer,
  TextField as TF,
} from "./Styles";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
export const DeleteIcon = styled(DeleteSweep)`
  color: #306fbc;
  cursor: pointer;
`;
export const StarLabelNew = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #000000;
  padding-left: 16px;
`;
export const CompetitorsTextField = styled(TFNew)`
  &.MuiFormControl-root {
    margin-right: 0;
    width: 18%;
    margin: 0 16px;
    color: #757575;
  }
  &.MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      backgroundcolor: red;
    }
  }

  label {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
    height: 24px;
    // transform-origin: center;
  }
  label.MuiFormLabel-root.Mui-focused {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #000000;
  }
  input {
    font-family: Roboto;
    letter-spacing: 0px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #110641;
    text-align: left;
    background: #ffffff;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    box-shadow: 0 0 0 30px white inset !important;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: #110641 !important;
  }
`;
export const Field = styled(TF)`
  text-decoration: none;
  &.MuiFormControl-root {
    margin-right: 40px;
    width: 50%;
    margin-bottom: 0px;
  }
  label {
    font: normal normal bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    padding-left: 6px;
    width: 100px;
    min-width: 50px;
    color: #0a0a0a;
  }
  label.MuiFormLabel-root.Mui-focused {
    font: Bold 16px/20px Roboto;
    letter-spacing: 0px;
    color: #0a0a0a;
  }
  input,
  .MuiAutocomplete-inputRoot[class*="MuiInput-root"]
    .MuiAutocomplete-input:first-child {
    font: normal normal normal 16px/20px Roboto;
    letter-spacing: 0px;
    color: #110641;
    min-width: 100px;
    background-color: rgba(200, 200, 244, 0.4);
    padding-left: 8px;
    margin-top: 4px;
  }

  &.invalid {
    label {
      color: red;
    }
    label.MuiFormLabel-root.Mui-focused {
      color: red;
    }
  }
`;

export const ButtonNew = styled(GhostButton)`
  &&& {
    width: auto;
    border: none;
    background-color: transparent;
    color: #306fbc;
    padding: 5px 10px;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      background-color: #f7f7f7;
      border-radius: 2px;
      color: #306fbc;
    }
    &:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: ${({ disabled }) => (disabled ? "#6f6f6f" : "#ffffff")};
    }

    &:active {
      background-color: ${({ disabled }) =>
        disabled ? "transparent" : "#f1f2fe"};
    }
  }
`;

export const Box = styled(BX)`
  background: #ffffff;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.25);
  border-radius: 4px;
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
`;

export const Text = styled(Typography)`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;
`;

export const CompetitorPageNew = styled(BX)`
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: none;
  padding: 20px;
  outline: none;
  height: calc(100vh - 160px);
  overflow: scroll;
  overflow-x: hidden;
  box-shadow: 1px 4px 10px rgba(48, 111, 188, 0.2);
`;
const AddCompetitorData = ({ rateShopAppEnabled, setRateShopAppEnabled }) => {
  const { hotelId } = useParams();
  const { permission, version } = useAuth();

  const {
    hotelSelect,
    allSources,
    competitorsData,
    setCompetitorsData,
    changeHandler,
    removeHandler,
    addHotelHandler,
    saveCompetitorData,
    handleCloseStatus,
    open,
    networkMsg,
    Error,
    isLoading,
    handleUploadNetwork,
    uploadNetworkMsg,
    reorderCompetitors,
    myError,
    openChannelDialog,
    handleEyeClick,
    handleCloseChannelDialog,
    ischannelSearching,
    selectedCompititor,
    availableChannels,
    CompAvailableChannels,
    zippedArray,
    formEdit,
    setformEdit,
    handleRedirect,
    reorderCompApi,
  } = useCompetitor(hotelId, rateShopAppEnabled, setRateShopAppEnabled);

  const [active, setActive] = useState(null);
  const data = useMemo(
    () => competitorsData?.find((item) => item.sequence === active?.id),
    [active, competitorsData]
  );

  const ratePingExpediaEnabled =
    hotelSelect?.competitorPricingFileTypeID !== 8 || !rateShopAppEnabled;

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  // //api call on competitor set up page
  // useEffect(() => {
  //   async function AllDetailsSetup() {
  //     const { get } = await authFetch({
  //       path: `/hotel/${hotelId}`,
  //     });
  //     const { data, error } = await get();
  //     if (data && !error) {
  //       setHotelSelect(data);
  //       setRateShopAppEnabled(data?.rateShopEnabled);
  //     }
  //   }
  //   AllDetailsSetup();
  // }, [authFetch, hotelId, setRateShopAppEnabled, token]);

  let compDetailsEdit = null,
    editExclusiveDetails = null,
    compDetailsView = null,
    competitorDelete = null;

  for (let key in permission) {
    if (permission.hasOwnProperty(key)) {
      if (permission[key].name === "compDetailsView") {
        compDetailsView = permission[key];
      }
      if (permission[key].name === "compDetailsEdit") {
        compDetailsEdit = permission[key];
      }
      if (permission[key].name === "EditExclusiveDetails") {
        editExclusiveDetails = permission[key];
      }
      if (permission[key].name === "competitorDelete") {
        competitorDelete = permission[key];
      }
    }
  }

  const [askconfirmation, setAskconfirmation] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [competitorList, setCompetitorList] = React.useState("");

  const handleCompetitors = (event) => {
    setCompetitorList(event.target.value);
  };

  const onDragComp = ({ destination, source }) => {
    console.log(source, destination);
    if (destination < 0) return;
    console.log(source, destination);
    const newCompetitors = reorderCompetitors(
      competitorsData,
      source,
      destination,
      competitorsData?.[source],
      competitorsData?.[destination]
    );
    setCompetitorsData([...newCompetitors]);
  };

  return !Error ? (
    <CompetitorPageNew>
      <Dialog open={askconfirmation} onClose={handleCloseChannelDialog}>
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Label> Are you sure you want to delete this Competitor? </Label>
            <Cross onClick={() => setAskconfirmation(false)}>&times;</Cross>
          </div>{" "}
        </DialogTitle>
        <DialogActions
          style={{ justifyContent: "space-evenly", paddingBottom: 16 }}
        >
          <PrimaryButton
            autoFocus
            next
            onClick={() => {
              removeHandler(idToDelete);
              setIdToDelete(null);
              setAskconfirmation(false);
            }}
            style={{ color: "#FFFFFF", background: "#306FBC" }}
          >
            Yes
          </PrimaryButton>
          <PrimaryButton
            style={{ color: "#FFFFFF", background: "#306FBC" }}
            next
            onClick={() => setAskconfirmation(false)}
          >
            No
          </PrimaryButton>
        </DialogActions>
      </Dialog>

      <StyledChannelDialog
        open={openChannelDialog}
        onClose={handleCloseChannelDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle className="content">Available Channels</DialogTitle>

        <CloseIcon className="closeButton" onClick={handleCloseChannelDialog} />

        <DialogContent className="content">
          {ischannelSearching ? (
            <CircularProgress />
          ) : (
            <Stack direction="column" width="100%">
              <Stack
                display="flex"
                flexDirection="row"
                textAlign="left"
                paddingLeft={16}
              >
                <Stack width="100%" textAlign="left">
                  <Typography className="hotelDetail">
                    <strong> Hotel name:</strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.hotelname}
                  </Typography>
                  <Typography>
                    <strong> Address:</strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.address}
                  </Typography>
                  <Typography>
                    <strong> City: </strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.city}
                  </Typography>
                  <Typography>
                    <strong> Postal Code:</strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.zip}
                  </Typography>
                </Stack>
                <Stack width="100%" textAlign="left">
                  <Typography>
                    <strong>State:</strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.state}
                  </Typography>
                  <Typography>
                    <strong>Country:</strong>{" "}
                    {CompAvailableChannels?.HotelDetails?.country}
                  </Typography>
                  <Typography>
                    <strong>Coordinates: </strong>
                    {CompAvailableChannels?.HotelDetails?.lat !== undefined
                      ? Number(CompAvailableChannels.HotelDetails.lat).toFixed(
                          6
                        )
                      : ""}
                    ,{" "}
                    {CompAvailableChannels?.HotelDetails?.lng !== undefined
                      ? Number(CompAvailableChannels.HotelDetails.lng).toFixed(
                          6
                        )
                      : ""}
                  </Typography>

                  <Stack flexDirection="row">
                    <Typography>
                      <strong> Ratings:</strong>
                    </Typography>
                    <RatingContainer>
                      <Rating
                        label="Stars"
                        style={{
                          color: "#306FBC",
                          margin: "0 16px 0 16px",
                        }}
                        value={
                          parseInt(
                            CompAvailableChannels?.HotelDetails?.starrating
                          ) || null
                        }
                      />
                    </RatingContainer>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                width="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Typography className="heading">{`${hotelSelect?.name}'s Selected Channels`}</Typography>
                <Typography className="heading">
                  {`${selectedCompititor}'s available Channels`}
                </Typography>
                <Typography className="heading">
                  {`${hotelSelect?.name}'s available Channels`}
                </Typography>
              </Stack>
              <Stack direction="column" width="100%" alignItems="center">
                {zippedArray?.map((item, index) =>
                  !(
                    item.myHotelSelectedChannels === "-" &&
                    item.myCompAvailableChannels === "-" &&
                    item.myHotelAvailableChannels === "-"
                  ) ? (
                    <Stack className="dataStack" key={index}>
                      <Stack width="100%">
                        <Typography>{item.myHotelSelectedChannels}</Typography>
                      </Stack>
                      <Stack width="100%">
                        <Typography>{item.myCompAvailableChannels}</Typography>
                      </Stack>
                      <Stack width="100%">
                        <Typography>{item.myHotelAvailableChannels}</Typography>
                      </Stack>
                    </Stack>
                  ) : null
                )}
              </Stack>
            </Stack>
          )}
        </DialogContent>
      </StyledChannelDialog>

      <Text>Competitors</Text>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          setActive(active);
        }}
        onDragEnd={({ active, over }) => {
          if (over && active.id !== over?.id) {
            const activeIndex = competitorsData.findIndex(
              ({ sequence }) => sequence === active.id
            );
            const activeId = competitorsData.find(
              ({ sequence }) => sequence === active.id
            ).id;

            const overIndex = competitorsData.findIndex(
              ({ sequence }) => sequence === over.id
            );
            setCompetitorsData(
              arrayMove(competitorsData, activeIndex, overIndex)
            );
            reorderCompApi(activeId, activeIndex + 1, overIndex + 1);
          }

          setActive(null);
        }}
        onDragCancel={() => {
          setActive(null);
        }}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={competitorsData}
          strategy={verticalListSortingStrategy}
        >
          {competitorsData.map((data, index) => (
            <DraggableItem
              id={data?.sequence}
              key={data?.sequence}
              setformEdit={setformEdit}
            >
              <Fields>
                <CompetitorsTextField
                  label="Sequence"
                  sx={{
                    width: "6% !important",
                  }}
                  slotProps={{
                    input: {
                      readOnly: true,
                    },
                  }}
                  value={index + 1}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <CompetitorsTextField
                  label="Display Key"
                  name="displayKey"
                  disabled={compDetailsEdit ? false : true}
                  error={myError && !data.displayKey}
                  onMouseDown={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    changeHandler(index, "displayKey", e.target.value)
                  }
                  value={data.displayKey || ""}
                  style={{ minWidth: "50px" }}
                  InputProps={{ disableUnderline: true }}
                  variant="outlined"
                />
                {hotelSelect?.competitorPricingFileTypeID !== 2 &&
                  hotelSelect?.competitorPricingFileTypeID !== 4 &&
                  hotelSelect?.competitorPricingFileTypeID !== 5 &&
                  hotelSelect?.competitorPricingFileTypeID !== 6 &&
                  hotelSelect?.competitorPricingFileTypeID !== 7 &&
                  ratePingExpediaEnabled && (
                    <CompetitorsTextField
                      label={"Expedia Name"}
                      variant="outlined"
                      disabled={compDetailsEdit ? false : true}
                      error={myError && !data.expediaName}
                      name="expediaName"
                      onMouseDown={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        changeHandler(index, "expediaName", e.target.value)
                      }
                      value={data.expediaName || ""}
                      style={{ minWidth: "50px" }}
                      InputProps={{ disableUnderline: true }}
                    />
                  )}
                {hotelSelect?.competitorPricingFileTypeID === 4 && (
                  <CompetitorsTextField
                    label={"Generic Name"}
                    name="displayKey"
                    disabled={compDetailsEdit ? false : true}
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "displayKey", e.target.value)
                    }
                    value={data.displayKey || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                    variant="outlined"
                  />
                )}
                {hotelSelect?.competitorPricingFileTypeID === 5 && (
                  <CompetitorsTextField
                    label={"OTA Insight Name"}
                    variant="outlined"
                    disabled={compDetailsEdit ? false : true}
                    error={myError && !data.otaName}
                    name="otaName"
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "otaName", e.target.value)
                    }
                    value={data.otaName || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
                {hotelSelect?.competitorPricingFileTypeID === 6 && (
                  <CompetitorsTextField
                    label={"Travel Clicked"}
                    variant="outlined"
                    disabled={compDetailsEdit ? false : true}
                    error={myError && !data.travelClickName}
                    name="travelClickName"
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "travelClickName", e.target.value)
                    }
                    value={data.travelClickName || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
                {hotelSelect?.competitorPricingFileTypeID === 7 && (
                  <CompetitorsTextField
                    required
                    disabled={compDetailsEdit ? false : true}
                    error={myError && !data.rateGainName}
                    label="Rategain Name"
                    variant="outlined"
                    name="rateGainName"
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "rateGainName", e.target.value)
                    }
                    value={data.rateGainName || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}{" "}
                {hotelSelect?.competitorPricingFileTypeID !== 1 &&
                  hotelSelect?.competitorPricingFileTypeID !== 4 &&
                  hotelSelect?.competitorPricingFileTypeID !== 5 &&
                  hotelSelect?.competitorPricingFileTypeID !== 6 &&
                  hotelSelect?.competitorPricingFileTypeID !== 7 &&
                  ratePingExpediaEnabled && (
                    <CompetitorsTextField
                      label="Rateping Name"
                      variant="outlined"
                      disabled={compDetailsEdit ? false : true}
                      error={myError && !data.ratepingName}
                      name="ratepingName"
                      onMouseDown={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                      onChange={(e) =>
                        changeHandler(index, "ratepingName", e.target.value)
                      }
                      value={data.ratepingName || ""}
                      style={{ minWidth: "50px" }}
                      InputProps={{ disableUnderline: true }}
                    />
                  )}{" "}
                <CompetitorsTextField
                  label="Number of rooms"
                  variant="outlined"
                  disabled={compDetailsEdit ? false : true}
                  error={myError && !data.rooms}
                  type="number"
                  name="rooms"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{ minWidth: "10px" }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    changeHandler(index, "rooms", parseInt(e.target.value))
                  }
                  value={data.rooms || ""}
                />
                {rateShopAppEnabled && (
                  <CompetitorsTextField
                    required
                    label="Hotel Rate Metric Code"
                    variant="outlined"
                    disabled={
                      compDetailsEdit && editExclusiveDetails ? false : true
                    }
                    name="rateMetricHotelCode"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{ minWidth: "20px" }}
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(
                        index,
                        "rateMetricHotelCode",
                        parseInt(e.target.value)
                      )
                    }
                    value={data.rateMetricHotelCode || ""}
                  />
                )}
                {rateShopAppEnabled && (
                  <Tooltip title={"Show Channels"}>
                    <IconButton
                      disabled={
                        editExclusiveDetails ? !data?.rateMetricHotelCode : true
                      }
                      onMouseDown={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                      onClick={(e) => handleEyeClick(index)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {hotelSelect?.canViewUpcomingEvents && (
                  <CompetitorsTextField
                    label="Competitor Hotel Location URL"
                    name="locationUrl"
                    disabled={compDetailsEdit ? false : true}
                    onMouseDown={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "locationUrl", e.target.value)
                    }
                    value={data?.locationUrl || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                    variant="outlined"
                  />
                )}
                <RatingContainer key={index}>
                  <StarLabelNew>Star Rating</StarLabelNew>

                  <Rating
                    key={index}
                    disabled={compDetailsEdit ? false : true}
                    name={`${index}`}
                    label="Stars"
                    id={index}
                    style={{
                      color: "#306FBC",
                      margin: "0 16px 0 16px",
                    }}
                    value={parseInt(data.stars) || null}
                    onMouseDown={(e) => e.stopPropagation()}
                    onChange={(e) =>
                      changeHandler(index, "stars", parseInt(e.target.value))
                    }
                  />
                </RatingContainer>
                {competitorDelete && (
                  <Box>
                    <IconButton
                      // disabled={disable}
                      onMouseDown={(e) => e.stopPropagation()}
                      onClick={() => {
                        setAskconfirmation(true);
                        setIdToDelete(data.id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Fields>
            </DraggableItem>
          ))}
        </SortableContext>
        {/* <DragOverlay>
          {activeItem ? activeItem : null}
        </DragOverlay> */}
        {data ? (
          <SortableOverlay>
            <Fields>
              <CompetitorsTextField
                label="Sequence"
                sx={{
                  width: "6% !important",
                }}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={data.sequence}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <CompetitorsTextField
                label="Display Key"
                name="displayKey"
                disabled={compDetailsEdit ? false : true}
                error={myError && !data.displayKey}
                value={data.displayKey || ""}
                style={{ minWidth: "50px" }}
                InputProps={{ disableUnderline: true }}
                variant="outlined"
              />
              {hotelSelect?.competitorPricingFileTypeID !== 2 &&
                hotelSelect?.competitorPricingFileTypeID !== 4 &&
                hotelSelect?.competitorPricingFileTypeID !== 5 &&
                hotelSelect?.competitorPricingFileTypeID !== 6 &&
                hotelSelect?.competitorPricingFileTypeID !== 7 && (
                  <CompetitorsTextField
                    label={"Expedia Name"}
                    variant="outlined"
                    disabled={compDetailsEdit ? false : true}
                    error={myError && !data.expediaName}
                    name="expediaName"
                    value={data.expediaName || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}
              {hotelSelect?.competitorPricingFileTypeID === 4 && (
                <CompetitorsTextField
                  label={"Generic Name"}
                  name="displayKey"
                  disabled={compDetailsEdit ? false : true}
                  value={data.displayKey || ""}
                  style={{ minWidth: "50px" }}
                  InputProps={{ disableUnderline: true }}
                  variant="outlined"
                />
              )}
              {hotelSelect?.competitorPricingFileTypeID === 5 && (
                <CompetitorsTextField
                  label={"OTA Insight Name"}
                  variant="outlined"
                  disabled={compDetailsEdit ? false : true}
                  error={myError && !data.otaName}
                  name="otaName"
                  value={data.otaName || ""}
                  style={{ minWidth: "50px" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}
              {hotelSelect?.competitorPricingFileTypeID === 6 && (
                <CompetitorsTextField
                  label={"Travel Clicked"}
                  variant="outlined"
                  disabled={compDetailsEdit ? false : true}
                  error={myError && !data.travelClickName}
                  name="travelClickName"
                  value={data.travelClickName || ""}
                  style={{ minWidth: "50px" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}
              {hotelSelect?.competitorPricingFileTypeID === 7 && (
                <CompetitorsTextField
                  required
                  disabled={compDetailsEdit ? false : true}
                  error={myError && !data.rateGainName}
                  label="Rategain Name"
                  variant="outlined"
                  name="rateGainName"
                  value={data.rateGainName || ""}
                  style={{ minWidth: "50px" }}
                  InputProps={{ disableUnderline: true }}
                />
              )}{" "}
              {hotelSelect?.competitorPricingFileTypeID !== 1 &&
                hotelSelect?.competitorPricingFileTypeID !== 4 &&
                hotelSelect?.competitorPricingFileTypeID !== 5 &&
                hotelSelect?.competitorPricingFileTypeID !== 6 &&
                hotelSelect?.competitorPricingFileTypeID !== 7 && (
                  <CompetitorsTextField
                    label="Rateping Name"
                    variant="outlined"
                    disabled={compDetailsEdit ? false : true}
                    error={myError && !data.ratepingName}
                    name="ratepingName"
                    value={data.ratepingName || ""}
                    style={{ minWidth: "50px" }}
                    InputProps={{ disableUnderline: true }}
                  />
                )}{" "}
              <CompetitorsTextField
                label="Number of rooms"
                variant="outlined"
                disabled={compDetailsEdit ? false : true}
                error={myError && !data.rooms}
                type="number"
                name="rooms"
                InputProps={{
                  disableUnderline: true,
                }}
                style={{ minWidth: "10px" }}
                value={data.rooms || ""}
              />
              {rateShopAppEnabled && (
                <CompetitorsTextField
                  required
                  label="Hotel Rate Metric Code"
                  variant="outlined"
                  disabled={compDetailsEdit ? false : true}
                  name="rateMetricHotelCode"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{ minWidth: "20px" }}
                  value={data.rateMetricHotelCode || ""}
                />
              )}
              {rateShopAppEnabled && (
                <Tooltip title={"Show Channels"}>
                  <IconButton
                    disabled={!data?.rateMetricHotelCode}
                    onClick={(e) => handleEyeClick(data.sequence)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              )}
              <RatingContainer key={data.sequence}>
                <StarLabelNew>Star Rating</StarLabelNew>

                <Rating
                  key={data.sequence}
                  disabled={compDetailsEdit ? false : true}
                  name={`${data.sequence}`}
                  label="Stars"
                  id={data.sequence}
                  style={{
                    color: "#306FBC",
                    margin: "0 16px 0 16px",
                  }}
                  value={parseInt(data.stars) || null}
                />
              </RatingContainer>
              {/* <div
                  style={{
                    border: "1px solid rgba(130, 130, 130, 0.3)",
                    borderRadius: "4px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "1%",
                  }}
                >
                  <Stack direction={"row"}>
                    <IconButton
                      value={data.sequence}
                      disabled={disable}
                      onClick={() => {
                        onDragComp({
                          destination: index - 1,
                          source: index,
                        });
                      }}
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                    <IconButton
                      value={data.sequence}
                      disabled={disable}
                      onClick={() => {
                        onDragComp({
                          destination: index + 1,
                          source: index,
                        });
                      }}
                    >
                      <KeyboardArrowDownIcon />
                    </IconButton>
                  </Stack>
                </div> */}
              {competitorDelete && (
                <Box>
                  <IconButton
                    // disabled={disable}
                    onClick={() => {
                      setAskconfirmation(true);
                      setIdToDelete(data.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Fields>
          </SortableOverlay>
        ) : null}
      </DndContext>
      {compDetailsEdit && (
        <Stack direction={"row"} justifyContent={"space-between"}>
          <ButtonNew
            style={{
              textTransform: "none",
              display: "flex",
              color: "#ffffff",
              width: "fit-content",
              backgroundColor: "#306fbc",
              borderRadius: "5px",
              alignItems: "center",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "18px",
            }}
            onClick={addHotelHandler}
          >
            + Add Competitor
          </ButtonNew>
          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              sx={{
                height: "32px",
                backgroundColor: "#306fbc",
                borderRadius: "5px",
              }}
              next
              onClick={saveCompetitorData}
              type="submit"
              loading={isLoading}
              disabled={isLoading || !formEdit}
            >
              Save
            </Button>

            <PrimaryButton
              sx={{
                background: "#306FBC",
                boxShadow: "0px 4px 4px rgba(3, 4, 94, 0.2)",
                borderRadius: "8px",
                color: "#FFFFFF",
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                width: "10px",
              }}
              next
              onClick={() => {
                handleRedirect(`/hotel/${hotelId}/setup/room-adjustment`);
              }}
              type="submit"
              disabled={isLoading}
            >
              Next
            </PrimaryButton>
          </Stack>
        </Stack>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleCloseStatus}
      >
        <SnackbarContent
          style={{ backgroundColor: "#CA3433" }}
          message={networkMsg}
        />
      </Snackbar>
      {uploadNetworkMsg?.open && (
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={uploadNetworkMsg?.open}
          autoHideDuration={3000}
          onClose={handleUploadNetwork}
        >
          <SnackbarContent
            style={{ backgroundColor: uploadNetworkMsg?.color }}
            message={uploadNetworkMsg?.msg}
          />
        </Snackbar>
      )}
    </CompetitorPageNew>
  ) : (
    <ErrorPage />
  );
};

export default AddCompetitorData;

const DraggableItem = ({ children, id, setformEdit }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
  });

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };
  if (isDragging) setformEdit(true);
  return (
    <Competitors
      key={id}
      ref={setNodeRef}
      style={{ marginBottom: "0px", ...style }}
      {...attributes}
      {...listeners}
    >
      {children}
    </Competitors>
  );
};

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

const SortableOverlay = ({ children }) => {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
};

const StyledChannelDialog = styled(Dialog)(() => ({
  ".closeButton": {
    position: "absolute",
    right: "10px",
    top: "10px",
    cursor: "pointer",
  },
  ".content": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ".heading": {
      textDecoration: "underline",
      padding: "10px",
      textAlign: "center",
    },
    ".dataStack": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      textAlign: "center",
    },
  },
}));
